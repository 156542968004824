<template>
<div style="background: transparent">
    <v-card flat style="overflow: hidden;">
        <v-card-title>
            <v-row justify="end" class="my-2">
                <v-col cols="12" sm="6" md="6" lg="6" class="text-left">
                    <h2>{{ this.chosenTemplateName }}</h2>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6" class="text-right">

                    <v-btn icon class="ma-1" @click="referenceDialog = true">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on">help</v-icon>
                            </template>
                            <span>Help</span>
                        </v-tooltip>
                    </v-btn>

                    <v-btn icon class="ma-1" @click="jsonStorageDialog = true" color="info">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on">storage</v-icon>
                            </template>
                            <span>JSON Storage</span>
                        </v-tooltip>
                    </v-btn>

                    <v-btn icon :disabled="segments.length === 0" class="ma-1" @click="fetchPreviewMessage(), 
            previewDialog = !previewDialog" color="secondary">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on">preview</v-icon>
                            </template>
                            <span>Preview</span>
                        </v-tooltip>
                    </v-btn>
                    <v-menu v-model="menuVisible" offset-y>
                        <template v-slot:activator="{ on }">
                            <v-btn class="ma-1" v-on="on" color="primary">
                                Template
                            </v-btn>

                        </template>
                        <v-list>
                            <v-list-item @click="commenceAction(item.text)" v-for="(item, index) in createOptions" :key="index" :value="index">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon>{{ item.icon }}</v-icon> {{ item.text }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
            </v-row>
        </v-card-title>
        <v-row>
            <v-col cols="12" :sm="previewDialog ? 6 : 12" :md="previewDialog ? 6 : 12" :lg="previewDialog ? 6 : 12">
                <v-card-text style="height: 65vh; overflow: auto;">
                    <v-progress-circular v-if="loading" color="primary" style="position: absolute; top: 50%; left:50%;transform: translate(-50%, -50%); z-index: 10;" indeterminate size="160"></v-progress-circular>
                    <v-expansion-panels>
                        <draggable class="dragComponent" v-model="segments" :options="dragOptions" @end="fetchPreviewMessage(), resetSegmentOrder()">
                            <v-expansion-panel :style="{ width: '100%' }" v-for="(item, index) in computedSegments" :key="index">
                                <template v-if="!Array.isArray(item) && !item.hasOwnProperty('subGroups')" flat style="background-color: var(--v-greyRaised-lighten1) !important" class="mb-2">
                                    <v-expansion-panel-header @contextmenu.prevent="event => showConfigMenu(event, index)">
                                        <h3>{{ item.name }} [{{ item.seg }}]
                                            <v-icon v-if="anyFlags(item) === true" large color="warning" class="mt-n3">flag</v-icon>
                                        </h3>
                                        <v-spacer></v-spacer>
                                        <div class="d-flex justify-end">
                                            <!-- Added a wrapper with classes to align elements to the right -->
                                            <v-chip v-if="item.required === 'M'" outlined style="border-radius: 30px;" right color="primary" class="ma-1">M</v-chip>
                                            <v-chip v-else right color="info" class="ma-1" outlined style="border-radius: 30px;">C</v-chip>
                                            <v-chip v-if="!item.position.includes('.')" right color="secondary" class="ma-1" outlined style="border-radius: 10px;" :key="segmentKey">{{ item.repeat }}</v-chip>
                                            <!-- <v-icon large :color=inUseMandatoryColorPicker(item) v-if="item.required === 'M'">check_circle</v-icon> -->
                                            <v-btn @click="markSegmentInUseOrNotInUse(item, item)" v-if="item.inUse === true" icon>
                                                <v-icon large :color="inUseColorPicker(item)">check_circle</v-icon>
                                            </v-btn>
                                            <v-btn @click="markSegmentInUseOrNotInUse(item, item)" v-else icon>
                                                <v-icon large color="#626262">unpublished</v-icon>
                                            </v-btn>
                                            <v-btn icon @click="editSegment(item)">
                                                <v-icon color="tertiary">playlist_add</v-icon>
                                            </v-btn>
                                            <v-btn v-if="!item.position.includes('.')" :loading="btnLoader" icon :disabled="item.repeat === '1'" @click="addRepeat(item)">
                                                <v-icon color="success">add</v-icon>
                                            </v-btn>
                                            <v-btn v-else :loading="btnLoader" icon :disabled="item.repeat === item.maxRepeat.toString()" @click="removeRepeat(item)">
                                                <v-icon color="primary">remove</v-icon>
                                            </v-btn>
                                        </div>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content style="background-color: var(--v-background-lighten1) !important" class="my-3 pb-2">
                                        <div v-for="(element, index) in item.DataElements" :key="index">
                                            <!-- Main Element -->
                                            <v-chip @contextmenu.prevent="rightClickMenu(element)" @click="beginMapping(element, item)" small class="ma-1" outlined :key="element.id" :color="element.componentDataElements ? 'black' : (element.mappedValue ? 'success' : (element.inUse ? (element.requirement === 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)') : '#626262'))">
                                                <v-avatar v-if="element.requirement === 'M'" left class="darken-4">M</v-avatar>
                                                <v-avatar v-else left class="darken-4">C</v-avatar>
                                                <div v-if="showElementIDAndName">
                                                    [{{ element.elementId }}] {{ element.name }}
                                                </div>
                                                <div v-else-if="showElementNameOnly">
                                                    {{ element.name }}
                                                </div>
                                                <div v-else-if="showElementIDOnly">
                                                    {{ element.elementId }}
                                                </div>
                                                <v-avatar right class="darken-4" v-if="element.flagged === true && !element.componentDataElements">
                                                    <v-icon color="warning">flag</v-icon>
                                                </v-avatar>
                                            </v-chip>

                                            <!-- Component Elements (if present) -->
                                            <template v-if="element.componentDataElements && element.componentDataElements.length > 0">
                                                <v-chip v-for="(component, cIndex) in element.componentDataElements" :key="cIndex" @contextmenu.prevent="rightClickMenu(component.element)" @click="beginMapping(component.element, item)" small class="ma-1" outlined :color="component.element.mappedValue ? 'success' : (component.element.inUse ? (component.element.requirement === 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)') : '#626262')">
                                                    <v-avatar v-if="component.element.requirement === 'M'" left class="darken-4">M</v-avatar>
                                                    <v-avatar v-else left class="darken-4">C</v-avatar>
                                                    <div v-if="showElementIDAndName">
                                                        [{{ component.element.elementId }}] {{ component.element.name }}
                                                    </div>
                                                    <div v-else-if="showElementNameOnly">
                                                        {{ component.element.name }}
                                                    </div>
                                                    <div v-else-if="showElementIDOnly">
                                                        {{ component.element.elementId }}
                                                    </div>
                                                    <v-avatar right class="darken-4" v-if="component.element.flagged === true">
                                                        <v-icon color="warning">flag</v-icon>
                                                    </v-avatar>
                                                </v-chip>
                                            </template>
                                        </div>
                                    </v-expansion-panel-content>
                                </template>
                                <template v-if="Array.isArray(item)">
                                    <v-expansion-panel-header @contextmenu.prevent="event => showConfigMenu(event, index)">
                                        <h3>{{ item[0].name }}
                                            <v-icon v-if="anyFlagsArr(item.slice(1)) === true" large color="warning" class="mt-n3">flag</v-icon>
                                        </h3>
                                        <v-spacer></v-spacer>
                                        <div class="d-flex justify-end">
                                            <v-chip outlined style="border-radius: 30px;" v-if="item[0].required === 'M'" right :color="item[0].required == 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)'" class="ma-1">M</v-chip>
                                            <v-chip outlined style="border-radius: 30px;" v-else right :color="item[0].required == 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)'" class="ma-1">C</v-chip>
                                            <v-chip v-if="!item[0].position.includes('.')" right color="secondary" class="ma-1" outlined style="border-radius: 10px;">{{ item[0].repeat }}</v-chip>
                                            <v-btn @click="markSegmentInUseOrNotInUse(item, item[0])" v-if="item[0].inUse === true" icon>
                                                <v-icon large :color="inUseColorPicker(item[0])">check_circle</v-icon>
                                            </v-btn>
                                            <v-btn @click="markSegmentInUseOrNotInUse(item, item[0])" v-else icon>
                                                <v-icon large color="#626262">unpublished</v-icon>
                                            </v-btn>
                                            <v-btn v-if="!item[0].position.includes('.')" :loading="btnLoader" icon :disabled="item[0].repeat === '1'" @click="addRepeat(item)">
                                                <v-icon color="success">add</v-icon>
                                            </v-btn>
                                            <v-btn v-else :loading="btnLoader" icon :disabled="item[0].repeat === item[0].maxRepeat.toString()" @click="removeRepeat(item)">
                                                <v-icon color="primary">remove</v-icon>
                                            </v-btn>
                                        </div>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-card v-for="(part) in item.slice(1)" :key="part.position" class="mx-3 mb-2" style="background-color: var(--v-background-lighten1) !important">
                                            <v-toolbar dense flat style="background-color: var(--v-greyBase-base) !important">
                                                <!-- <v-chip left outlined class="ma-1">{{ part.position }}</v-chip> -->
                                                <v-toolbar-title>{{ part.name }} [{{ part.seg }}]
                                                </v-toolbar-title>
                                                <v-icon v-if="anyFlags(part) === true" large color="warning" class="mt-n3">flag</v-icon>
                                                <v-spacer></v-spacer>
                                                <v-chip v-if="part.required === 'M'" outlined style="border-radius: 30px;" right :color="part.required == 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)'" class="ma-1">M</v-chip>
                                                <v-chip v-else right :color="part.required == 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)'" outlined style="border-radius: 30px;" class="ma-1">C</v-chip>
                                                <v-chip v-if="!part.position.includes('.')" right color="secondary" class="ma-1" outlined style="border-radius: 10px;">{{ part.repeat }}</v-chip>
                                                <v-btn @click="markSegmentInUseOrNotInUse(item, part)" v-if="part.inUse === true" icon>
                                                    <v-icon large :color="inUseColorPicker(part)">check_circle</v-icon>
                                                </v-btn>
                                                <v-btn @click="markSegmentInUseOrNotInUse(item, part)" v-else icon>
                                                    <v-icon large color="#626262">unpublished</v-icon>
                                                </v-btn>
                                                <v-btn icon @click="editSegment(part)">
                                                    <v-icon color="tertiary">playlist_add</v-icon>
                                                </v-btn>
                                                <v-btn v-if="!part.position.includes('.')" :loading="btnLoader" icon :disabled="part.repeat === '1'" @click="addSubRepeat(part, item)">
                                                    <v-icon color="success">add</v-icon>
                                                </v-btn>
                                                <v-btn v-else :loading="btnLoader" icon :disabled="part.repeat === part.maxRepeat.toString()" @click="removeSubRepeat(part, item)">
                                                    <v-icon color="primary">remove</v-icon>
                                                </v-btn>
                                            </v-toolbar>
                                            <v-card-text>
                                                <div v-for="(element, index) in part.DataElements" :key="index">
                                                    <v-chip @contextmenu.prevent="rightClickMenu(element)" @click="beginMapping(element, item)" class="ma-1" small outlined :key="element.id" :color="element.componentDataElements ? 'black' : (element.mappedValue ? 'success' : (element.inUse ? (element.requirement === 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)') : '#626262'))">
                                                        <v-avatar v-if="element.requirement === 'M'" left class="darken-4">M</v-avatar>
                                                        <v-avatar v-else left class="darken-4">C</v-avatar>
                                                        <div v-if="showElementIDAndName">
                                                            [{{ element.elementId }}] {{ element.name }}
                                                        </div>
                                                        <div v-else-if="showElementNameOnly">
                                                            {{ element.name }}
                                                        </div>
                                                        <div v-else-if="showElementIDOnly">
                                                            {{ element.elementId }}
                                                        </div>
                                                        <v-avatar right class="darken-4" v-if="element.flagged === true && !element.componentDataElements">
                                                            <v-icon color="warning">flag</v-icon>
                                                        </v-avatar>
                                                    </v-chip>
                                                    <template v-if="element.componentDataElements && element.componentDataElements.length > 0">
                                                        <v-chip v-for="(component, cIndex) in element.componentDataElements" @contextmenu.prevent="rightClickMenu(component.element)" :key="cIndex" @click="beginMapping(component.element, item)" class="ma-1" small outlined :color="component.element.mappedValue ? 'success' : (component.element.inUse ? (component.element.requirement === 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)') : '#626262')">
                                                            <v-avatar v-if="component.element.requirement === 'M'" left class="darken-4">M</v-avatar>
                                                            <v-avatar v-else left class="darken-4">C</v-avatar>
                                                            <div v-if="showElementIDAndName">
                                                                [{{ component.element.elementId }}] {{ component.element.name }}
                                                            </div>
                                                            <div v-else-if="showElementNameOnly">
                                                                {{ component.element.name }}
                                                            </div>
                                                            <div v-else-if="showElementIDOnly">
                                                                {{ component.element.elementId }}
                                                            </div>
                                                            <v-avatar right class="darken-4" v-if="component.element.flagged === true">
                                                                <v-icon color="warning">flag</v-icon>
                                                            </v-avatar>
                                                        </v-chip>
                                                    </template>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-expansion-panel-content>
                                </template>
                                <template v-if="!Array.isArray(item) && item.hasOwnProperty('subGroups') || item.hasOwnProperty('groupElements')">
                                    <v-expansion-panel-header @contextmenu.prevent="event => showConfigMenu(event, index)">
                                        <h3>{{ item.name }}
                                            <v-icon v-if="anyFlagsSubGrps(item) === true" large color="warning" class="mt-n3">flag</v-icon>
                                        </h3>
                                        <v-spacer></v-spacer>
                                        <div class="d-flex justify-end">
                                            <v-chip v-if="item.required === 'M'" outlined style="border-radius: 30px;" right color="primary" class="ma-1">M</v-chip>
                                            <v-chip v-else right color="info" class="ma-1" outlined style="border-radius: 30px;">C</v-chip>
                                            <v-chip v-if="!item.position.includes('.')" right color="secondary" class="ma-1" outlined style="border-radius: 10px;">{{ item.repeat }}</v-chip>
                                            <v-btn @click="markSegmentInUseOrNotInUse(item, item)" v-if="item.inUse === true" icon>
                                                <v-icon large :color="inUseColorPicker(item)">check_circle</v-icon>
                                            </v-btn>
                                            <v-btn @click="markSegmentInUseOrNotInUse(item, item)" v-else icon>
                                                <v-icon large color="#626262">unpublished</v-icon>
                                            </v-btn>
                                            <v-btn v-if="!item.position.includes('.')" :loading="btnLoader" icon :disabled="item.repeat === '1'" @click="addRepeat(item)">
                                                <v-icon color="success">add</v-icon>
                                            </v-btn>
                                            <v-btn v-else :loading="btnLoader" icon :disabled="item.repeat === item.maxRepeat.toString()" @click="removeRepeat(item)">
                                                <v-icon color="primary">remove</v-icon>
                                            </v-btn>
                                        </div>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-card v-for="(part) in item.groupElements" :key="part.position" class="mx-3 mb-2" style="background-color: var(--v-background-lighten1) !important">
                                            <v-toolbar dense flat style="background-color: var(--v-greyBase-base) !important">
                                                <!-- <v-chip left outlined class="ma-1">{{ part.position }}</v-chip> -->
                                                <v-toolbar-title>{{ part.name }} [{{ part.seg }}]
                                                </v-toolbar-title>
                                                <v-icon v-if="anyFlags(part) === true" large color="warning" class="mt-n3">flag</v-icon>
                                                <v-spacer></v-spacer>
                                                <v-chip v-if="part.required === 'M'" outlined style="border-radius: 30px;" right :color="part.required == 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)'" class="ma-1">M</v-chip>
                                                <v-chip v-else right :color="part.required == 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)'" outlined style="border-radius: 30px;" class="ma-1">C</v-chip>
                                                <v-chip v-if="!part.position.includes('.')" right color="secondary" class="ma-1" outlined style="border-radius: 10px;">{{ part.repeat }}</v-chip>
                                                <v-btn @click="markSegmentInUseOrNotInUse(item, part)" v-if="part.inUse === true" icon>
                                                    <v-icon large :color="inUseColorPicker(part)">check_circle</v-icon>
                                                </v-btn>
                                                <v-btn @click="markSegmentInUseOrNotInUse(item, part)" v-else icon>
                                                    <v-icon large color="#626262">unpublished</v-icon>
                                                </v-btn>
                                                <v-btn icon @click="editSegment(part)">
                                                    <v-icon color="tertiary">playlist_add</v-icon>
                                                </v-btn>
                                                <v-btn v-if="!part.position.includes('.')" :loading="btnLoader" icon :disabled="part.repeat === '1'" @click="addSubGroupRepeat(part, item, 'groupElements')">
                                                    <v-icon color="success">add</v-icon>
                                                </v-btn>
                                                <v-btn v-else :loading="btnLoader" icon :disabled="part.repeat === part.maxRepeat.toString()" @click="removeSubGroupRepeat(part, item, 'groupElements')">
                                                    <v-icon color="primary">remove</v-icon>
                                                </v-btn>
                                            </v-toolbar>
                                            <v-card-text>
                                                <div v-for="(element, index) in part.DataElements" :key="index">
                                                    <v-chip @contextmenu.prevent="rightClickMenu(element)" @click="beginMapping(element, item)" class="ma-1" small outlined :key="element.id" :color="element.componentDataElements ? 'black' : (element.mappedValue ? 'success' : (element.inUse ? (element.requirement === 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)') : '#626262'))">
                                                        <v-avatar v-if="element.requirement === 'M'" left class="darken-4">M</v-avatar>
                                                        <v-avatar v-else left class="darken-4">C</v-avatar>
                                                        <div v-if="showElementIDAndName">
                                                            [{{ element.elementId }}] {{ element.name }}
                                                        </div>
                                                        <div v-else-if="showElementNameOnly">
                                                            {{ element.name }}
                                                        </div>
                                                        <div v-else-if="showElementIDOnly">
                                                            {{ element.elementId }}
                                                        </div>
                                                        <v-avatar right class="darken-4" v-if="element.flagged === true && !element.componentDataElements">
                                                            <v-icon color="warning">flag</v-icon>
                                                        </v-avatar>
                                                    </v-chip>
                                                    <template v-if="element.componentDataElements && element.componentDataElements.length > 0">
                                                        <v-chip v-for="(component, cIndex) in element.componentDataElements" :key="`component-${index}-${cIndex}`" @contextmenu.prevent="rightClickMenu(component.element)" @click="beginMapping(component.element, item)" class="ma-1" small outlined :color="component.element.mappedValue ? 'success' : (component.element.inUse ? (component.element.requirement === 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)') : '#626262')">
                                                            <v-avatar v-if="component.element.requirement === 'M'" left class="darken-4">M</v-avatar>
                                                            <v-avatar v-else left class="darken-4">C</v-avatar>
                                                            <div v-if="showElementIDAndName">
                                                                [{{ component.element.elementId }}] {{ component.element.name }}
                                                            </div>
                                                            <div v-else-if="showElementNameOnly">
                                                                {{ component.element.name }}
                                                            </div>
                                                            <div v-else-if="showElementIDOnly">
                                                                {{ component.element.elementId }}
                                                            </div>
                                                            <v-avatar right class="darken-4" v-if="component.element.flagged === true">
                                                                <v-icon color="warning">flag</v-icon>
                                                            </v-avatar>
                                                        </v-chip>
                                                    </template>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                        <v-expansion-panels>
                                            <v-expansion-panel v-for="(subGroup, index) in item.subGroups" :key="index" class="mx-3 mb-2" style="background-color: var(--v-background-lighten1) !important">
                                                <v-expansion-panel-header style="background-color: var(--v-greyBase-base) !important">
                                                    <h3>{{ subGroup[0].name }}
                                                        <v-icon v-if="anyFlagsArr(subGroup.slice(1)) === true || anyFlagsSubGrpArr(subGroup[0].subGroups) === true || anyFlagsSubOfSubGrp(subGroup[0])" large color="warning" class="mt-n3">flag</v-icon>
                                                    </h3>
                                                    <v-spacer></v-spacer>
                                                    <div class="d-flex justify-end">
                                                        <v-chip outlined style="border-radius: 30px;" v-if="subGroup[0].required === 'M'" right :color="subGroup[0].required == 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)'" class="ma-1">M</v-chip>
                                                        <v-chip outlined style="border-radius: 30px;" v-else right :color="subGroup[0].required == 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)'" class="ma-1">C</v-chip>
                                                        <v-chip v-if="!subGroup[0].position.includes('.')" right color="secondary" class="ma-1" outlined style="border-radius: 10px;">{{
                                                subGroup[0].repeat }}</v-chip>
                                                        <v-btn @click="markSegmentInUseOrNotInUse(item, subGroup[0])" v-if="subGroup[0].inUse === true" icon>
                                                            <v-icon large :color="inUseColorPicker(subGroup[0])">check_circle</v-icon>
                                                        </v-btn>
                                                        <v-btn @click="markSegmentInUseOrNotInUse(item, subGroup[0])" v-else icon>
                                                            <v-icon large color="#626262">unpublished</v-icon>
                                                        </v-btn>
                                                        <v-btn v-if="!subGroup[0].position.includes('.')" :loading="btnLoader" icon :disabled="subGroup[0].repeat === '1'" @click="addSubGroupRepeat(subGroup, item, 'subGroups')">
                                                            <v-icon color="success">add</v-icon>
                                                        </v-btn>
                                                        <v-btn v-else :loading="btnLoader" icon :disabled="subGroup[0].repeat === subGroup[0].maxRepeat.toString()" @click="removeSubGroupRepeat(subGroup, item, 'subGroups')">
                                                            <v-icon color="primary">remove</v-icon>
                                                        </v-btn>
                                                    </div>
                                                </v-expansion-panel-header>
                                                <v-expansion-panel-content>
                                                    <v-card v-for="(part) in subGroup.slice(1)" :key="part.position" class="mx-3 mb-2" style="background-color: var(--v-background-lighten1) !important">
                                                        <v-toolbar dense flat style="background-color: var(--v-greyBase-base) !important">
                                                            <!-- <v-chip left outlined class="ma-1">{{ item.position }}</v-chip> -->
                                                            <v-toolbar-title>{{ part.name }} [{{ part.seg }}]
                                                            </v-toolbar-title>
                                                            <v-icon v-if="anyFlags(part) === true" large color="warning" class="mt-n3">flag</v-icon>
                                                            <v-spacer></v-spacer>
                                                            <v-chip v-if="part.required === 'M'" outlined style="border-radius: 30px;" right :color="part.required == 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)'" class="ma-1">M</v-chip>
                                                            <v-chip v-else right :color="part.required == 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)'" outlined style="border-radius: 30px;" class="ma-1">C</v-chip>
                                                            <v-chip v-if="!part.position.includes('.')" right color="secondary" class="ma-1" outlined style="border-radius: 10px;">{{
                                                        part.repeat }}</v-chip>
                                                            <v-btn @click="markSegmentInUseOrNotInUse(item, part)" v-if="part.inUse === true" icon>
                                                                <v-icon large :color="inUseColorPicker(part)">check_circle</v-icon>
                                                            </v-btn>
                                                            <v-btn @click="markSegmentInUseOrNotInUse(item, part)" v-else icon>
                                                                <v-icon large color="#626262">unpublished</v-icon>
                                                            </v-btn>
                                                            <v-btn icon @click="editSegment(part)">
                                                                <v-icon color="tertiary">playlist_add</v-icon>
                                                            </v-btn>
                                                            <v-btn v-if="!part.position.includes('.')" :loading="btnLoader" icon :disabled="part.repeat === '1'" @click="addSubGroupElementRepeat(part, subGroup, item)">
                                                                <v-icon color="success">add</v-icon>
                                                            </v-btn>
                                                            <v-btn v-else :loading="btnLoader" icon :disabled="part.repeat === part.maxRepeat.toString()" @click="removeSubGroupElementRepeat(part, subGroup, item)">
                                                                <v-icon color="primary">remove</v-icon>
                                                            </v-btn>
                                                        </v-toolbar>
                                                        <v-card-text>
                                                            <div v-for="(element, index) in part.DataElements" :key="index">
                                                                <v-chip @contextmenu.prevent="rightClickMenu(element)" @click="beginMapping(element, item)" class="ma-1" small outlined :key="element.id" :color="element.componentDataElements ? 'black' : (element.mappedValue ? 'success' : (element.inUse ? (element.requirement === 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)') : '#626262'))">
                                                                    <v-avatar v-if="element.requirement === 'M'" left class="darken-4">M</v-avatar>
                                                                    <v-avatar v-else left class="darken-4">C</v-avatar>
                                                                    <div v-if="showElementIDAndName">
                                                                        [{{ element.elementId }}] {{ element.name }}
                                                                    </div>
                                                                    <div v-else-if="showElementNameOnly">
                                                                        {{ element.name }}
                                                                    </div>
                                                                    <div v-else-if="showElementIDOnly">
                                                                        {{ element.elementId }}
                                                                    </div>
                                                                    <v-avatar right class="darken-4" v-if="element.flagged === true && !element.componentDataElements">
                                                                        <v-icon color="warning">flag</v-icon>
                                                                    </v-avatar>
                                                                </v-chip>
                                                                <template v-if="element.componentDataElements && element.componentDataElements.length > 0">
                                                                    <v-chip v-for="(component, cIndex) in element.componentDataElements" :key="`component-${index}-${cIndex}`" @contextmenu.prevent="rightClickMenu(component.element)" @click="beginMapping(component.element, item)" class="ma-1" small outlined :color="component.element.mappedValue ? 'success' : (component.element.inUse ? (component.element.requirement === 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)') : '#626262')">
                                                                        <v-avatar v-if="component.element.requirement === 'M'" left class="darken-4">M</v-avatar>
                                                                        <v-avatar v-else left class="darken-4">C</v-avatar>
                                                                        <div v-if="showElementIDAndName">
                                                                            [{{ component.element.elementId }}] {{ component.element.name }}
                                                                        </div>
                                                                        <div v-else-if="showElementNameOnly">
                                                                            {{ component.element.name }}
                                                                        </div>
                                                                        <div v-else-if="showElementIDOnly">
                                                                            {{ component.element.elementId }}
                                                                        </div>
                                                                        <v-avatar right class="darken-4" v-if="component.element.flagged === true">
                                                                            <v-icon color="warning">flag</v-icon>
                                                                        </v-avatar>
                                                                    </v-chip>
                                                                </template>
                                                            </div>
                                                        </v-card-text>
                                                    </v-card>
                                                    <v-expansion-panels v-if="subGroup[0].subGroups">
                                                        <v-expansion-panel v-for="(subOfSubGroup, index) in subGroup[0].subGroups" :key="index" class="mx-3 mb-3" style="background-color: var(--v-background-lighten1) !important">
                                                            <v-expansion-panel-header style="background-color: var(--v-greyBase-base) !important">
                                                                <h3>{{ subOfSubGroup[0].name }}
                                                                    <v-icon v-if="anyFlagsArr(subOfSubGroup.slice(1)) === true || anyFlagsSubGrpArr(subOfSubGroup[0].subGroups) === true" large color="warning" class="mt-n3">flag</v-icon>
                                                                </h3>
                                                                <v-spacer></v-spacer>
                                                                <div class="d-flex justify-end">
                                                                    <v-chip outlined style="border-radius: 30px;" v-if="subOfSubGroup[0].required === 'M'" right :color="subOfSubGroup[0].required == 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)'" class="ma-1">M</v-chip>
                                                                    <v-chip outlined style="border-radius: 30px;" v-else right :color="subOfSubGroup[0].required == 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)'" class="ma-1">C</v-chip>
                                                                    <v-chip v-if="!subOfSubGroup[0].position.includes('.')" right color="secondary" class="ma-1" outlined style="border-radius: 10px;">{{
                                                subOfSubGroup[0].repeat }}</v-chip>
                                                                    <v-btn @click="markSegmentInUseOrNotInUse(item, subOfSubGroup[0])" v-if="subOfSubGroup[0].inUse === true" icon>
                                                                        <v-icon large :color="inUseColorPicker(subOfSubGroup[0])">check_circle</v-icon>
                                                                    </v-btn>
                                                                    <v-btn @click="markSegmentInUseOrNotInUse(item, subOfSubGroup[0])" v-else icon>
                                                                        <v-icon large color="#626262">unpublished</v-icon>
                                                                    </v-btn>
                                                                    <v-btn v-if="!subOfSubGroup[0].position.includes('.')" :loading="btnLoader" icon :disabled="subOfSubGroup[0].repeat === '1'" @click="addSubOfSubGroupRepeat(subGroup, item, subOfSubGroup)">
                                                                        <v-icon color="success">add</v-icon>
                                                                    </v-btn>
                                                                    <v-btn v-else :loading="btnLoader" icon :disabled="subOfSubGroup[0].repeat === subOfSubGroup[0].maxRepeat.toString()" @click="removeSubOfSubGroupRepeat(subGroup, item, subOfSubGroup)">
                                                                        <v-icon color="primary">remove</v-icon>
                                                                    </v-btn>
                                                                </div>
                                                            </v-expansion-panel-header>
                                                            <v-expansion-panel-content>
                                                                <v-card v-for="(part) in subOfSubGroup.slice(1)" :key="part.position" class="mx-3 mb-2" style="background-color: var(--v-background-lighten1) !important">
                                                                    <v-toolbar dense flat style="background-color: var(--v-greyBase-base) !important">
                                                                        <!-- <v-chip left outlined class="ma-1">{{ item.position }}</v-chip> -->
                                                                        <v-toolbar-title>{{ part.name }} [{{ part.seg }}]
                                                                        </v-toolbar-title>
                                                                        <v-icon v-if="anyFlags(part) === true" large color="warning" class="mt-n3">flag</v-icon>
                                                                        <v-spacer></v-spacer>
                                                                        <v-chip v-if="part.required === 'M'" outlined style="border-radius: 30px;" right :color="part.required == 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)'" class="ma-1">M</v-chip>
                                                                        <v-chip v-else right :color="part.required == 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)'" outlined style="border-radius: 30px;" class="ma-1">C</v-chip>
                                                                        <v-chip v-if="!part.position.includes('.')" right color="secondary" class="ma-1" outlined style="border-radius: 10px;">{{
                                                        part.repeat }}</v-chip>
                                                                        <!-- <v-icon large :color=inUseMandatoryColorPicker(part) v-if="part.required === 'M'">check_circle</v-icon> -->
                                                                        <v-btn @click="markSegmentInUseOrNotInUse(item, part)" v-if="part.inUse === true" icon>
                                                                            <v-icon large :color="inUseColorPicker(part)">check_circle</v-icon>
                                                                        </v-btn>
                                                                        <v-btn @click="markSegmentInUseOrNotInUse(item, part)" v-else icon>
                                                                            <v-icon large color="#626262">unpublished</v-icon>
                                                                        </v-btn>
                                                                        <v-btn icon @click="editSegment(part)">
                                                                            <v-icon color="tertiary">playlist_add</v-icon>
                                                                        </v-btn>
                                                                        <v-btn v-if="!part.position.includes('.')" :loading="btnLoader" icon :disabled="part.repeat === '1'" @click="addSubOfSubGroupElementRepeat(subGroup, item, subOfSubGroup, part)">
                                                                            <v-icon color="success">add</v-icon>
                                                                        </v-btn>
                                                                        <v-btn v-else :loading="btnLoader" icon :disabled="part.repeat === part.maxRepeat.toString()" @click="removeSubOfSubGroupElementRepeat(subGroup, item, subOfSubGroup, part)">
                                                                            <v-icon color="primary">remove</v-icon>
                                                                        </v-btn>
                                                                    </v-toolbar>
                                                                    <v-card-text>
                                                                        <div v-for="(element, index) in part.DataElements" :key="index">
                                                                            <v-chip @contextmenu.prevent="rightClickMenu(element)" @click="beginMapping(element, item)" class="ma-1" small outlined :key="element.id" :color="element.componentDataElements ? 'black' : (element.mappedValue ? 'success' : (element.inUse ? (element.requirement === 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)') : '#626262'))">
                                                                                <v-avatar v-if="element.requirement === 'M'" left class="darken-4">M</v-avatar>
                                                                                <v-avatar v-else left class="darken-4">C</v-avatar>
                                                                                <div v-if="showElementIDAndName">
                                                                                    [{{ element.elementId }}] {{ element.name }}
                                                                                </div>
                                                                                <div v-else-if="showElementNameOnly">
                                                                                    {{ element.name }}
                                                                                </div>
                                                                                <div v-else-if="showElementIDOnly">
                                                                                    {{ element.elementId }}
                                                                                </div>
                                                                                <v-avatar right class="darken-4" v-if="element.flagged === true && !element.componentDataElements">
                                                                                    <v-icon color="warning">flag</v-icon>
                                                                                </v-avatar>
                                                                            </v-chip>
                                                                            <template v-if="element.componentDataElements && element.componentDataElements.length > 0">
                                                                                <v-chip v-for="(component, cIndex) in element.componentDataElements" :key="`component-${index}-${cIndex}`" @contextmenu.prevent="rightClickMenu(component.element)" @click="beginMapping(component.element, item)" class="ma-1" small outlined :color="component.element.mappedValue ? 'success' : (component.element.inUse ? (component.element.requirement === 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)') : '#626262')">
                                                                                    <v-avatar v-if="component.element.requirement === 'M'" left class="darken-4">M</v-avatar>
                                                                                    <v-avatar v-else left class="darken-4">C</v-avatar>
                                                                                    <div v-if="showElementIDAndName">
                                                                                        [{{ component.element.elementId }}] {{ component.element.name }}
                                                                                    </div>
                                                                                    <div v-else-if="showElementNameOnly">
                                                                                        {{ component.element.name }}
                                                                                    </div>
                                                                                    <div v-else-if="showElementIDOnly">
                                                                                        {{ component.element.elementId }}
                                                                                    </div>
                                                                                    <v-avatar right class="darken-4" v-if="component.element.flagged === true">
                                                                                        <v-icon color="warning">flag</v-icon>
                                                                                    </v-avatar>
                                                                                </v-chip>
                                                                            </template>
                                                                        </div>
                                                                    </v-card-text>
                                                                </v-card>
                                                                <v-expansion-panels v-if="subOfSubGroup[0].subGroups">
                                                                    <v-expansion-panel v-for="(lastOfSubGroup, index) in subOfSubGroup[0].subGroups" :key="index" class="mx-3 mb-3" style="background-color: var(--v-background-lighten1) !important">
                                                                        <v-expansion-panel-header style="background-color: var(--v-greyBase-base) !important">
                                                                            <h3>{{ lastOfSubGroup[0].name }}
                                                                                <v-icon v-if="anyFlagsArr(lastOfSubGroup.slice(1)) === true" large color="warning" class="mt-n3">flag</v-icon>
                                                                            </h3>
                                                                            <v-spacer></v-spacer>
                                                                            <div class="d-flex justify-end">
                                                                                <v-chip outlined style="border-radius: 30px;" v-if="lastOfSubGroup[0].required === 'M'" right :color="lastOfSubGroup[0].required == 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)'" class="ma-1">M</v-chip>
                                                                                <v-chip outlined style="border-radius: 30px;" v-else right :color="lastOfSubGroup[0].required == 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)'" class="ma-1">C</v-chip>
                                                                                <v-chip v-if="!lastOfSubGroup[0].position.includes('.')" right color="secondary" class="ma-1" outlined style="border-radius: 10px;">{{
                                                lastOfSubGroup[0].repeat }}</v-chip>
                                                                                <v-btn @click="markSegmentInUseOrNotInUse(item, lastOfSubGroup[0])" v-if="lastOfSubGroup[0].inUse === true" icon>
                                                                                    <v-icon large :color="inUseColorPicker(lastOfSubGroup[0])">check_circle</v-icon>
                                                                                </v-btn>
                                                                                <v-btn @click="markSegmentInUseOrNotInUse(item, lastOfSubGroup[0])" v-else icon>
                                                                                    <v-icon large color="#626262">unpublished</v-icon>
                                                                                </v-btn>
                                                                                <v-btn v-if="!lastOfSubGroup[0].position.includes('.')" :loading="btnLoader" icon :disabled="lastOfSubGroup[0].repeat === '1'" @click="addLastSubGroupRepeat(subGroup, subOfSubGroup, item, lastOfSubGroup)">
                                                                                    <v-icon color="success">add</v-icon>
                                                                                </v-btn>
                                                                                <v-btn v-else :loading="btnLoader" icon :disabled="lastOfSubGroup[0].repeat === lastOfSubGroup[0].maxRepeat.toString()" @click="removeLastSubGroupRepeat(subGroup, subOfSubGroup, item, lastOfSubGroup)">
                                                                                    <v-icon color="primary">remove</v-icon>
                                                                                </v-btn>
                                                                            </div>
                                                                        </v-expansion-panel-header>
                                                                        <v-expansion-panel-content>
                                                                            <v-card v-for="(part) in lastOfSubGroup.slice(1)" :key="part.position" class="mx-3 mb-2" style="background-color: var(--v-background-lighten1) !important">
                                                                                <v-toolbar dense flat style="background-color: var(--v-greyBase-base) !important">
                                                                                    <!-- <v-chip left outlined class="ma-1">{{ item.position }}</v-chip> -->
                                                                                    <v-toolbar-title>{{ part.name }} [{{ part.seg }}]
                                                                                    </v-toolbar-title>
                                                                                    <v-icon v-if="anyFlags(part) === true" large color="warning" class="mt-n3">flag</v-icon>
                                                                                    <v-spacer></v-spacer>
                                                                                    <v-chip v-if="part.required === 'M'" outlined style="border-radius: 30px;" right :color="part.required == 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)'" class="ma-1">M</v-chip>
                                                                                    <v-chip v-else right :color="part.required == 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)'" outlined style="border-radius: 30px;" class="ma-1">C</v-chip>
                                                                                    <v-chip v-if="!part.position.includes('.')" right color="secondary" class="ma-1" outlined style="border-radius: 10px;">{{
                                                        part.repeat }}</v-chip>
                                                                                    <!-- <v-icon large :color=inUseMandatoryColorPicker(part) v-if="part.required === 'M'">check_circle</v-icon> -->
                                                                                    <v-btn @click="markSegmentInUseOrNotInUse(item, part)" v-if="part.inUse === true" icon>
                                                                                        <v-icon large :color="inUseColorPicker(part)">check_circle</v-icon>
                                                                                    </v-btn>
                                                                                    <v-btn @click="markSegmentInUseOrNotInUse(item, part)" v-else icon>
                                                                                        <v-icon large color="#626262">unpublished</v-icon>
                                                                                    </v-btn>
                                                                                    <v-btn icon @click="editSegment(part)">
                                                                                        <v-icon color="tertiary">playlist_add</v-icon>
                                                                                    </v-btn>
                                                                                    <v-btn v-if="!part.position.includes('.')" :loading="btnLoader" icon :disabled="part.repeat === '1'" @click="addSubOfSubGroupElementRepeat(subGroup, item, lastOfSubGroup, part)">
                                                                                        <v-icon color="success">add</v-icon>
                                                                                    </v-btn>
                                                                                    <v-btn v-else :loading="btnLoader" icon :disabled="part.repeat === part.maxRepeat.toString()" @click="removeSubOfSubGroupElementRepeat(subGroup, item, lastOfSubGroup, part)">
                                                                                        <v-icon color="primary">remove</v-icon>
                                                                                    </v-btn>
                                                                                </v-toolbar>
                                                                                <v-card-text>
                                                                                    <div v-for="(element, index) in part.DataElements" :key="index">
                                                                                        <v-chip @contextmenu.prevent="rightClickMenu(element)" @click="beginMapping(element, item)" class="ma-1" small outlined :key="element.id" :color="element.componentDataElements ? 'black' : (element.mappedValue ? 'success' : (element.inUse ? (element.requirement === 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)') : '#626262'))">
                                                                                            <v-avatar v-if="element.requirement === 'M'" left class="darken-4">M</v-avatar>
                                                                                            <v-avatar v-else left class="darken-4">C</v-avatar>
                                                                                            <div v-if="showElementIDAndName">
                                                                                                [{{ element.elementId }}] {{ element.name }}
                                                                                            </div>
                                                                                            <div v-else-if="showElementNameOnly">
                                                                                                {{ element.name }}
                                                                                            </div>
                                                                                            <div v-else-if="showElementIDOnly">
                                                                                                {{ element.elementId }}
                                                                                            </div>
                                                                                            <v-avatar right class="darken-4" v-if="element.flagged === true && !element.componentDataElements">
                                                                                                <v-icon color="warning">flag</v-icon>
                                                                                            </v-avatar>
                                                                                        </v-chip>
                                                                                        <template v-if="element.componentDataElements && element.componentDataElements.length > 0">
                                                                                            <v-chip v-for="(component, cIndex) in element.componentDataElements" :key="`component-${index}-${cIndex}`" @contextmenu.prevent="rightClickMenu(component.element)" @click="beginMapping(component.element, item)" class="ma-1" small outlined :color="component.element.mappedValue ? 'success' : (component.element.inUse ? (component.element.requirement === 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)') : '#626262')">
                                                                                                <v-avatar v-if="component.element.requirement === 'M'" left class="darken-4">M</v-avatar>
                                                                                                <v-avatar v-else left class="darken-4">C</v-avatar>
                                                                                                <div v-if="showElementIDAndName">
                                                                                                    [{{ component.element.elementId }}] {{ component.element.name }}
                                                                                                </div>
                                                                                                <div v-else-if="showElementNameOnly">
                                                                                                    {{ component.element.name }}
                                                                                                </div>
                                                                                                <div v-else-if="showElementIDOnly">
                                                                                                    {{ component.element.elementId }}
                                                                                                </div>
                                                                                                <v-avatar right class="darken-4" v-if="component.element.flagged === true">
                                                                                                    <v-icon color="warning">flag</v-icon>
                                                                                                </v-avatar>
                                                                                            </v-chip>
                                                                                        </template>
                                                                                    </div>
                                                                                </v-card-text>
                                                                            </v-card>
                                                                        </v-expansion-panel-content>
                                                                    </v-expansion-panel>
                                                                </v-expansion-panels>
                                                            </v-expansion-panel-content>
                                                        </v-expansion-panel>
                                                    </v-expansion-panels>
                                                </v-expansion-panel-content>

                                            </v-expansion-panel>
                                        </v-expansion-panels>
                                    </v-expansion-panel-content>
                                </template>
                            </v-expansion-panel>
                        </draggable>
                    </v-expansion-panels>
                </v-card-text>
            </v-col>
            <v-col v-if="previewDialog === true" cols="12" sm="6" md="6" lg="6">
                <v-card width="auto" max-height="65vh" style="height: 65vh; overflow: auto;">
                    <v-toolbar flat>
                        <v-toolbar-title>{{ this.selectedMsg }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-switch @change="fetchPreviewMessage()" v-model="debug" label="Debug"></v-switch>
                    </v-toolbar>
                    <v-card-text>
                        <div v-for="(line, index) of formattedPreviewMsg" :key="index">
                            {{ line }}
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-card>

    <v-dialog v-model="removeMessageTemplateDialog" class="black-dialog" width="1000px" persistent>
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>Remove Template</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="removeMessageTemplateDialog = false">
                    <v-icon color="primary">close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="6" md="6" lg="6" class="text-left">
                        <el-pagination style="color: var(--v-primaryText-base)" :current-page.sync="page" :page-size.sync="params.limit" :page-sizes="[10]" layout="sizes, prev, pager, next, jumper, total" :total="total"></el-pagination>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6" class="text-right">
                        <el-input suffix-icon="el-icon-search" v-model="buildSearch" clearable placeholder="Search">
                        </el-input>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-data-table :key="refreshKey" hide-default-footer :items="templateOptions" :headers="deletionHeaders" :options="{
      page: page,
      itemsPerPage: params.limit,
      pageStart: 1,
    }">
                            <template v-slot:item.delete="{ item }">
                                <v-icon @click="deleteTemplate(item)" color="error">delete</v-icon>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="chooseBuildDialog" class="black-dialog" width="1000px" persistent>
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>Select Template</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="chooseBuildDialog = false">
                    <v-icon color="primary">close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="6" md="6" lg="6" class="text-left">
                        <el-pagination style="color: var(--v-primaryText-base)" :current-page.sync="page" :page-size.sync="params.limit" :page-sizes="[10]" layout="sizes, prev, pager, next, jumper, total" :total="total"></el-pagination>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6" class="text-right">
                        <el-input suffix-icon="el-icon-search" v-model="buildSearch" clearable placeholder="Search">
                        </el-input>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="12" md="12" lg="12" class="text-right">
                        <v-data-table :key="refreshKey" hide-default-footer :items="templateOptions" :headers="templateHeaders" :options="{
        page: page,
        itemsPerPage: params.limit,
        pageStart: 1,
      }" @click:row="selectBuild"></v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="commeceCopyCreationDialog" class="black-dialog" width="1000px" persistent>
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>Make a Copy</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="commeceCopyCreationDialog = false">
                    <v-icon color="primary">close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="6" md="6" lg="6" class="text-left">
                        <el-pagination style="color: var(--v-primaryText-base)" :current-page.sync="pageBuild" :page-size.sync="paramsBuild.limit" :page-sizes="[10]" layout="sizes, prev, pager, next, jumper, total" :total="total"></el-pagination>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6" class="text-right">
                        <el-input suffix-icon="el-icon-search" v-model="searchPrevious" clearable placeholder="Search">
                        </el-input>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="12" md="12" lg="12" class="text-right">
                        <v-data-table :key="refreshKey" hide-default-footer :items="copyOptions" :headers="templateHeaders" :options="{
        page: pageBuild,
        itemsPerPage: paramsBuild.limit,
        pageStart: 1,
      }" @click:row="newBuildFromOldBuild"></v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="commenceBuildCreateDialog" class="black-dialog" width="1500px" persistent>
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>Create New Template</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="commenceBuildCreateDialog = false">
                    <v-icon color="primary">close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-select :items="unVersions" v-model="chosenUNVersion" label="Version"></v-select>
                <v-card flat>
                    <v-select :items="filteredMessageTypes" v-model="chosenUNMessageType" :disabled="!chosenUNVersion" label="Message Type">
                        <template v-slot:prepend-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <el-input suffix-icon="el-icon-search" v-model="searchVersion" clearable placeholder="Search"></el-input>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-select>
                </v-card>
                <el-input :disabled="!chosenUNMessageType" v-model="newBuildName" placeholder="Enter Name of Created File">
                    <template slot="append">
                        <el-button :key="refreshKey" @click="createNewBuildUN()" color="success" el-tooltip="Create Build File">
                            <v-icon>save</v-icon>
                        </el-button>
                    </template>
                </el-input>
                <v-progress-linear v-if="creatingBuild" indeterminate color="primary"></v-progress-linear>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="jsonStorageDialog" class="black-dialog" width="1800px" persistent>
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>JSON Storage</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="jsonStorageDialog = false">
                    <v-icon color="primary">close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-tabs v-model="storageTabs" background-color="transparent" grow>
                    <v-tab key="json">JSON Files</v-tab>
                    <v-tab key="edifact">EDIFACT Message Files</v-tab>
                </v-tabs>
                <v-tabs-items v-model="storageTabs">
                    <v-tab-item key="json">
                        <v-card tile>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" sm="6" md="6" lg="6" class="text-left">
                                        <el-pagination style="color: var(--v-primaryText-base)" :current-page.sync="pageJSON" :page-size.sync="jsonParams.limit" :page-sizes="[5]" layout="sizes, prev, pager, next, jumper, total" :total="totalJSONs"></el-pagination>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" lg="6" class="text-right">
                                        <el-input suffix-icon="el-icon-search" v-model="searchJSON" clearable placeholder="Search">
                                        </el-input>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="4" md="4" lg="4" class="text-left">
                                        <v-card>
                                            <v-toolbar flat>
                                                <v-toolbar-title>
                                                    Upload JSON
                                                </v-toolbar-title>
                                                <v-spacer></v-spacer>
                                                <v-btn :disabled="fileJSON === null" icon @click="uploadJSONToStorage()">
                                                    <v-icon color="success">upload</v-icon>
                                                </v-btn>
                                            </v-toolbar>
                                            <v-card-text>
                                                <v-card flat @click="addFiles()" style="height: 300px; border: 1px dotted grey">
                                                    <v-card-text class="text-center" style="height: 100%">
                                                        <v-container fill-height>
                                                            <v-row align="center" v-if="fileList.length === 0">
                                                                <v-col cols="12" class="text-center">
                                                                    <v-icon x-large class="mb-1 pb-1">cloud_upload</v-icon>
                                                                    <h1>Drag files or click to upload a JSON file</h1>
                                                                    <file-upload class="my-0 py-0" post-action="" :multiple="false" :drop="true" :drop-directory="true" v-model="fileList" ref="jsonUploader">
                                                                    </file-upload>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row v-else>
                                                                <v-col cols="12" v-for="(file, index) in fileList" :key="index" class="text-center">
                                                                    <div>
                                                                        <v-badge>
                                                                            <template v-slot:badge>
                                                                                <v-icon @click="removeJSON()">close</v-icon>
                                                                            </template>
                                                                            <v-icon x-large>{{ getFileIcon(file) }}</v-icon>
                                                                        </v-badge>
                                                                        <p>{{ file.name }}</p>
                                                                    </div>
                                                                    <file-upload class="my-0 py-0" post-action="" :multiple="false" :drop="true" :drop-directory="true" v-model="fileList" ref="jsonUploader">
                                                                    </file-upload>
                                                                </v-col>
                                                            </v-row>
                                                        </v-container>
                                                    </v-card-text>
                                                </v-card>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12" sm="8" md="8" lg="8" class="text-right">
                                        <v-data-table :key="refreshKey" hide-default-footer :items="jsonFileOptions" :headers="jsonHeaders" :options="{
      page: pageJSON,
      itemsPerPage: jsonParams.limit,
      pageStart: 1,
    }" item-key="fileName" class="elevation-1">
                                            <!-- <template v-slot:item="{ item }">
                                                <tr :class="{ 'primary--text': isSelected(item) }" @click="handleRowClick(item)">
                                                    <td class="text-center">
                                                        <v-checkbox v-model="selectedJSON" :value="item.fileName" />
                                                    </td>
                                                    <td class="text-left">{{ item.fileName }}</td>
                                                    <td class="text-left">{{ item.fileType }}</td>
                                                    <td class="text-left">{{ item.lastModified }}</td>
                                                    <td class="text-left">{{ item.size }}</td>
                                                </tr>
                                            </template> -->
                                            <template v-slot:[`item.actions`]="{ item }">
                                                <div>
                                                    <v-icon v-if="item.fileName === selectedJSON">check_box</v-icon>
                                                    <v-btn @click="handleRowClick(item)" v-else icon>
                                                        <v-icon>check_box_outline_blank</v-icon>
                                                    </v-btn>
                                                    <v-btn @click="beginEditing(item)" icon>
                                                        <v-icon color="info">edit</v-icon>
                                                    </v-btn>
                                                    <v-btn @click="deleteFileDialog = true, fileToDelete = { ...item, deleteType: 'json' }" icon color="primary">
                                                        <v-icon>delete</v-icon>
                                                    </v-btn>
                                                </div>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item key="edifact">
                        <v-card tile>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" sm="6" md="6" lg="6" class="text-left">
                                        <el-pagination style="color: var(--v-primaryText-base)" :current-page.sync="pageMsg" :page-size.sync="msgParams.limit" :page-sizes="[5]" layout="sizes, prev, pager, next, jumper, total" :total="totalMsgs"></el-pagination>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" lg="6" class="text-right">
                                        <el-input suffix-icon="el-icon-search" v-model="searchMsg" clearable placeholder="Search">
                                        </el-input>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="4" md="4" lg="4" class="text-left">
                                        <v-card>
                                            <v-toolbar flat>
                                                <v-toolbar-title>
                                                    Upload EDIFACT Message Files
                                                </v-toolbar-title>
                                                <v-spacer></v-spacer>
                                                <v-btn :disabled="fileMSG === null" icon @click="uploadMsgToStorage()">
                                                    <v-icon color="success">upload</v-icon>
                                                </v-btn>
                                            </v-toolbar>
                                            <v-card-text>
                                                <v-card flat @click="addMsgs()" style="height: 300px; border: 1px dotted grey">
                                                    <v-card-text class="text-center" style="height: 100%">
                                                        <v-container fill-height>
                                                            <v-row align="center" v-if="msgList.length === 0">
                                                                <v-col cols="12" class="text-center">
                                                                    <v-icon x-large class="mb-1 pb-1">cloud_upload</v-icon>
                                                                    <h1>Drag files or click to upload a EDIFACT Message file</h1>
                                                                    <file-upload class="my-0 py-0" post-action="" :multiple="false" :drop="true" :drop-directory="true" v-model="msgList" ref="edifactMSGUploader">
                                                                    </file-upload>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row v-else>
                                                                <v-col cols="12" v-for="(file, index) in msgList" :key="index" class="text-center">
                                                                    <div>
                                                                        <v-badge>
                                                                            <template v-slot:badge>
                                                                                <v-icon @click="removeMSG()">close</v-icon>
                                                                            </template>
                                                                            <v-icon x-large>{{ getFileIcon(file) }}</v-icon>
                                                                        </v-badge>
                                                                        <p>{{ file.name }}</p>
                                                                    </div>
                                                                    <file-upload class="my-0 py-0" post-action="" :multiple="false" :drop="true" :drop-directory="true" v-model="msgList" ref="edifactMSGUploader">
                                                                    </file-upload>
                                                                </v-col>
                                                            </v-row>
                                                        </v-container>
                                                    </v-card-text>
                                                </v-card>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12" sm="8" md="8" lg="8" class="text-right">
                                        <v-data-table :key="refreshKey" hide-default-footer :items="msgFileOptions" :headers="jsonHeaders" :options="{
                                              page: pageMsg,
                                              itemsPerPage: msgParams.limit,
                                              pageStart: 1,
                                            }" item-key="fileName" class="elevation-1">
                                            <template v-slot:[`item.actions`]="{ item }">
                                                <div>
                                                    <v-icon v-if="item.fileName === selectedMsg">check_box</v-icon>
                                                    <v-btn @click="handleMsgRowClick(item)" v-else icon>
                                                        <v-icon>check_box_outline_blank</v-icon>
                                                    </v-btn>
                                                    <v-btn @click="deleteFileDialog = true, fileToDelete = { ...item, deleteType: 'message' }" icon color="primary">
                                                        <v-icon>delete</v-icon>
                                                    </v-btn>
                                                </div>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="jsonEditDialog" class="black-dialog" width="1000px" height="1000px" persistent>
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>Editing JSON</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn @click="uploadEdited()" icon>
                    <v-icon color="success">check</v-icon>
                </v-btn>
                <v-btn @click="jsonEditDialog = !jsonEditDialog" icon>
                    <v-icon color="primary">close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <!-- <v-textarea outlined no-resize rows="5" v-model="editingJSON"></v-textarea> -->

                <vue-json-pretty v-model="editingJSON" editableTrigger="dblclick" :data="editingJSON" :showLine="false" :virtual="true" :showIcon="true" :editable="true"/>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="modifyBuildDialog" class="black-dialog" width="1000px" height="1000px" persistent>
        <v-card>
            <v-card-title>Display of Copy</v-card-title>
            <v-toolbar flat>
                <v-spacer></v-spacer>
                <el-input dense v-model="newBuildName" clearable placeholder="Name of Template"></el-input>
                <v-btn @click="saveCopyOfTemplate()" icon>
                    <v-icon color="success">save_as</v-icon>
                </v-btn>
                <v-btn icon @click="modifyBuildDialog = false, commeceCopyCreationDialog = true">
                    <v-icon color="primary">close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-card style="background-color: var(--v-background-lighten1) !important">
                    <v-card-text>
                        <p><b>Message Type:</b> {{ this.jsonObj.MessageType }}</p>
                        <p><b>Source:</b> {{ this.jsonObj.Source }}</p>
                        <p><b>Release:</b> {{ this.jsonObj.Release }}</p>
                        <p><b>Version:</b> {{ this.jsonObj.Version }}</p>
                        <p><b>Date:</b> {{ this.jsonObj.Date }}</p>
                        <p><b>Revision:</b> {{ this.jsonObj.Revision }}</p>
                        <p><b>ContrAgency:</b> {{ this.jsonObj.ContrAgency }}</p>
                    </v-card-text>
                </v-card>
                <v-card style="max-height: 300px; overflow-y: auto;">
                    <v-card-title>Segment Table</v-card-title>
                    <v-expansion-panels>
                        <v-expansion-panel v-for="(item, index) in computedBuild" :key="index">
                            <template v-if="!Array.isArray(item) && !item.hasOwnProperty('subGroups')" flat style="background-color: var(--v-greyRaised-lighten1) !important" class="mb-2">
                                <v-expansion-panel-header>
                                    <h3>{{ item.name }} [{{ item.seg }}]</h3>
                                    <v-spacer></v-spacer>
                                    <div class="d-flex justify-end">
                                        <!-- Added a wrapper with classes to align elements to the right -->
                                        <v-chip v-if="item.required === 'M'" outlined style="border-radius: 30px;" right color="primary" class="ma-1">M</v-chip>
                                        <v-chip v-else right color="info" class="ma-1" outlined style="border-radius: 30px;">C</v-chip>
                                        <v-chip v-if="!item.position.includes('.')" right color="secondary" class="ma-1" outlined style="border-radius: 10px;" :key="segmentKey">{{ item.repeat }}</v-chip>
                                        <v-icon large :color=inUseMandatoryColorPicker(part) v-if="part.required === 'M'">check_circle</v-icon>
                                        <v-btn :disabled="item.required === 'M'" v-else-if="item.inUse === true" icon>
                                            <v-icon large :color="inUseColorPicker(item)">check_circle</v-icon>
                                        </v-btn>
                                        <v-btn :disabled="item.required === 'M'" v-else icon>
                                            <v-icon large color="#626262">unpublished</v-icon>
                                        </v-btn>
                                    </div>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content style="background-color: var(--v-background-lighten1) !important" class="my-3 pb-2">
                                    <div v-for="(element, index) in item.DataElements" :key="index">
                                        <!-- Main Element -->
                                        <v-chip small class="ma-1" outlined :key="element.id" :color="element.componentDataElements ? 'black' : (element.mappedValue ? 'success' : (element.inUse ? (element.requirement === 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)') : '#626262'))">
                                            <v-avatar v-if="element.requirement === 'M'" left class="darken-4">M</v-avatar>
                                            <v-avatar v-else left class="darken-4">C</v-avatar>
                                            <div v-if="showElementIDAndName">
                                                [{{ element.elementId }}] {{ element.name }}
                                            </div>
                                            <div v-else-if="showElementNameOnly">
                                                {{ element.name }}
                                            </div>
                                            <div v-else-if="showElementIDOnly">
                                                {{ element.elementId }}
                                            </div>
                                        </v-chip>

                                        <!-- Component Elements (if present) -->
                                        <template v-if="element.componentDataElements && element.componentDataElements.length > 0">
                                            <v-chip v-for="(component, cIndex) in element.componentDataElements" :key="cIndex" small class="ma-1" outlined :color="component.element.mappedValue ? 'success' : (component.element.inUse ? (component.element.requirement === 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)') : '#626262')">
                                                <v-avatar v-if="component.element.requirement === 'M'" left class="darken-4">M</v-avatar>
                                                <v-avatar v-else left class="darken-4">C</v-avatar>
                                                <div v-if="showElementIDAndName">
                                                    [{{ component.element.elementId }}] {{ component.element.name }}
                                                </div>
                                                <div v-else-if="showElementNameOnly">
                                                    {{ component.element.name }}
                                                </div>
                                                <div v-else-if="showElementIDOnly">
                                                    {{ component.element.elementId }}
                                                </div>
                                            </v-chip>
                                        </template>
                                    </div>
                                </v-expansion-panel-content>
                            </template>
                            <template v-if="Array.isArray(item)">
                                <v-expansion-panel-header>
                                    <h3>{{ item[0].name }}</h3>
                                    <v-spacer></v-spacer>
                                    <div class="d-flex justify-end">
                                        <v-chip outlined style="border-radius: 30px;" v-if="item[0].required === 'M'" right :color="item[0].required == 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)'" class="ma-1">M</v-chip>
                                        <v-chip outlined style="border-radius: 30px;" v-else right :color="item[0].required == 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)'" class="ma-1">C</v-chip>
                                        <v-chip v-if="!item[0].position.includes('.')" right color="secondary" class="ma-1" outlined style="border-radius: 10px;">{{ item[0].repeat }}</v-chip>
                                    </div>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-card v-for="(part) in item.slice(1)" :key="part.position" class="mx-3 mb-2" style="background-color: var(--v-background-lighten1) !important">
                                        <v-toolbar dense flat style="background-color: var(--v-greyBase-base) !important">
                                            <!-- <v-chip left outlined class="ma-1">{{ part.position }}</v-chip> -->
                                            <v-toolbar-title>{{ part.name }} [{{ part.seg }}]</v-toolbar-title>
                                            <v-spacer></v-spacer>
                                            <v-chip v-if="part.required === 'M'" outlined style="border-radius: 30px;" right :color="part.required == 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)'" class="ma-1">M</v-chip>
                                            <v-chip v-else right :color="part.required == 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)'" outlined style="border-radius: 30px;" class="ma-1">C</v-chip>
                                            <v-chip v-if="!part.position.includes('.')" right color="secondary" class="ma-1" outlined style="border-radius: 10px;">{{ part.repeat }}</v-chip>
                                            <v-icon large :color=inUseMandatoryColorPicker(part) v-if="part.required === 'M'">check_circle</v-icon>
                                            <v-btn :disabled="part.required === 'M'" v-else-if="part.inUse === true" icon>
                                                <v-icon large :color="inUseColorPicker(part)">check_circle</v-icon>
                                            </v-btn>
                                            <v-btn :disabled="part.required === 'M'" v-else icon>
                                                <v-icon large color="#626262">unpublished</v-icon>
                                            </v-btn>
                                        </v-toolbar>
                                        <v-card-text>
                                            <div v-for="(element, index) in part.DataElements" :key="index">
                                                <v-chip class="ma-1" small outlined :key="element.id" :color="element.componentDataElements ? 'black' : (element.mappedValue ? 'success' : (element.inUse ? (element.requirement === 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)') : '#626262'))">
                                                    <v-avatar v-if="element.requirement === 'M'" left class="darken-4">M</v-avatar>
                                                    <v-avatar v-else left class="darken-4">C</v-avatar>
                                                    <div v-if="showElementIDAndName">
                                                        [{{ element.elementId }}] {{ element.name }}
                                                    </div>
                                                    <div v-else-if="showElementNameOnly">
                                                        {{ element.name }}
                                                    </div>
                                                    <div v-else-if="showElementIDOnly">
                                                        {{ element.elementId }}
                                                    </div>
                                                </v-chip>
                                                <template v-if="element.componentDataElements && element.componentDataElements.length > 0">
                                                    <v-chip v-for="(component, cIndex) in element.componentDataElements" :key="cIndex" class="ma-1" small outlined :color="component.element.mappedValue ? 'success' : (component.element.inUse ? (component.element.requirement === 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)') : '#626262')">
                                                        <v-avatar v-if="component.element.requirement === 'M'" left class="darken-4">M</v-avatar>
                                                        <v-avatar v-else left class="darken-4">C</v-avatar>
                                                        <div v-if="showElementIDAndName">
                                                            [{{ component.element.elementId }}] {{ component.element.name }}
                                                        </div>
                                                        <div v-else-if="showElementNameOnly">
                                                            {{ component.element.name }}
                                                        </div>
                                                        <div v-else-if="showElementIDOnly">
                                                            {{ component.element.elementId }}
                                                        </div>
                                                    </v-chip>
                                                </template>
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-expansion-panel-content>
                            </template>
                            <template v-if="!Array.isArray(item) && item.hasOwnProperty('subGroups') || item.hasOwnProperty('groupElements')">
                                <v-expansion-panel-header>
                                    <h3>{{ item.name }}</h3>
                                    <v-spacer></v-spacer>
                                    <div class="d-flex justify-end">
                                        <v-chip v-if="item.required === 'M'" outlined style="border-radius: 30px;" right color="primary" class="ma-1">M</v-chip>
                                        <v-chip v-else right color="info" class="ma-1" outlined style="border-radius: 30px;">C</v-chip>
                                        <v-chip v-if="!item.position.includes('.')" right color="secondary" class="ma-1" outlined style="border-radius: 10px;">{{ item.repeat }}</v-chip>
                                    </div>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-card v-for="(part) in item.groupElements" :key="part.position" class="mx-3 mb-2" style="background-color: var(--v-background-lighten1) !important">
                                        <v-toolbar dense flat style="background-color: var(--v-greyBase-base) !important">
                                            <!-- <v-chip left outlined class="ma-1">{{ part.position }}</v-chip> -->
                                            <v-toolbar-title>{{ part.name }} [{{ part.seg }}]</v-toolbar-title>
                                            <v-spacer></v-spacer>
                                            <v-chip v-if="part.required === 'M'" outlined style="border-radius: 30px;" right :color="part.required == 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)'" class="ma-1">M</v-chip>
                                            <v-chip v-else right :color="part.required == 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)'" outlined style="border-radius: 30px;" class="ma-1">C</v-chip>
                                            <v-chip v-if="!part.position.includes('.')" right color="secondary" class="ma-1" outlined style="border-radius: 10px;">{{ part.repeat }}</v-chip>
                                            <v-icon large :color=inUseMandatoryColorPicker(part) v-if="part.required === 'M'">check_circle</v-icon>
                                            <v-btn :disabled="part.required === 'M'" v-else-if="part.inUse === true" icon>
                                                <v-icon large :color="inUseColorPicker(part)">check_circle</v-icon>
                                            </v-btn>
                                            <v-btn :disabled="part.required === 'M'" v-else icon>
                                                <v-icon large color="#626262">unpublished</v-icon>
                                            </v-btn>
                                        </v-toolbar>
                                        <v-card-text>
                                            <div v-for="(element, index) in part.DataElements" :key="index">
                                                <v-chip class="ma-1" small outlined :key="element.id" :color="element.componentDataElements ? 'black' : (element.mappedValue ? 'success' : (element.inUse ? (element.requirement === 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)') : '#626262'))">
                                                    <v-avatar v-if="element.requirement === 'M'" left class="darken-4">M</v-avatar>
                                                    <v-avatar v-else left class="darken-4">C</v-avatar>
                                                    <div v-if="showElementIDAndName">
                                                        [{{ element.elementId }}] {{ element.name }}
                                                    </div>
                                                    <div v-else-if="showElementNameOnly">
                                                        {{ element.name }}
                                                    </div>
                                                    <div v-else-if="showElementIDOnly">
                                                        {{ element.elementId }}
                                                    </div>
                                                </v-chip>
                                                <template v-if="element.componentDataElements && element.componentDataElements.length > 0">
                                                    <v-chip v-for="(component, cIndex) in element.componentDataElements" :key="`component-${index}-${cIndex}`" class="ma-1" small outlined :color="component.element.mappedValue ? 'success' : (component.element.inUse ? (component.element.requirement === 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)') : '#626262')">
                                                        <v-avatar v-if="component.element.requirement === 'M'" left class="darken-4">M</v-avatar>
                                                        <v-avatar v-else left class="darken-4">C</v-avatar>
                                                        <div v-if="showElementIDAndName">
                                                            [{{ component.element.elementId }}] {{ component.element.name }}
                                                        </div>
                                                        <div v-else-if="showElementNameOnly">
                                                            {{ component.element.name }}
                                                        </div>
                                                        <div v-else-if="showElementIDOnly">
                                                            {{ component.element.elementId }}
                                                        </div>
                                                    </v-chip>
                                                </template>
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                    <v-expansion-panels>
                                        <v-expansion-panel v-for="(subGroup, index) in item.subGroups" :key="index" class="mx-3 mb-2" style="background-color: var(--v-background-lighten1) !important">
                                            <v-expansion-panel-header style="background-color: var(--v-greyBase-base) !important">
                                                <h3>{{ subGroup[0].name }}</h3>
                                                <v-spacer></v-spacer>
                                                <div class="d-flex justify-end">
                                                    <v-chip outlined style="border-radius: 30px;" v-if="subGroup[0].required === 'M'" right :color="subGroup[0].required == 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)'" class="ma-1">M</v-chip>
                                                    <v-chip outlined style="border-radius: 30px;" v-else right :color="subGroup[0].required == 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)'" class="ma-1">C</v-chip>
                                                    <v-chip v-if="!subGroup[0].position.includes('.')" right color="secondary" class="ma-1" outlined style="border-radius: 10px;">{{
                                                subGroup[0].repeat }}</v-chip>
                                                </div>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-card v-for="(part) in subGroup.slice(1)" :key="part.position" class="mx-3 mb-2" style="background-color: var(--v-background-lighten1) !important">
                                                    <v-toolbar dense flat style="background-color: var(--v-greyBase-base) !important">
                                                        <!-- <v-chip left outlined class="ma-1">{{ item.position }}</v-chip> -->
                                                        <v-toolbar-title>{{ part.name }} [{{ part.seg }}]</v-toolbar-title>
                                                        <v-spacer></v-spacer>
                                                        <v-chip v-if="part.required === 'M'" outlined style="border-radius: 30px;" right :color="part.required == 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)'" class="ma-1">M</v-chip>
                                                        <v-chip v-else right :color="part.required == 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)'" outlined style="border-radius: 30px;" class="ma-1">C</v-chip>
                                                        <v-chip v-if="!part.position.includes('.')" right color="secondary" class="ma-1" outlined style="border-radius: 10px;">{{
                                                        part.repeat }}</v-chip>
                                                        <v-icon large :color=inUseMandatoryColorPicker(part) v-if="part.required === 'M'">check_circle</v-icon>
                                                        <v-btn :disabled="part.required === 'M'" v-else-if="part.inUse === true" icon>
                                                            <v-icon large :color="inUseColorPicker(part)">check_circle</v-icon>
                                                        </v-btn>
                                                        <v-btn :disabled="part.required === 'M'" v-else icon>
                                                            <v-icon large color="#626262">unpublished</v-icon>
                                                        </v-btn>
                                                    </v-toolbar>
                                                    <v-card-text>
                                                        <div v-for="(element, index) in part.DataElements" :key="index">
                                                            <v-chip class="ma-1" small outlined :key="element.id" :color="element.componentDataElements ? 'black' : (element.mappedValue ? 'success' : (element.inUse ? (element.requirement === 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)') : '#626262'))">
                                                                <v-avatar v-if="element.requirement === 'M'" left class="darken-4">M</v-avatar>
                                                                <v-avatar v-else left class="darken-4">C</v-avatar>
                                                                <div v-if="showElementIDAndName">
                                                                    [{{ element.elementId }}] {{ element.name }}
                                                                </div>
                                                                <div v-else-if="showElementNameOnly">
                                                                    {{ element.name }}
                                                                </div>
                                                                <div v-else-if="showElementIDOnly">
                                                                    {{ element.elementId }}
                                                                </div>
                                                            </v-chip>
                                                            <template v-if="element.componentDataElements && element.componentDataElements.length > 0">
                                                                <v-chip v-for="(component, cIndex) in element.componentDataElements" :key="`component-${index}-${cIndex}`" class="ma-1" small outlined :color="component.element.mappedValue ? 'success' : (component.element.inUse ? (component.element.requirement === 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)') : '#626262')">
                                                                    <v-avatar v-if="component.element.requirement === 'M'" left class="darken-4">M</v-avatar>
                                                                    <v-avatar v-else left class="darken-4">C</v-avatar>
                                                                    <div v-if="showElementIDAndName">
                                                                        [{{ component.element.elementId }}] {{ component.element.name }}
                                                                    </div>
                                                                    <div v-else-if="showElementNameOnly">
                                                                        {{ component.element.name }}
                                                                    </div>
                                                                    <div v-else-if="showElementIDOnly">
                                                                        {{ component.element.elementId }}
                                                                    </div>
                                                                </v-chip>
                                                            </template>
                                                        </div>
                                                    </v-card-text>
                                                </v-card>
                                                <v-expansion-panels v-if="subGroup[0].subGroups">
                                                    <v-expansion-panel v-for="(subOfSubGroup, index) in subGroup[0].subGroups" :key="index" class="mx-3 mb-3" style="background-color: var(--v-background-lighten1) !important">
                                                        <v-expansion-panel-header style="background-color: var(--v-greyBase-base) !important">
                                                            <h3>{{ subOfSubGroup[0].name }}</h3>
                                                            <v-spacer></v-spacer>
                                                            <div class="d-flex align-end justify-end">
                                                                <v-chip outlined style="border-radius: 30px;" v-if="subOfSubGroup[0].required === 'M'" right :color="subOfSubGroup[0].required == 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)'" class="ma-1">M</v-chip>
                                                                <v-chip outlined style="border-radius: 30px;" v-else right :color="subOfSubGroup[0].required == 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)'" class="ma-1">C</v-chip>
                                                                <v-chip v-if="!subOfSubGroup[0].position.includes('.')" right color="secondary" class="ma-1" outlined style="border-radius: 10px;">{{
                                                subOfSubGroup[0].repeat }}</v-chip>
                                                            </div>
                                                        </v-expansion-panel-header>
                                                        <v-expansion-panel-content>
                                                            <v-card v-for="(part) in subOfSubGroup.slice(1)" :key="part.position" class="mx-3 mb-2" style="background-color: var(--v-background-lighten1) !important">
                                                                <v-toolbar dense flat style="background-color: var(--v-greyBase-base) !important">
                                                                    <!-- <v-chip left outlined class="ma-1">{{ item.position }}</v-chip> -->
                                                                    <v-toolbar-title>{{ part.name }} [{{ part.seg }}]</v-toolbar-title>
                                                                    <v-spacer></v-spacer>
                                                                    <v-chip v-if="part.required === 'M'" outlined style="border-radius: 30px;" right :color="part.required == 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)'" class="ma-1">M</v-chip>
                                                                    <v-chip v-else right :color="part.required == 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)'" outlined style="border-radius: 30px;" class="ma-1">C</v-chip>
                                                                    <v-chip v-if="!part.position.includes('.')" right color="secondary" class="ma-1" outlined style="border-radius: 10px;">{{
                                                        part.repeat }}</v-chip>
                                                                    <v-icon large :color=inUseMandatoryColorPicker(part) v-if="part.required === 'M'">check_circle</v-icon>
                                                                    <v-btn :disabled="part.required === 'M'" v-else-if="part.inUse === true" icon>
                                                                        <v-icon large :color="inUseColorPicker(part)">check_circle</v-icon>
                                                                    </v-btn>
                                                                    <v-btn :disabled="part.required === 'M'" v-else icon>
                                                                        <v-icon large color="#626262">unpublished</v-icon>
                                                                    </v-btn>
                                                                </v-toolbar>
                                                                <v-card-text>
                                                                    <div v-for="(element, index) in part.DataElements" :key="index">
                                                                        <v-chip class="ma-1" small outlined :key="element.id" :color="element.componentDataElements ? 'black' : (element.mappedValue ? 'success' : (element.inUse ? (element.requirement === 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)') : '#626262'))">
                                                                            <v-avatar v-if="element.requirement === 'M'" left class="darken-4">M</v-avatar>
                                                                            <v-avatar v-else left class="darken-4">C</v-avatar>
                                                                            <div v-if="showElementIDAndName">
                                                                                [{{ element.elementId }}] {{ element.name }}
                                                                            </div>
                                                                            <div v-else-if="showElementNameOnly">
                                                                                {{ element.name }}
                                                                            </div>
                                                                            <div v-else-if="showElementIDOnly">
                                                                                {{ element.elementId }}
                                                                            </div>
                                                                        </v-chip>
                                                                        <template v-if="element.componentDataElements && element.componentDataElements.length > 0">
                                                                            <v-chip v-for="(component, cIndex) in element.componentDataElements" :key="`component-${index}-${cIndex}`" class="ma-1" small outlined :color="component.element.mappedValue ? 'success' : (component.element.inUse ? (component.element.requirement === 'M' ? 'var(--v-primary-lighten1)' : 'var(--v-info-lighten1)') : '#626262')">
                                                                                <v-avatar v-if="component.element.requirement === 'M'" left class="darken-4">M</v-avatar>
                                                                                <v-avatar v-else left class="darken-4">C</v-avatar>
                                                                                <div v-if="showElementIDAndName">
                                                                                    [{{ component.element.elementId }}] {{ component.element.name }}
                                                                                </div>
                                                                                <div v-else-if="showElementNameOnly">
                                                                                    {{ component.element.name }}
                                                                                </div>
                                                                                <div v-else-if="showElementIDOnly">
                                                                                    {{ component.element.elementId }}
                                                                                </div>
                                                                            </v-chip>
                                                                        </template>
                                                                    </div>
                                                                </v-card-text>
                                                            </v-card>
                                                        </v-expansion-panel-content>
                                                    </v-expansion-panel>
                                                </v-expansion-panels>
                                            </v-expansion-panel-content>

                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-expansion-panel-content>
                            </template>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="loadJSONDialog" class="black-dialog" width="auto" persistent>
        <v-card>
            <v-toolbar>
                <v-toolbar-title>Load JSON</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn :disabled="!chosenJSONOption" icon @click="loadFileData">
                    <v-icon color="success">start</v-icon>
                </v-btn>
                <v-btn icon @click="loadJSONDialog = false">
                    <v-icon color="primary">close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-select v-model="chosenJSONOption" :items="jsonOptions"></v-select>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="mapDialog" width="1800px" persistent height="auto">
        <v-card style="background-color: var(--v-greyBase-darken1) !important;">
            <v-toolbar flat>
                <v-toolbar-title>
                    {{ this.chosenChip.name }} [{{ this.chosenChip.elementId }}]
                    <v-chip style="border-radius: 5px;" class="ma-1" outlined color="primary"> {{ this.chosenChip.requirement || this.chosenChip.required || "C"}}</v-chip>
                    <v-chip style="border-radius: 5px;" class="ma-1" outlined color="secondary">{{ this.chosenChip.max || 1 }}</v-chip>
                    <v-chip style="border-radius: 5px;" v-if="this.chosenChip.repr" class="ma-1" outlined color="tertiary">{{ (this.chosenChip.repr).trim().slice(0, this.chosenChip.repr.indexOf(" ")) }}</v-chip>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn class="ma-1" color="secondary" :disabled="this.chosenChip.inUse === false" @click="markAsEnd(), fetchPreviewMessage()" v-if="!this.chosenChip.hide || this.chosenChip.hide === false">Hide Following Seperator</v-btn>
                <v-btn class="ma-1" color="secondary" @click="markAsEnd(), fetchPreviewMessage()" v-else>Show Following Seperator</v-btn>
                <v-btn class="ma-1" color="secondary" v-if="this.chosenChip.inUse === true" @click="markNotInUse(), fetchPreviewMessage()">Mark Not In-Use</v-btn>
                <v-btn class="ma-1" color="secondary" :disabled="this.chosenChip.hide === true" v-else @click="markNotInUse(), fetchPreviewMessage()">Mark In-Use</v-btn>
                <v-btn @click="keyValuePairDialog = true, mapDialog = false" class="ma-1" color="info">Set Key-Value-Pairs</v-btn>
                <v-btn v-if="!chosenChip.static" @click="setStaticDialog = true" class="ma-1" color="info">Set Static</v-btn>
                <v-btn v-else class="ma-1" :disabled="!chosenChip.static" color="error" @click="removeStatic(), fetchPreviewMessage()">Remove Static</v-btn>
                <!-- <v-btn @click="useStatic()" :disabled="!this.chosenChip.static || Array.isArray(this.chosenChip.static)" color="info">Use Set Static</v-btn> -->
                <v-btn :key="refreshKey" v-if="this.chosenChip.flagged === false || !this.chosenChip.flagged" icon @click="flagFunc()">
                    <v-icon large color="#626262">flag_circle</v-icon>
                </v-btn>
                <v-btn :key="refreshKey + 1" v-else icon @click="flagFunc()">
                    <v-icon large color="error">flag_circle</v-icon>
                </v-btn>
                <v-btn class="ma-1" :disabled="commentsLoading === true" icon @click="mapDialog = false">
                    <v-icon color="primary">close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <p>Element ID: {{ chosenChip.elementId }}</p>
                        <p>Name: {{ chosenChip.name }}</p>
                        <p>Mapped Value: {{ chosenChip.mappedValue || "NULL" }}</p>
                        <p>Requirement: {{ chosenChip.requirement || chosenChip.required || "C" }}</p>
                        <p>Qualifier: {{ chosenChip.qualifier }}</p>
                        <p>Mask: {{ chosenChip.mask || "None" }}</p>
                    </v-col>
                    <v-col cols="6">
                        <p>Repr: {{ chosenChip.repr }}</p>
                        <p>Max: {{ chosenChip.max }}</p>
                        <p>Note: {{ chosenChip.note }}</p>
                        <p>Description: {{ chosenChip.desc }}</p>
                        <p>
                            <v-text-field dense outlined label="Comment(s)" v-model="comment">
                                <template slot="append">
                                    <v-btn class="mt-n2" :loading="commentsLoading" @click="saveComments(comment)" icon>
                                        <v-icon>save</v-icon>
                                    </v-btn>
                                </template>
                            </v-text-field>
                            <v-text-field dense outlined label="End Tag" v-model="endTag" clearable>
                                <template slot="append">
                                    <v-btn class="mt-n2" :loading="commentsLoading" @click="setEndTag(), fetchPreviewMessage()" icon>
                                        <v-icon>save</v-icon>
                                    </v-btn>
                                </template>
                            </v-text-field>
                        </p>
                    </v-col>
                </v-row>
                <v-card v-if="Array.isArray(this.chosenChip.static)">
                    <v-card-text>
                        <v-row>
                            <v-col cols="8">
                                <v-card>
                                    <v-card-text>
                                        <el-input suffix-icon="el-icon-search" v-model="dataSearch" clearable placeholder="Search"></el-input>
                                        <v-row>
                                            <v-col cols="12" sm="4" md="4" lg="4" class="text-left">
                                                <v-tabs v-model="tab" color="primary" vertical style="max-height: 350px; overflow-y: auto;">
                                                    <v-tab dense v-for="tab in tabs" :key="tab" :value="tab">
                                                        {{ tab }}
                                                    </v-tab>
                                                </v-tabs>
                                            </v-col>
                                            <v-col cols="12" sm="8" md="8" lg="8" class="text-left">
                                                <v-card class="mx-auto" tile>
                                                    <v-list dense style="max-height: 350px; overflow-y: auto;">
                                                        <v-list-item-group v-model="selectedItem" active-class="primary--text">
                                                            <v-list-item @click="setValueForMapping(item)" v-for="(item, i) in filteredDataItems" :key="i">
                                                                <v-list-item-content>
                                                                    <v-list-item-title>{{ item.path }}
                                                                        <v-icon color="primary">arrow_forward</v-icon>{{ item.value }}
                                                                    </v-list-item-title>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </v-list-item-group>
                                                    </v-list>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="4">
                                <h2>Key-Value Pairs</h2>
                                <v-list style="max-height: 450px; overflow-y: auto;">
                                    <v-list-item v-for="(item, i) in this.chosenChip.static" :key="i">
                                        <v-card class="mt-4">
                                            <v-toolbar flat>
                                                <v-toolbar-title>{{ item.name }}
                                                    <v-icon>arrow_forward</v-icon> {{ item.value }}
                                                </v-toolbar-title>
                                                <v-spacer></v-spacer>
                                                <v-btn @click="removeKeyValuePair(item)" icon>
                                                    <v-icon color="primary">delete</v-icon>
                                                </v-btn>
                                            </v-toolbar>
                                            <v-card-text>
                                                {{ item.description }}
                                            </v-card-text>
                                        </v-card>
                                    </v-list-item>
                                </v-list>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card v-else>
                    <v-card-text>
                        <el-input suffix-icon="el-icon-search" v-model="dataSearch" clearable placeholder="Search">
                        </el-input>
                        <v-row>
                            <v-col cols="12" sm="4" md="4" lg="4" class="text-left">
                                <v-tabs v-model="tab" color="primary" vertical style="max-height: 400px; overflow-y: auto;">
                                    <v-tab dense v-for="tab in tabs" :key="tab" :value="tab">
                                        {{ tab }}
                                    </v-tab>
                                </v-tabs>
                            </v-col>
                            <v-col cols="12" sm="8" md="8" lg="8" class="text-left">
                                <v-card class="mx-auto" tile>
                                    <v-list dense style="max-height: 400px; overflow-y: auto;">
                                        <v-list-item-group v-model="selectedItem" active-class="primary--text">
                                            <v-list-item @click="setValueForMapping(item)" v-for="(item, i) in filteredDataItems" :key="i">
                                                <v-list-item-content>
                                                    <v-list-item-title style="white-space: normal;">
                                                        {{ item.path }}
                                                        <v-icon color="primary">arrow_forward</v-icon>
                                                        <!-- Use a conditional statement to display only non-objects and non-arrays -->
                                                        <template v-if="!Array.isArray(item.value) && typeof item.value !== 'object'">
                                                            {{ item.value }}
                                                        </template>
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-card-text>
            <v-toolbar flat>
                <v-spacer></v-spacer>
                <v-btn v-if="!this.chosenChip.mask || this.chosenChip.mask === null" @click="maskDialog=true" class="ma-1" color="info">Add Mask</v-btn>
                <v-btn v-else class="ma-1" color="primary" @click="() => removeMask()">Remove Mask</v-btn>
                <v-btn v-if="this.chosenChip.mappedValue && this.chosenChip.mappedValue !== null" class="ma-1" color="primary" @click="unmap(), fetchPreviewMessage()">Unmap</v-btn>
                <v-btn v-else class="ma-1" color="success" @click="mapToJSON(selectedItemToMap), fetchPreviewMessage()">Map</v-btn>
            </v-toolbar>
        </v-card>
    </v-dialog>

    <v-dialog v-model="staticArrayDialog" width="1500px" persistent height="auto">
        <v-card style="background-color: var(--v-greyBase-darken1) !important;">
            <v-toolbar flat>
                <v-toolbar-title>

                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn class="ma-1" icon @click="staticArrayDialog = false">
                    <v-icon color="primary">close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-card>
                    <v-card-text>
                        <el-input suffix-icon="el-icon-search" v-model="dataSearch" clearable placeholder="Search"></el-input>
                        <v-row>
                            <v-col cols="12" sm="4" md="4" lg="4" class="text-left">
                                <v-tabs v-model="tab" color="primary" vertical style="max-height: 400px; overflow-y: auto;">
                                    <v-tab dense v-for="tab in tabs" :key="tab" :value="tab">
                                        {{ tab }}
                                    </v-tab>
                                </v-tabs>
                            </v-col>
                            <v-col cols="12" sm="8" md="8" lg="8" class="text-left">
                                <v-card class="mx-auto" tile>
                                    <v-list dense style="max-height: 400px; overflow-y: auto;">
                                        <v-list-item-group v-model="selectedItem" active-class="primary--text">
                                            <v-list-item @click="setValueForMapping(item)" v-for="(item, i) in filteredDataItems" :key="i">
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ item.path }}
                                                        <v-icon color="primary">arrow_forward</v-icon>{{ item.value }}
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-card-text>
            <v-toolbar flat>
                <v-spacer></v-spacer>
                <v-btn v-if="this.chosenChip.mappedValue && this.chosenChip.mappedValue !== null" class="ma-1" color="primary" @click="unmap()">Unmap</v-btn>
                <v-btn v-else class="ma-1" color="success" @click="mapToJSON(selectedItemToMap)">Map</v-btn>
            </v-toolbar>
        </v-card>
    </v-dialog>

    <v-dialog v-model="setStaticDialog" width="500px">
        <v-card style="background-color: var(--v-greyBase-darken1) !important; height: auto;">
            <v-toolbar flat>
                <v-spacer></v-spacer>
                <v-btn @click="setStatic(), fetchPreviewMessage()" color="success">Set</v-btn>
                <v-btn color="primary" icon @click="mapDialog = true, setStaticDialog = false">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-text-field label="Enter Static Value" v-model="staticValue"></v-text-field>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="keyValuePairDialog" width="1000px" height="auto">
        <v-card style="background-color: var(--v-greyBase-darken1) !important; height: auto;">
            <v-toolbar flat>
                <v-toolbar-title>Key Value Pairs</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn @click="setKeyValuePairs" color="success" :disabled="isButtonDisabled">Set Key-Value-Pairs</v-btn>
                <v-btn icon @click="addKeyValuePairs()">
                    <v-icon color="info">add</v-icon>
                </v-btn>
                <v-btn icon @click="removeRow()" :disabled="rightColumnItems.length === 0 || leftColumnItems.length === 0 ">
                    <v-icon color="error">remove</v-icon>
                </v-btn>
                <v-btn icon @click="mapDialog = true, keyValuePairDialog = false">
                    <v-icon color="primary">close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col cols="3">
                                <v-text-field v-for="(item, index) in leftColumnItems" :key="index" v-model="item.name" label="Name"></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field v-for="(item, index) in rightColumnItems" :key="index" v-model="item.value" label="Value"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field v-for="(item, index) in descriptionColumnItems" :key="index" v-model="item.description" label="Description"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="referenceDialog" width="500px">
        <v-card style="background-color: var(--v-greyBase-darken1) !important; height: auto;">
            <v-toolbar flat>
                <v-toolbar-title>Help Guide</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on">Display Options</v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="showElementIDOnly = true, showElementNameOnly = false, showElementIDAndName = false">Element ID Only</v-list-item>
                        <v-list-item @click="showElementIDOnly = false, showElementNameOnly = true, showElementIDAndName = false">Element Name Only</v-list-item>
                        <v-list-item @click="showElementIDOnly = false, showElementNameOnly = false, showElementIDAndName = true">Element ID and Name</v-list-item>
                    </v-list>
                </v-menu>
                <v-btn icon @click="referenceDialog = false">
                    <v-icon color="primary">close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-tabs grow v-model="selectedReferenceTab">
                <v-tab :key="'segments'">Segments</v-tab>
                <v-tab :key="'elements'">Elements</v-tab>
            </v-tabs>

            <v-tabs-items v-model="selectedReferenceTab">
                <v-tab-item :key="'segments'">
                    <v-card-text>
                        <p>
                            <v-chip outlined style="border-radius: 30px;" color="primary">M</v-chip>
                            <v-icon class="ma-2">forward</v-icon> Mandatory segment.
                        </p>
                        <p>
                            <v-chip color="info" outlined style="border-radius: 30px;">C</v-chip>
                            <v-icon class="ma-2">forward</v-icon> Conditional segment.
                        </p>
                        <p>
                            <v-chip color="secondary" outlined style="border-radius: 10px;">9</v-chip>
                            <v-icon class="ma-2">forward</v-icon> Total allowed repeats.
                        </p>
                        <p>
                            <v-icon large color="green">check_circle</v-icon>
                            <v-icon class="ma-2">forward</v-icon> Fully Mapped.
                        </p>
                        <p>
                            <v-icon large color="warning">check_circle</v-icon>
                            <v-icon class="ma-2">forward</v-icon> Not fully mapped.
                        </p>
                        <!-- <p>
                            <v-icon large color="primary">check_circle</v-icon>
                            <v-icon class="ma-2">forward</v-icon> Mandatory in use.
                        </p> -->
                        <p>
                            <v-icon large color="#626262">unpublished</v-icon>
                            <v-icon class="ma-2">forward</v-icon> Not in use.
                        </p>
                        <p>
                            <v-icon large color="warning">flag</v-icon>
                            <v-icon class="ma-2">forward</v-icon> Flagged segment.
                        </p>
                        <p>
                            <v-icon large color="tertiary">playlist_add</v-icon>
                            <v-icon class="ma-2">forward</v-icon> Modify Data Elements / Component Elements.
                        </p>
                    </v-card-text>
                </v-tab-item>
                <v-tab-item :key="'elements'">
                    <v-card-text>
                        <p>
                            <v-chip outlined color="black">M</v-chip>
                            <v-icon class="ma-2">forward</v-icon> Name of data element with components.
                        </p>
                        <p>
                            <v-chip outlined color="primary">M</v-chip>
                            <v-icon class="ma-2">forward</v-icon> Mandatory element.
                        </p>
                        <p>
                            <v-chip outlined color="info">C</v-chip>
                            <v-icon class="ma-2">forward</v-icon> Conditional element.
                        </p>
                        <p>
                            <v-chip outlined color="success">C</v-chip>
                            <v-icon class="ma-2">forward</v-icon> Mapped element.
                        </p>
                        <p>
                            <v-chip style="border-radius: 5px;" class="ma-1" outlined color="primary">C</v-chip>
                            <v-icon class="ma-2">forward</v-icon> Requirement indicator.
                        </p>
                        <p>
                            <v-chip style="border-radius: 5px;" class="ma-1" outlined color="secondary">1</v-chip>
                            <v-icon class="ma-2">forward</v-icon> Total allowed repeats.
                        </p>
                        <p>
                            <v-chip style="border-radius: 5px;" class="ma-1" outlined color="tertiary">n..</v-chip>
                            <v-icon class="ma-2">forward</v-icon> Repr value.
                        </p>
                        <p>
                            <v-icon large color="warning">flag</v-icon>
                            <v-icon class="ma-2">forward</v-icon> Flagged element.
                        </p>
                    </v-card-text>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </v-dialog>

    <v-dialog v-model="commenceCreationDialog" width="auto">
        <v-card width="auto">
            <v-toolbar flat style="background-color: var(--v-greyRaised-darken1) !important">
                <v-toolbar-title>Enter Name Of Service</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn class="ma-1" @click="createTemplate()" :disabled="this.templateName.length <= 0 || this.templateName === '' || this.templateName === null" color="success" icon>
                    <v-icon>save_as</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-text-field v-model="templateName"></v-text-field>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="nameIncompleteDialog" persistent width="auto">
        <v-card width="auto">
            <v-toolbar flat style="background-color: var(--v-greyRaised-darken1) !important">
                <v-toolbar-title>Save As</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn class="ma-1" @click="nameIncompleteDialog = false" color="primary" icon>
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-tabs v-model="saveTabs" background-color="transparent" grow>
                    <v-tab key="incomplete">Incomplete</v-tab>
                    <v-tab key="service">Service</v-tab>
                </v-tabs>
                <v-tabs-items v-model="saveTabs">
                    <v-tab-item key="incomplete">
                        <v-form>
                            <el-input background-color="transparent" label="Enter File Name" v-model="templateName">
                                <template slot="append">
                                    <el-button @click="submitIncomplete()" :disabled="templateName.length <= 0 || templateName === '' || templateName === null">
                                        <v-icon>save</v-icon>
                                    </el-button>
                                </template>
                            </el-input>
                        </v-form>
                    </v-tab-item>
                    <v-tab-item key="service">
                        <v-form>
                            <el-input background-color="transparent" label="Enter File Name" v-model="templateName">
                                <template slot="append">
                                    <el-button @click="createTemplate()" :disabled="this.templateName.length <= 0 || this.templateName === '' || this.templateName === null">
                                        <v-icon>upload</v-icon>
                                    </el-button>
                                </template>
                            </el-input>
                        </v-form>
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="confirmIncompleteTemplateDialog" width="auto" persistent>
        <v-card width="auto">
            <v-toolbar flat style="background-color: var(--v-greyRaised-darken1) !important">
                <v-toolbar-title>Unable to Create Service</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn @click="confirmIncompleteTemplateDialog = false" icon>
                    <v-icon color="error">close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text color="error">
                {{ this.incompleteResponse }}
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="incompleteTemplatesDialog" class="black-dialog" width="1000px" persistent>
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>Incomplete Template Options</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="incompleteTemplatesDialog = false, chosenIncomplete = null">
                    <v-icon color="primary">close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="6" md="6" lg="6" class="text-left">
                        <el-pagination style="color: var(--v-primaryText-base)" :current-page.sync="incompletePage" :page-size.sync="incompleteParams.limit" :page-sizes="[10, 25, 50, 100]" layout="sizes, prev, pager, next, jumper, total" :total="incompleteTotal"></el-pagination>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6" class="text-right">
                        <el-input suffix-icon="el-icon-search" v-model="incompleteSearch" clearable placeholder="Search">
                        </el-input>
                    </v-col>
                </v-row>
                <v-data-table :key="refreshKey" hide-default-footer :items="incompleteTemplates" :headers="templateHeaders" :options="{
        page: incompletePage,
        itemsPerPage: incompleteParams.limit,
        pageStart: 1,
      }" @click:row="selectIncomplete"></v-data-table>
            </v-card-text>
        </v-card>
    </v-dialog>

    <!-- <v-dialog v-model="previewDialog" width="800">
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>EDIFACT Message Preview</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="previewDialog = false">
                    <v-icon color="primary">close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card width="auto" max-height="60vh" style="overflow-y: auto">
                <v-card-text>
                    <div v-for="(line, index) of formattedPreviewMsg" :key="index">
                        {{ line }}
                    </div>
                </v-card-text>
            </v-card>
        </v-card>
    </v-dialog> -->

    <v-dialog v-model="editSegmentDialog" class="black-dialog" width="1000px" persistent>
        <v-card width="auto">
            <v-toolbar style="background-color: var(--v-toolbar-base) !important" flat>
                <v-toolbar-title>Data Elements of {{ currentSegment.name }} [{{ currentSegment.seg }}]</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="editSegmentDialog = false">
                    <v-icon color="primary">close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text style="max-height: 600px; overflow-y: auto;">
                <v-expansion-panels>
                    <draggable class="dragComponent" v-model="computedCurrentSeg.DataElements" :options="dragOptions" @end="fetchPreviewMessage()">
                        <v-expansion-panel class="mx-3 mb-2" v-for="(dataElement, index) in computedCurrentSeg.DataElements" :key="index">
                            <template style="background-color: var(--v-greyRaised-lighten1) !important" class="mb-2">
                                <v-expansion-panel-header>
                                    {{ dataElement.name }} [{{ dataElement.elementId }}]
                                    <v-spacer></v-spacer>
                                    <div class="d-flex justify-end">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn @click="setCDEDialog = true, selectedDEIndex = index" :disabled="cdeButtonDisabled(dataElement.componentDataElements)" icon v-on="on">
                                                    <v-icon color="info">low_priority</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Set as Component Data Element</span>
                                        </v-tooltip>
                                        <v-menu v-model="dataElement.openMenu" left>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" icon>
                                                    <v-icon color="success">add</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item @click="dialogAddDE = true, editSegmentDialog = false, dataElementIndex = index, cdeIndex = null">
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            Add Data Element
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-list-item v-if="!dataElement.componentDataElements" @click="dialogAddCDE = true, editSegmentDialog = false, dataElementIndex = index, cdeIndex = 0">
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            Add Component Data Element
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                        <v-btn icon>
                                            <v-icon @click="removeDataElement(index)" color="primary">remove</v-icon>
                                        </v-btn>
                                    </div>
                                </v-expansion-panel-header>

                                <v-expansion-panel-content style="background-color: var(--v-background-lighten1) !important" class="my-3 pb-2">
                                    <v-row>
                                        <v-col cols="6">
                                            <p>Element ID: {{ dataElement.elementId }}</p>
                                            <p>Name: {{ dataElement.name }}</p>
                                            <p>Mapped Value: {{ dataElement.mappedValue || "NULL" }}</p>
                                            <p>Requirement: {{ dataElement.requirement || dataElement.required || "C" }}</p>
                                            <p>Qualifier: {{ dataElement.qualifier }}</p>
                                        </v-col>
                                        <v-col cols="6">
                                            <p>Repr: {{ dataElement.repr }}</p>
                                            <p>Max: {{ dataElement.max }}</p>
                                            <p>Note: {{ dataElement.note }}</p>
                                            <p>Description: {{ dataElement.desc }}</p>
                                            <p>
                                                <v-text-field readonly dense outlined label="Comment(s)" v-model="dataElement.comments">
                                                </v-text-field>
                                            </p>
                                        </v-col>
                                    </v-row>
                                    <v-expansion-panels>
                                        <draggable class="dragComponent" v-model="dataElement.componentDataElements" :options="innerDragOptions" @end="resetOrder(index), fetchPreviewMessage()">
                                            <v-expansion-panel class="mx-3 mb-2" v-for="(componentDataElement, subIndex) in dataElement.componentDataElements" :key="subIndex">
                                                <template style="background-color: var(--v-greyRaised-lighten1) !important" class="mb-2">
                                                    <v-expansion-panel-header>
                                                        {{ componentDataElement.element.name }} [{{ componentDataElement.element.elementId }}]
                                                        <v-spacer></v-spacer>
                                                        <div class="d-flex justify-end">
                                                            <v-tooltip top>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn @click="setAsDataElement(index, subIndex)" icon v-on="on">
                                                                        <v-icon style="transform: rotate(180deg);" color="info">low_priority</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Set as Data Element</span>
                                                            </v-tooltip>
                                                            <v-btn icon @click="dialogAddCDE = true, editSegmentDialog = false, dataElementIndex = index, cdeIndex = subIndex">
                                                                <v-icon color="success">add</v-icon>
                                                            </v-btn>
                                                            <v-btn icon @click="removeComponentDE(index, subIndex)">
                                                                <v-icon color="primary">remove</v-icon>
                                                            </v-btn>
                                                        </div>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content style="background-color: var(--v-background-lighten2)">
                                                        <v-row>
                                                            <v-col cols="6">
                                                                <p>Order: {{ componentDataElement.order }}</p>
                                                                <p>Element ID: {{ componentDataElement.element.elementId }}</p>
                                                                <p>Name: {{ componentDataElement.element.name }}</p>
                                                                <p>Mapped Value: {{ componentDataElement.element.mappedValue || "NULL" }}</p>
                                                                <p>Requirement: {{ componentDataElement.element.requirement || componentDataElement.element.required || "C" }}</p>
                                                                <p>Qualifier: {{ componentDataElement.element.qualifier }}</p>
                                                            </v-col>
                                                            <v-col cols="6">
                                                                <p>Repr: {{ componentDataElement.element.repr }}</p>
                                                                <p>Max: {{ componentDataElement.element.max }}</p>
                                                                <p>Note: {{ componentDataElement.element.note }}</p>
                                                                <p>Description: {{ componentDataElement.element.desc }}</p>
                                                                <p>
                                                                    <v-text-field readonly dense outlined label="Comment(s)" v-model="componentDataElement.element.comments">
                                                                    </v-text-field>
                                                                </p>
                                                            </v-col>
                                                        </v-row>
                                                    </v-expansion-panel-content>
                                                </template>
                                            </v-expansion-panel>
                                        </draggable>
                                    </v-expansion-panels>
                                </v-expansion-panel-content>
                            </template>
                        </v-expansion-panel>
                    </draggable>
                </v-expansion-panels>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="setCDEDialog" class="black-dialog" width="1000px" persistent>
        <v-card width="auto">
            <v-toolbar style="background-color: var(--v-toolbar-base) !important" flat>
                <v-toolbar-title>Select Data Element To Assign To</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn @click="setAsComponentDE()" icon>
                    <v-icon color="success">check</v-icon>
                </v-btn>
                <v-btn icon @click="setCDEDialog = false">
                    <v-icon color="primary">close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-select v-model="targetDE" :items="computedCurrentSeg.DataElements" :item-text="combinedNameAndDescription" item-value="value"></v-select>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAddDE" class="black-dialog" width="1000px" persistent>
        <v-card width="auto">
            <v-toolbar style="background-color: var(--v-toolbar-base) !important" flat>
                <v-toolbar-title>Creation of Data Element</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="addDataElement()">Create</v-btn>
                <v-btn icon @click="dialogAddDE = false, editSegmentDialog = true">
                    <v-icon color="primary">close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col cols="4">
                            <v-text-field v-model="elementObj.elementId" label="Element ID" :rules="elementRules"></v-text-field>
                            <v-text-field v-model="elementObj.name" label="Name" :rules="elementRules"></v-text-field>
                            <v-text-field v-model="elementObj.requirement" label="Requirement" :rules="elementRules"></v-text-field>
                            <v-text-field v-model="elementObj.qualifier" label="Qualifier" :rules="elementRules"></v-text-field>
                            <v-text-field v-model="elementObj.max" label="Max" min="0" type="number" :rules="elementRules"></v-text-field>
                            <v-text-field v-model="elementObj.repr" label="Repr"></v-text-field>
                        </v-col>
                        <v-col cols="8">
                            <v-textarea style="margin-top: 30px;" outlined v-model="elementObj.note" label="Note"></v-textarea>
                            <v-textarea outlined v-model="elementObj.desc" label="Description"></v-textarea>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAddCDE" class="black-dialog" width="1000px" persistent>
        <v-card width="auto">
            <v-toolbar style="background-color: var(--v-toolbar-base) !important" flat>
                <v-toolbar-title>Creation of Component Data Element</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="addComponentDE()">Create</v-btn>
                <v-btn icon @click="dialogAddCDE = false, editSegmentDialog = true">
                    <v-icon color="primary">close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col cols="4">
                            <v-text-field v-model="elementObj.elementId" label="Element ID" :rules="elementRules"></v-text-field>
                            <v-text-field v-model="elementObj.name" label="Name" :rules="elementRules"></v-text-field>
                            <v-text-field v-model="elementObj.requirement" label="Requirement" :rules="elementRules"></v-text-field>
                            <v-text-field v-model="elementObj.qualifier" label="Qualifier" :rules="elementRules"></v-text-field>
                            <v-text-field v-model="elementObj.max" label="Max" min="0" type="number" :rules="elementRules"></v-text-field>
                            <v-text-field v-model="elementObj.repr" label="Repr"></v-text-field>
                        </v-col>
                        <v-col cols="8">
                            <v-textarea style="margin-top: 30px;" outlined v-model="elementObj.note" label="Note"></v-textarea>
                            <v-textarea outlined v-model="elementObj.desc" label="Description"></v-textarea>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="deleteFileDialog" class="black-dialog" width="1000px" persistent>
        <v-card width="auto">
            <v-toolbar>
                <v-toolbar-title>
                    Delete "{{ fileToDelete.fileName }}"?
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn @click="deleteStorageFile()" icon>
                    <v-icon color="success">check</v-icon>
                </v-btn>
                <v-btn @click="deleteFileDialog = false" icon>
                    <v-icon color="primary">close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                Confirm deletion of this file? Once deleted, it can not be recovered on the storage.
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="maskDialog" class="black-dialog" width="1000px" persistent>
        <v-card width="auto">
            <v-toolbar>
                <v-toolbar-title>
                    Setting a Mask
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="setMask()">
                    <v-icon color="success">check</v-icon>
                </v-btn>
                <v-btn @click="maskDialog = false, mapDialog = false" icon>
                    <v-icon color="primary">close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-select label="Date/Time Mask" v-model="selectedMask" :items="dateTimeOptions">
                </v-select>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="newSegmentDialog" class="black-dialog" width="800px" persistent>
        <v-card width="auto">
            <v-toolbar>
                <v-toolbar-title>Creating New Segment</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn :disabled="isCreateButtonDisabled" :loading="createSegLoading" icon @click="createNewSegment()">
                    <v-icon color="success">check</v-icon>
                </v-btn>
                <v-btn :disabled="createSegLoading === true" @click="newSegmentDialog = false" icon>
                    <v-icon color="primary">close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-select :label="'Select Position in Reference to ' + labelText" v-model="selectedSegPosition" :items="positionOptions"></v-select>
                <v-select label="Type of Segment" :disabled="selectedSegPosition === null" v-model="selectedSegType" :items="filteredSegmentsList">
                    <template v-slot:prepend-item>
                        <v-text-field v-model="searchSL" label="Search for Segment"></v-text-field>
                    </template>
                </v-select>
                <v-radio-group :disabled="selectedSegType === null" v-model="selectedCondition">
                    <v-radio v-for="item in radioOptions" :key="item.value" :label="item.label" :value="item.value" color="primary">
                        <v-chip outlined :color="item.color" class="ma-1" style="border-radius: 30px;">{{ item.value }}</v-chip>
                    </v-radio>
                </v-radio-group>
                <v-text-field :disabled="selectedCondition === null" type="number" v-model="selectedRepeat" label="Max Repeats" min="1"></v-text-field>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="createSegmentGroupDialog" class="black-dialog" width="800px" persistent>
        <v-card width="auto">
            <v-toolbar>
                <v-toolbar-title>Creating New Segment Group</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn :disabled="isCreateButtonDisabled" :loading="createSegLoading" icon @click="createNewSegment()">
                    <v-icon color="success">check</v-icon>
                </v-btn>
                <v-btn :disabled="createSegLoading === true" @click="createSegmentGroupDialog = false" icon>
                    <v-icon color="primary">close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-text-field type="number" v-model="selectedSegmentGroupNumber" label="Segment Group Number" min="1"></v-text-field>
                <v-select :label="'Select Position in Reference to ' + labelText" v-model="selectedSegPosition" :items="positionOptions"></v-select>
                <!-- <v-select label="Select Type of Subgroup" :items="selectedSegmentGroupTypes" v-model="selectedSegmentGroupType"></v-select> -->
                <v-radio-group v-model="selectedSegmentGroupCondition">
                    <v-radio v-for="item in radioOptions" :key="item.value" :label="item.label" :value="item.value" color="primary">
                        <v-chip outlined :color="item.color" class="ma-1" style="border-radius: 30px;">{{ item.value }}</v-chip>
                    </v-radio>
                </v-radio-group>
                <v-text-field type="number" v-model="selectedSegmentGroupRepeat" label="Max Repeats" min="1"></v-text-field>
                <v-card>
                    <v-card-title>
                        Group Elements
                    </v-card-title>
                    <v-card-text style="max-height: 400px; overflow-y: auto;">
                        <v-card flat v-for="(segment, index) in selectedSegmentGroupSegments" :key="index">
                            <v-card-title>
                                <v-spacer></v-spacer>
                                <v-btn icon>
                                    <v-icon color="success">add</v-icon>
                                </v-btn>
                                <v-btn icon>
                                    <v-icon color="primary">remove</v-icon>
                                </v-btn>
                            </v-card-title>
                            <v-select label="Type of Segment" v-model="selectedSegmentGroupSegments[index].seg" :items="filteredSegmentsList">
                                <template v-slot:prepend-item>
                                    <v-text-field v-model="searchSL" label="Search for Segment"></v-text-field>
                                </template>
                            </v-select>
                            <v-radio-group v-model="selectedSegmentGroupSegments[index].required">
                                <v-radio v-for="item in radioOptions" :key="item.value" :label="item.label" :value="item.value" color="primary">
                                    <v-chip outlined :color="item.color" class="ma-1" style="border-radius: 30px;">{{ item.value }}</v-chip>
                                </v-radio>
                            </v-radio-group>
                            <v-text-field type="number" v-model="selectedSegmentGroupSegments[index].maxRepeat" label="Max Repeats" min="1"></v-text-field>
                        </v-card>
                    </v-card-text>
                </v-card>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="confirmSegDelDialog" class="black-dialog" width="500px" persistent>
        <v-card width="auto">
            <v-toolbar>
                <v-toolbar-title>
                    Delete {{ labelText }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn @click="deleteSegment()" icon>
                    <v-icon color="success">check</v-icon>
                </v-btn>
                <v-btn @click="confirmSegDelDialog = false" icon>
                    <v-icon color="primary">close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                Confirm deletion of this segment?
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-menu v-model="configMenu" :position-x="posX" :position-y="posY">
        <v-list>
            <v-list-item @click="newSegmentDialog = true">
                <v-list-item-content>
                    <v-list-item-title>
                        <v-icon>add_box</v-icon>New Segment
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item @click="createSegmentGroupDialog = true">
                <v-list-item-content>
                    <v-list-item-title>
                        <v-icon>add_to_photos</v-icon>New Segment Group
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item @click="confirmSegDelDialog = true">
                <v-list-item-content>
                    <v-list-item-title>
                        <v-icon>delete</v-icon>Remove Segment/Segment Group
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>

    <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout" :color="snackbar.color">{{ snackbar.text
            }}</v-snackbar>
</div>
</template>

<script>
import FileUpload from "vue-upload-component";
import draggable from "vuedraggable";
export default {
    components: {
        FileUpload,
        draggable
    },
    data() {
        return {
            selectedMask: null,
            dateTimeOptions: [
                "YYMMDD",
                "HHmm",
                "YYMMDD0000"
            ],
            selectedElement: null,
            cdeIndex: null,
            dataElementIndex: 0,
            elementAddMenu: null,
            elementObj: {},
            elementRules: [
                value => {
                    if (value) return true

                    return 'Mandatory field.'
                },
            ],
            dialogAddDE: false,
            dialogAddCDE: false,
            intialPreview: false,
            searchVersion: "",
            selectedTab: 1,
            createOptions: [{
                    text: "New",
                    icon: "open_in_new"
                },
                {
                    text: "Open",
                    icon: "folder_open"
                },
                {
                    text: "Save",
                    icon: "save"
                },
                {
                    text: "Save As",
                    icon: "save_as"
                },
                {
                    text: "Make a Copy",
                    icon: "file_copy"
                },
                {
                    text: "Delete",
                    icon: "delete"
                },
            ],
            serviceOptions: [{
                text: "Create Service",
                icon: "upload"
            }, ],
            leftColumnItems: [{
                name: ""
            }, ],
            rightColumnItems: [{
                value: ""
            }, ],
            descriptionColumnItems: [{
                description: ""
            }, ],
            createTemplateDialog: false,
            buildTab: null,
            createBuildFileDialog: false,
            commenceBuildCreateDialog: false,
            commeceCopyCreationDialog: false,
            page: 1,
            total: 100,
            params: {
                limit: 15,
                offset: 0
            },
            jsonParams: {
                limit: 5,
                offset: 0
            },
            pageJSON: 1,
            totalJSONs: 100,
            searchJSON: "",
            msgParams: {
                limit: 5,
                offset: 0
            },
            pageMsg: 1,
            totalMsgs: 100,
            searchMsg: "",
            jsonStorageDialog: false,
            updateKey: 0,
            buildSearch: null,
            tab: null,
            saveTabs: 'incomplete',
            storageTabs: 'json',
            tabs: [],
            segments: [],
            templateOptions: null,
            chosenTemplateName: null,
            copyOptions: null,
            chosenJSONOption: null,
            chooseBuildDialog: false,
            loadJSONDialog: false,
            mapDialog: false,
            loading: false,
            segmentKey: 0,
            btnLoader: false,
            jsonOptions: null,
            msgFileOptions: [],
            jsonFileOptions: [],
            fileJSON: null,
            fileMSG: null,
            fileKeys: null,
            chosenChip: {},
            dataSearch: null,
            selectedItem: null,
            selectedData: {},
            rightClickMenuDialog: false,
            currentSegment: {},
            snackbar: {
                show: false,
                text: "",
                timeout: 5000,
                color: "error"
            },
            templateName: "",
            commenceCreationDialog: false,
            nameIncompleteDialog: false,
            templateHeaders: [{
                    text: "File Name",
                    value: "fileName",
                },
                {
                    text: "File Type",
                    value: "fileType",
                },
                {
                    text: "Last Modified",
                    value: "lastModified",
                },
                {
                    text: "Size",
                    value: "size",
                },
                {
                    text: "Source Type",
                    value: "source"
                }
            ],
            jsonHeaders: [{
                    text: "",
                    value: "actions",
                    sortable: false
                },
                {
                    text: "File Name",
                    value: "fileName",
                },
                {
                    text: "File Type",
                    value: "fileType",
                },
                {
                    text: "Last Modified",
                    value: "lastModified",
                },
                {
                    text: "Size",
                    value: "size",
                }
            ],
            deletionHeaders: [{
                    text: "Delete",
                    value: "delete",
                },
                {
                    text: "File Name",
                    value: "fileName",
                },
                {
                    text: "File Type",
                    value: "fileType",
                },
                {
                    text: "Last Modified",
                    value: "lastModified",
                },
                {
                    text: "Size",
                    value: "size",
                },
            ],
            unVersions: [],
            chosenUNVersion: null,
            unMessageTypes: [],
            chosenUNMessageType: null,
            newBuildName: null,
            confirmIncompleteTemplateDialog: false,
            incompleteResponse: "",
            incompleteTemplatesDialog: false,
            incompleteTemplates: [],
            chosenIncomplete: null,
            incompleteSearch: "",
            chosenIncompleteName: "",
            incompletePage: 1,
            incompleteTotal: 100,
            incompleteParams: {
                limit: 15,
                offset: 0
            },
            searchPrevious: "",
            pageBuild: 1,
            totalBuild: 10,
            paramsBuild: {
                limit: 15,
                offset: 0
            },
            jsonObj: "",
            modifyBuildDialog: false,
            menuVisible: false,
            serviceMenu: false,
            fileList: [],
            msgList: [],
            removeMessageTemplateDialog: false,
            warningText: "",
            selectedItemToMap: null,
            selectedKVPMatches: [],
            creatingBuild: false,
            keyValuePairDialog: false,
            setStaticDialog: false,
            staticValue: "",
            previewDialog: false,
            previewMsg: "",
            fileData: null,
            refreshKey: 0,
            uploadedFiles: [],
            currentJSON: "",
            currentMsg: "",
            selectedJSON: null,
            editingJSON: {},
            editedJSON: "",
            jsonEditDialog: false,
            selectedMsg: null,
            selectedFileProperties: null,
            referenceDialog: false,
            selectedReferenceTab: 'segments',
            staticArrayDialog: false,
            showElementIDAndName: true,
            showElementIDOnly: false,
            showElementNameOnly: true,
            commentsLoading: false,
            comment: "",
            editSegmentDialog: false,
            deleteFileDialog: false,
            fileToDelete: {
                type: ""
            },
            maskDialog: false,
            endTag: "",
            dragOptions: {
                animation: 150,
            },
            innerDragOptions: {
                animation: 150, // You can customize this for the inner panels.
            },
            setCDEDialog: false,
            selectedDEIndex: null,
            targetDE: null,
            segmentsKey: 0,
            debug: false,
            newSegmentDialog: false,
            segmentsList: [],
            searchSL: "",
            configMenu: false,
            posY: null,
            posX: null,
            segmentIndex: null,
            selectedSegType: null,
            selectedSegPosition: null,
            positionOptions: [{
                    text: 'After',
                    value: 'after'
                },
                {
                    text: 'Before',
                    value: 'before'
                },
            ],
            selectedCondition: null,
            selectedRepeat: 1,
            radioOptions: [{
                    label: 'Conditional',
                    value: 'C',
                    color: 'index'
                },
                {
                    label: 'Mandatory',
                    value: 'M',
                    color: 'primary'
                },
            ],
            confirmSegDelDialog: false,
            createSegLoading: false,
            createSegmentGroupDialog: false,
            selectedSegmentGroupType: null,
            selectedSegmentGroupTypes: [{
                text: 'Has No Sub Groups',
                value: 'subgroupless'
            }, {
                text: 'Has Sub Groups',
                value: 'subgroups'
            }],
            selectedSegmentGroupRepeat: null,
            selectedSegmentGroupCondition: null,
            selectedSegmentGroupPosition: null,
            selectedSegmentGroupNumber: null,
            selectedSegmentGroupSegments: [{
                seg: "",
                required: "",
                requirement: "",
                position: "",
                repeat: "1",
                maxRepeat: 1,
                group: "",
                groups: [""],
                inUse: true,
            }]
        };
    },
    watch: {
        fileList: {
            immediate: true,
            handler(val) {
                if (val.length > 0) {
                    this.submitFiles();
                    // console.log("OOPS")
                }
            },
        },
        msgList: {
            immediate: true,
            handler(val) {
                if (val.length > 0) {
                    this.submitJSON()
                }
            },
        },
        page: {
            handler: async function () {
                console.log("PAGE NUMBER CHANGED")
                this.params.offset = (this.page - 1) * this.params.limit;
                await this.fetchFileTemplates(this.params)
                this.updateKey++
            },
            immediate: true
        },
        'params.limit': {
            handler: async function () {
                console.log("RECORD LIMIT CHANGED")
                this.params.offset = (this.page - 1) * this.params.limit;
                await this.fetchFileTemplates(this.params)
                this.updateKey++
            },
            immediate: true
        },
        buildSearch: {
            handler: function () {
                setTimeout(() => {
                    this.fetchFileTemplates()
                }, 2000);
            },
            immediate: true
        },
        chosenUNVersion: {
            handler: function () {
                this.fetchUNMessageTypes(this.chosenUNVersion)
            }
        },
        incompletePage: {
            handler: async function () {
                console.log("PAGE NUMBER CHANGED")
                this.incompleteParams.offset = (this.incompletePage - 1) * this.incompleteParams.limit;
                await this.fetchIncompletes(this.params)
                this.updateKey++
            },
            immediate: true
        },
        'incompleteParams.limit': {
            handler: async function () {
                console.log("RECORD LIMIT CHANGED")
                this.incompleteParams.offset = (this.incompletePage - 1) * this.incompleteParams.limit;
                await this.fetchIncompletes(this.incompleteParams)
                this.updateKey++
            },
            immediate: true
        },
        incompleteSearch: {
            handler: function () {
                setTimeout(() => {
                    // this.fetchIncompletes()
                }, 2000);
            },
            immediate: true
        },
        pageJSON: {
            handler: async function () {
                console.log("PAGE NUMBER CHANGED")
                this.jsonParams.offset = (this.pageJSON - 1) * this.jsonParams.limit;
                this.fetchJSONsFromStorage()
                this.refreshKey++
            },
            immediate: true
        },
        'jsonParams.limit': {
            handler: async function () {
                console.log("RECORD LIMIT CHANGED")
                this.jsonParams.offset = (this.pageJSON - 1) * this.jsonParams.limit;
                this.fetchJSONsFromStorage()
                this.refreshKey++
            },
            immediate: true
        },
        searchJSON: {
            handler: function () {
                setTimeout(() => {
                    this.fetchJSONsFromStorage()
                }, 2000);
            },
            immediate: true
        },
        pageMsg: {
            handler: async function () {
                console.log("PAGE NUMBER CHANGED")
                this.msgParams.offset = (this.pageMsg - 1) * this.msgParams.limit;
                this.fetchMessagesFromStorage()
                this.refreshKey++
            },
            immediate: true
        },
        'msgParams.limit': {
            handler: async function () {
                console.log("RECORD LIMIT CHANGED")
                this.msgParams.offset = (this.pageMsg - 1) * this.msgParams.limit;
                this.fetchMessagesFromStorage()
                this.refreshKey++
            },
            immediate: true
        },
        searchMsg: {
            handler: function () {
                setTimeout(() => {
                    this.fetchMessagesFromStorage()
                }, 2000);
            },
            immediate: true
        },
        pageBuild: {
            handler: async function () {
                console.log("PAGE NUMBER CHANGED")
                this.paramsBuild.offset = (this.pageBuild - 1) * this.paramsBuild.limit;
                await this.fetchFileTemplates(this.paramsBuild)
                this.updateKey++
            },
            immediate: true
        },
        'paramsBuild.limit': {
            handler: async function () {
                console.log("RECORD LIMIT CHANGED")
                this.paramsBuild.offset = (this.pageBuild - 1) * this.paramsBuild.limit;
                await this.fetchFileTemplates(this.paramsBuild)
                this.updateKey++
            },
            immediate: true
        },
        searchPrevious: {
            handler: function () {
                setTimeout(() => {
                    this.fetchFileTemplates()
                }, 2000);
            },
            immediate: true
        },
    },
    async created() {
        await this.fetchFileTemplates()
        await this.fetchIncompletes()
        await this.fetchJSONOptions()
        await this.fetchUNVersions()
        await this.fetchJSONsFromStorage()
        await this.fetchSegmentsList()
    },
    computed: {
        isCreateButtonDisabled() {
            return (
                this.selectedSegPosition === null ||
                this.selectedSegType === null ||
                this.selectedCondition === null ||
                this.selectedRepeat === null
            );
        },
        labelText() {
            if (this.segments && this.segments[this.segmentIndex]) {
                return `${this.segments[this.segmentIndex].name}`;
            }
            return "NULL";
        },
        filteredSegmentsList() {
            if (this.searchSL.length > 0) {
                return this.segmentsList.filter((segment) => {
                    return segment.toLowerCase().includes(this.searchSL.toLowerCase());
                });
            } else {
                return this.segmentsList;
            }
        },
        isAssigned() {
            return (value) => {
                // Check if an object with the given static value exists in the array
                return this.selectedKVPMatches.some((item) => item.staticValue === value);
            };
        },
        isButtonDisabled() {
            return (
                this.leftColumnItems.some(item => item.name === null || item.name === "") ||
                this.rightColumnItems.some(item => item.value === null || item.value === "")
            );
        },
        cdeButtonDisabled() {
            return (componentDataElements) => componentDataElements && componentDataElements.length > 0;
        },
        filteredDataElements() {
            // Filter out objects that are strictly equal to computedCurrentSeg.DataElements[selectedDEIndex]
            if (this.selectedDEIndex === null) {
                return this.computedCurrentSeg.DataElements.filter(item => item !== this.computedCurrentSeg.DataElements[this.selectedDEIndex]);
            } else {
                return this.computedCurrentSeg.DataElements
            }
        },
        filteredMessageTypes() {
            if (!this.searchVersion) {
                return this.unMessageTypes;
            }
            const searchTerm = this.searchVersion.toLowerCase();
            return this.unMessageTypes.filter((type) =>
                type.toLowerCase().includes(searchTerm)
            );
        },
        combinedNameAndDescription() {
            // Assuming each item in computedCurrentSeg.DataElements has name and description properties
            return (item) => `${item.name} [${item.elementId}]`;
        },
        filteredDataItems() {
            let fileData = this.fileData;
            let filteredItems = this.tab || this.tab === 0 ?
                this.fileJSON[this.tab].keys :
                this.dataItems;

            if (filteredItems) {
                if (this.dataSearch) {
                    const searchValue = this.dataSearch.toLowerCase();
                    filteredItems = filteredItems.filter(item => item.toLowerCase().includes(searchValue));
                }

                // Create an array to store the results
                let results = [];

                // Iterate through filteredItems
                for (const path of filteredItems) {
                    // Split the path into its components
                    const pathComponents = path.split('.');

                    // Traverse the fileData object to get the value
                    let value = fileData;
                    for (const component of pathComponents) {
                        if (Object.prototype.hasOwnProperty.call(value, component)) {
                            value = value[component];
                        } else {
                            value = "NULL"; // Handle cases where the path is invalid
                            break;
                        }
                    }

                    // Add the path and value to the results array
                    results.push({
                        path,
                        value
                    });
                }

                return results;
            } else {
                return [];
            }
        },
        computedCurrentSeg() {
            let result = []
            result = this.currentSegment;
            return result
        },
        computedSegments() {
            this.segmentsKey;
            let result = [];
            this.updateNumberOfSegments();
            result = this.segments;
            return result;
        },
        computedBuild() {
            let result = []
            result = this.jsonObj.SegmentTable;
            return result
        },
        formattedPreviewMsg() {
            // const jsonString = JSON.stringify(this.previewMsg, null, 2);
            // const lines = jsonString.split(`'`); // Split the string by newline
            const lines = this.previewMsg.split(`'`)
            return lines
        },
        selectedRow() {
            return this.jsonFileOptions.find(
                (item) => item.fileName === this.selectedItem
            );
        },
        selectedMessageFile() {
            return this.msgFileOptions.find(
                (item) => item.fileName === this.selectedItem
            );
        }
    },
    methods: {
        async deleteSegment() {
            this.segments.splice(this.segmentIndex, 1);
            this.segmentsKey++
            this.confirmSegDelDialog = false;
        },
        async createNewSegmentGroup() {

        },
        async createNewSegment() {
            this.createSegLoading = true
            let segment = await this.$API.getChosenSegment({
                segment: this.selectedSegType
            })

            segment.position = "0000"
            segment.required = this.selectedCondition;
            segment.requirement = this.selectedCondition;
            segment.maxRepeat = this.selectedRepeat;
            segment.repeat = JSON.stringify(this.selectedRepeat);

            console.log(this.selectedSegPosition)
            if (this.selectedSegPosition === "before") {
                this.segments.splice(this.segmentIndex, 0, segment); // Insert "segment" before the element at "segmentIndex"
            } else if (this.selectedSegPosition === "after") {
                this.segments.splice(this.segmentIndex + 1, 0, segment); // Insert "segment" after the element at "segmentIndex"
            }

            this.resetSegmentOrder()
            this.segmentsKey++
            this.createSegLoading = false
            this.newSegmentDialog = false
        },
        showConfigMenu(event, index) {
            event.preventDefault();
            this.posX = event.clientX;
            this.posY = event.clientY;
            this.segmentIndex = index;
            console.log({
                segmentIndex: index
            })
            this.configMenu = true;
        },
        setAsDataElement(outerIndex, innerIndex) {
            const movedItem = this.computedCurrentSeg.DataElements[outerIndex].componentDataElements[innerIndex]
            this.computedCurrentSeg.DataElements.push(movedItem.element);
            this.computedCurrentSeg.DataElements[outerIndex].componentDataElements.splice(innerIndex, 1)
            if (this.computedCurrentSeg.DataElements[outerIndex].componentDataElements.length === 0) {
                delete this.computedCurrentSeg.DataElements[outerIndex].componentDataElements
            }
        },
        setAsComponentDE() {
            const selectedDE = this.computedCurrentSeg.DataElements[this.selectedDEIndex];
            const index = this.computedCurrentSeg.DataElements.findIndex(de => de.name === this.targetDE.replace(/\[.*?\]/g, '').trim());

            if (index !== -1) {
                const targetDE = this.computedCurrentSeg.DataElements[index];

                if (!targetDE.componentDataElements) {
                    targetDE.componentDataElements = [];
                    targetDE.componentDataElements.push({
                        order: "010",
                        element: selectedDE
                    });
                } else {
                    const lastOrder = targetDE.componentDataElements[targetDE.componentDataElements.length - 1].order;
                    const order = `0${parseInt(lastOrder) + 10}`;
                    targetDE.componentDataElements.push({
                        order,
                        element: selectedDE
                    });
                }

                this.computedCurrentSeg.DataElements.splice(this.selectedDEIndex, 1);
            }
            console.log({targetDE: this.targetDE, index, selectedDE, computedCurrentSeg: this.computedCurrentSeg})
            this.setCDEDialog = false
        },
        resetOrder(index) {
            for (let i = 0; i < this.computedCurrentSeg.DataElements[index].componentDataElements.length; i++) {
                let orderCheck = JSON.stringify((i + 1) * 10);
                let newOrder;
                if (orderCheck.length === 3 || orderCheck.length === 2) {
                    newOrder = orderCheck
                } else {
                    newOrder = `0${orderCheck}`
                }
                this.computedCurrentSeg.DataElements[index].componentDataElements[i].order = newOrder
            }
        },
        resetSegmentOrder() {
            for (let i = 0; i < this.segments.length; i++) {
                let numericPosition = (1 + 5) * 5;
                let stringPosition = JSON.stringify(numericPosition);
                if (Array.isArray(this.segments[i])) {
                    if (stringPosition.length === 1) {
                        this.segments[i][0].position = `000${stringPosition}`
                    } else if (stringPosition.length === 2) {
                        this.segments[i][0].position = `00${stringPosition}`
                    } else if (stringPosition.length === 3) {
                        this.segments[i][0].position = `0${stringPosition}`
                    } else if (stringPosition.length === 4) {
                        this.segments[i][0].position = `${stringPosition}`
                    }
                } else {
                    if (stringPosition.length === 1) {
                        this.segments[i].position = `000${stringPosition}`
                    } else if (stringPosition.length === 2) {
                        this.segments[i].position = `00${stringPosition}`
                    } else if (stringPosition.length === 3) {
                        this.segments[i].position = `0${stringPosition}`
                    } else if (stringPosition.length === 4) {
                        this.segments[i].position = `${stringPosition}`
                    }
                }
            }

            console.log(this.segments)
        },
        async setEndTag() {
            this.commentsLoading = true
            let segment = this.currentSegment;
            for (let i = 0; i < this.segments.length; i++) {
                if (segment === this.segments[i]) {
                    if (!Array.isArray(segment)) {
                        if (segment.groupElements && segment.subGroups) {
                            console.log({
                                SubGroups: segment.subGroups
                            })
                            for (let a = 0; a < segment.subGroups.length; a++) {
                                let subGroup = segment.subGroups[a];

                                if (subGroup[0].subGroups) {
                                    console.log("SUB GROUPS HAS SUB GROUPS")
                                    for (let v = 0; v < subGroup[0].subGroups.length; v++) {
                                        let sub = subGroup[0].subGroups[v]
                                        console.log({
                                            sub
                                        })
                                        for (let s = 0; s < sub.length; s++) {
                                            let subItem = sub[s];

                                            if (subItem.subGroups) {
                                                for (let lastGroup of subItem.subGroups) {
                                                    let lastGroupIndex = this.segments[i].subGroups[a][0].subGroups[v][s].subGroups.indexOf(lastGroup)
                                                    // console.log({
                                                    //     lastGroup,
                                                    //     lastGroupIndex
                                                    // })
                                                    for (let lastSegment of lastGroup.slice(1)) {
                                                        let lastSegmentIndex = this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex].indexOf(lastSegment);
                                                        // console.log({lastSegment, lastSegmentIndex})
                                                        if (lastSegment.DataElements) {
                                                            console.log("SUB HAS DATA ELEMENTS++++++++")
                                                            for (let r = 0; r < lastSegment.DataElements.length; r++) {
                                                                let dataElement = lastSegment.DataElements[r]
                                                                if (dataElement === this.chosenChip) {
                                                                    console.log("TESTER++++++", this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex])
                                                                    this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].endTag = this.endTag
                                                                    this.endTag = null;
                                                                    this.commentsLoading = false
                                                                    return;
                                                                } else if (dataElement.componentDataElements) {
                                                                    console.log("CHECKING COMPONENT DATA ELEMENTS+++++++")
                                                                    for (let g = 0; g < dataElement.componentDataElements.length; g++) {
                                                                        let componentDataElement = dataElement.componentDataElements[g];
                                                                        if (componentDataElement.element === this.chosenChip) {
                                                                            console.log("COMPONENT EQUAL TO CHOSEN CHIP++++++")
                                                                            this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].componentDataElements[g].element.endTag = this.endTag
                                                                            this.endTag = null;
                                                                            this.commentsLoading = false
                                                                            return;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            if (subItem.DataElements) {
                                                console.log("SUB HAS DATA ELEMENTS")
                                                for (let r = 0; r < subItem.DataElements.length; r++) {
                                                    let dataElement = subItem.DataElements[r]
                                                    if (dataElement === this.chosenChip) {
                                                        console.log("TESTER", this.segments[i].subGroups[a][0].subGroups[v][s])
                                                        this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].endTag = this.endTag

                                                        this.endTag = null;
                                                        this.commentsLoading = false
                                                        return;
                                                    } else if (dataElement.componentDataElements) {
                                                        console.log("CHECKING COMPONENT DATA ELEMENTS")
                                                        for (let g = 0; g < dataElement.componentDataElements.length; g++) {
                                                            let componentDataElement = dataElement.componentDataElements[g];
                                                            if (componentDataElement.element === this.chosenChip) {
                                                                console.log("COMPONENT EQUAL TO CHOSEN CHIP")
                                                                this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].componentDataElements[g].element.endTag = this.endTag

                                                                this.endTag = null;
                                                                this.commentsLoading = false
                                                                return;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                for (let index in subGroup) {
                                    let integerIndex = parseInt(index)
                                    let subSegment = subGroup[integerIndex]
                                    // console.log({
                                    //     integerIndex
                                    // })
                                    for (let x = 0; x < subSegment.DataElements.length; x++) {
                                        let DataElement = subSegment.DataElements[x];
                                        // console.log({
                                        //     integerIndex,
                                        //     DataElement,
                                        //     chosenChip: this.chosenChip
                                        // })
                                        if (DataElement === this.chosenChip) {
                                            // this.segments[i][integerIndex].DataElements[x].mappedValue = item
                                            this.segments[i].subGroups[a][integerIndex].DataElements[x].endTag = this.endTag

                                            this.endTag = null;
                                            this.commentsLoading = false
                                            return
                                        } else if (DataElement.componentDataElements) {
                                            for (let z = 0; z < DataElement.componentDataElements.length; z++) {
                                                let componentDataElement = DataElement.componentDataElements[z];
                                                if (componentDataElement.element === this.chosenChip) {
                                                    this.segments[i].subGroups[a][integerIndex].DataElements[x].componentDataElements[z].element.endTag = this.endTag

                                                    this.endTag = null;
                                                    this.commentsLoading = false
                                                    return
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            for (let o = 0; o < segment.groupElements.length; o++) {
                                let groupElement = segment.groupElements[o];
                                for (let p = 0; p < groupElement.DataElements.length; p++) {
                                    let DataElement = groupElement.DataElements[p];
                                    if (DataElement === this.chosenChip) {
                                        this.segments[i].groupElements[o].DataElements[p].endTag = this.endTag

                                        this.endTag = null;
                                        this.commentsLoading = false
                                        return;
                                    } else if (DataElement.componentDataElements) {
                                        for (let w = 0; w < DataElement.componentDataElements.length; w++) {
                                            let componentDataElement = DataElement.componentDataElements[w];
                                            if (componentDataElement.element === this.chosenChip) {
                                                this.segments[i].groupElements[o].DataElements[p].componentDataElements[w].element.endTag = this.endTag

                                                this.endTag = null;
                                                this.commentsLoading = false
                                                return;
                                            }
                                        }
                                    }
                                }
                            }

                        } else {
                            for (let j = 0; j < segment.DataElements.length; j++) {
                                let DataElement = segment.DataElements[j];
                                if (DataElement === this.chosenChip) {
                                    this.segments[i].DataElements[j].endTag = this.endTag
                                    this.endTag = null;
                                    this.commentsLoading = false
                                    return
                                } else if (DataElement.componentDataElements) {
                                    for (let k = 0; k < DataElement.componentDataElements.length; k++) {
                                        let componentDataElement = DataElement.componentDataElements[k];
                                        if (componentDataElement.element === this.chosenChip) {
                                            console.log({
                                                chosenChip: this.chosenChip,
                                                componentDataElement: componentDataElement.element
                                            })
                                            this.segments[i].DataElements[j].componentDataElements[k].element.endTag = this.endTag
                                            this.endTag = null;
                                            this.commentsLoading = false
                                            return
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        for (let y = 1; y < this.segments[i].length; y++) {
                            let subSegment = this.segments[i][y];
                            for (let x = 0; x < subSegment.DataElements.length; x++) {
                                let DataElement = subSegment.DataElements[x];
                                if (DataElement === this.chosenChip) {
                                    this.segments[i][y].DataElements[x].endTag = this.endTag
                                    return
                                } else if (DataElement.componentDataElements) {
                                    for (let z = 0; z < DataElement.componentDataElements.length; z++) {
                                        let componentDataElement = DataElement.componentDataElements[z];
                                        if (componentDataElement.element === this.chosenChip) {
                                            this.segments[i][y].DataElements[x].componentDataElements[z].element.endTag = this.endTag
                                            this.endTag = null;
                                            this.commentsLoading = false
                                            return
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        async subGroupCountHandler(subGroups) {
            let amountOfSegments = 0;

            for (let subGroup of subGroups) {
                for (let j = 0; j < subGroup.length; j++) {
                    if (subGroup[j].seg && subGroup[j].inUse === true) {
                        amountOfSegments++;
                    }
                }
                if (subGroup[0].subGroups && subGroup[0].subGroups.length > 0) {
                    amountOfSegments += await this.subGroupCountHandler(subGroup[0].subGroups);
                }
            }

            return amountOfSegments;
        },
        async updateNumberOfSegments() {
            if (this.segments.length > 0) {
                let amountOfSegments = 0;

                for (let segment of this.segments) {
                    if (!Array.isArray(segment) && !Object.prototype.hasOwnProperty.call(segment, "subGroups")) {
                        if (segment.inUse === true) {
                            amountOfSegments++;
                        }
                    } else if (Array.isArray(segment)) {
                        for (let i = 0; i < segment.length; i++) {
                            if (segment[i].seg && segment[i].inUse === true) {
                                amountOfSegments++;
                            }
                        }
                    } else if (!Array.isArray(segment) && Object.prototype.hasOwnProperty.call(segment, "subGroups")) {
                        for (let groupElement of segment.groupElements) {
                            if (groupElement.inUse === true) {
                                amountOfSegments++;
                            }
                        }

                        if (segment.subGroups.length > 0) {
                            amountOfSegments += await this.subGroupCountHandler(segment.subGroups);
                        }
                    }
                }

                for (let n = 0; n < this.segments.length; n++) {
                    if (this.segments[n].seg === "UNT") {
                        for (let m = 0; m < this.segments[n].DataElements.length; m++) {
                            if (this.segments[n].DataElements[m].name === "Number of segments in a message") {
                                this.segments[n].DataElements[m].mappedValue = amountOfSegments - 2;
                            }
                        }
                    }
                }
            }
        },
        async setMask() {
            let segment = this.currentSegment;
            this.mapDialog = false
            this.maskDialog = false
            // Attempt to delete the 'mask' property
            delete this.chosenChip.mask;

            // Check if the 'mask' property exists
            if ('mask' in this.chosenChip) {
                console.log('The "mask" property still exists in chosenChip:', this.chosenChip.mask);
            } else {
                console.log('The "mask" property has been successfully deleted from chosenChip.');
                console.log(this.chosenChip)
            }

            for (let i = 0; i < this.segments.length; i++) {
                if (segment === this.segments[i]) {
                    if (!Array.isArray(segment)) {
                        if (segment.groupElements && segment.subGroups) {
                            console.log({
                                SubGroups: segment.subGroups
                            })
                            for (let a = 0; a < segment.subGroups.length; a++) {
                                let subGroup = segment.subGroups[a];

                                if (subGroup[0].subGroups) {
                                    console.log("SUB GROUPS HAS SUB GROUPS")
                                    for (let v = 0; v < subGroup[0].subGroups.length; v++) {
                                        let sub = subGroup[0].subGroups[v]
                                        console.log({
                                            sub
                                        })
                                        for (let s = 0; s < sub.length; s++) {
                                            let subItem = sub[s];

                                            if (subItem.subGroups) {
                                                for (let lastGroup of subItem.subGroups) {
                                                    let lastGroupIndex = this.segments[i].subGroups[a][0].subGroups[v][s].subGroups.indexOf(lastGroup)
                                                    // console.log({
                                                    //     lastGroup,
                                                    //     lastGroupIndex
                                                    // })
                                                    for (let lastSegment of lastGroup.slice(1)) {
                                                        let lastSegmentIndex = this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex].indexOf(lastSegment);
                                                        // console.log({lastSegment, lastSegmentIndex})
                                                        if (lastSegment.DataElements) {
                                                            console.log("SUB HAS DATA ELEMENTS")
                                                            for (let r = 0; r < lastSegment.DataElements.length; r++) {
                                                                let dataElement = lastSegment.DataElements[r]
                                                                if (dataElement === this.chosenChip) {
                                                                    console.log("TESTER", this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex])
                                                                    this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].mask = this.selectedMask
                                                                    await this.fetchPreviewMessage();
                                                                    return;
                                                                } else if (dataElement.componentDataElements) {
                                                                    console.log("CHECKING COMPONENT DATA ELEMENTS")
                                                                    for (let g = 0; g < dataElement.componentDataElements.length; g++) {
                                                                        let componentDataElement = dataElement.componentDataElements[g];
                                                                        if (componentDataElement.element === this.chosenChip) {
                                                                            console.log("COMPONENT EQUAL TO CHOSEN CHIP")
                                                                            this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].componentDataElements[g].element.mask = this.selectedMask
                                                                            await this.fetchPreviewMessage();
                                                                            return;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            if (subItem.DataElements) {
                                                console.log("SUB HAS DATA ELEMENTS")
                                                for (let r = 0; r < subItem.DataElements.length; r++) {
                                                    let dataElement = subItem.DataElements[r]
                                                    if (dataElement === this.chosenChip) {
                                                        console.log("TESTER", this.segments[i].subGroups[a][0].subGroups[v][s])
                                                        this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].mask = this.selectedMask
                                                        await this.fetchPreviewMessage();
                                                        return;
                                                    } else if (dataElement.componentDataElements) {
                                                        console.log("CHECKING COMPONENT DATA ELEMENTS")
                                                        for (let g = 0; g < dataElement.componentDataElements.length; g++) {
                                                            let componentDataElement = dataElement.componentDataElements[g];
                                                            if (componentDataElement.element === this.chosenChip) {
                                                                console.log("COMPONENT EQUAL TO CHOSEN CHIP")
                                                                this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].componentDataElements[g].element.mask = this.selectedMask
                                                                await this.fetchPreviewMessage();
                                                                return;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                for (let index in subGroup) {
                                    let integerIndex = parseInt(index)
                                    let subSegment = subGroup[integerIndex]
                                    // console.log({
                                    //     integerIndex
                                    // })
                                    for (let x = 0; x < subSegment.DataElements.length; x++) {
                                        let DataElement = subSegment.DataElements[x];
                                        // console.log({
                                        //     integerIndex,
                                        //     DataElement,
                                        //     chosenChip: this.chosenChip
                                        // })
                                        if (DataElement === this.chosenChip) {
                                            // this.segments[i][integerIndex].DataElements[x].mappedValue = item
                                            this.segments[i].subGroups[a][integerIndex].DataElements[x].mask = this.selectedMask
                                            await this.fetchPreviewMessage();
                                            return
                                        } else if (DataElement.componentDataElements) {
                                            for (let z = 0; z < DataElement.componentDataElements.length; z++) {
                                                let componentDataElement = DataElement.componentDataElements[z];
                                                if (componentDataElement.element === this.chosenChip) {
                                                    this.segments[i].subGroups[a][integerIndex].DataElements[x].componentDataElements[z].element.mask = this.selectedMask
                                                    await this.fetchPreviewMessage();
                                                    return
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            for (let o = 0; o < segment.groupElements.length; o++) {
                                let groupElement = segment.groupElements[o];
                                for (let p = 0; p < groupElement.DataElements.length; p++) {
                                    let DataElement = groupElement.DataElements[p];
                                    if (DataElement === this.chosenChip) {
                                        this.segments[i].groupElements[o].DataElements[p].mask = this.selectedMask
                                        await this.fetchPreviewMessage();
                                        return;
                                    } else if (DataElement.componentDataElements) {
                                        for (let w = 0; w < DataElement.componentDataElements.length; w++) {
                                            let componentDataElement = DataElement.componentDataElements[w];
                                            if (componentDataElement.element === this.chosenChip) {
                                                this.segments[i].groupElements[o].DataElements[p].componentDataElements[w].element.mask = this.selectedMask
                                                await this.fetchPreviewMessage();
                                                return;
                                            }
                                        }
                                    }
                                }
                            }

                        } else {
                            for (let j = 0; j < segment.DataElements.length; j++) {
                                let DataElement = segment.DataElements[j];
                                if (DataElement === this.chosenChip) {
                                    console.log({
                                        chosenChip: this.chosenChip
                                    })
                                    this.segments[i].DataElements[j].mask = this.selectedMask
                                    await this.fetchPreviewMessage();
                                    return
                                } else if (DataElement.componentDataElements) {
                                    for (let k = 0; k < DataElement.componentDataElements.length; k++) {
                                        let componentDataElement = DataElement.componentDataElements[k];
                                        if (componentDataElement.element === this.chosenChip) {
                                            this.segments[i].DataElements[j].componentDataElements[k].element.mask = this.selectedMask
                                            await this.fetchPreviewMessage();
                                            return
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        for (let y = 1; y < this.segments[i].length; y++) {
                            let subSegment = this.segments[i][y];
                            for (let x = 0; x < subSegment.DataElements.length; x++) {
                                let DataElement = subSegment.DataElements[x];
                                if (DataElement === this.chosenChip) {
                                    this.segments[i][y].DataElements[x].mask = this.selectedMask
                                    await this.fetchPreviewMessage();
                                    return
                                } else if (DataElement.componentDataElements) {
                                    for (let z = 0; z < DataElement.componentDataElements.length; z++) {
                                        let componentDataElement = DataElement.componentDataElements[z];
                                        if (componentDataElement.element === this.chosenChip) {
                                            this.segments[i][y].DataElements[x].componentDataElements[z].element.mask = this.selectedMask
                                            await this.fetchPreviewMessage();
                                            return
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        async removeMask() {
            let segment = this.currentSegment;
            this.mapDialog = false
            for (let i = 0; i < this.segments.length; i++) {
                if (segment === this.segments[i]) {
                    if (!Array.isArray(segment)) {
                        if (segment.groupElements && segment.subGroups) {
                            console.log({
                                SubGroups: segment.subGroups
                            })
                            for (let a = 0; a < segment.subGroups.length; a++) {
                                let subGroup = segment.subGroups[a];

                                if (subGroup[0].subGroups) {
                                    console.log("SUB GROUPS HAS SUB GROUPS")
                                    for (let v = 0; v < subGroup[0].subGroups.length; v++) {
                                        let sub = subGroup[0].subGroups[v]
                                        console.log({
                                            sub
                                        })
                                        for (let s = 0; s < sub.length; s++) {
                                            let subItem = sub[s];

                                            if (subItem.subGroups) {
                                                for (let lastGroup of subItem.subGroups) {
                                                    let lastGroupIndex = this.segments[i].subGroups[a][0].subGroups[v][s].subGroups.indexOf(lastGroup)
                                                    // console.log({
                                                    //     lastGroup,
                                                    //     lastGroupIndex
                                                    // })
                                                    for (let lastSegment of lastGroup.slice(1)) {
                                                        let lastSegmentIndex = this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex].indexOf(lastSegment);
                                                        // console.log({lastSegment, lastSegmentIndex})
                                                        if (lastSegment.DataElements) {
                                                            console.log("SUB HAS DATA ELEMENTS")
                                                            for (let r = 0; r < lastSegment.DataElements.length; r++) {
                                                                let dataElement = lastSegment.DataElements[r]
                                                                if (dataElement === this.chosenChip) {
                                                                    console.log("TESTER", this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex])
                                                                    this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].mask = null
                                                                    await this.fetchPreviewMessage();
                                                                    return;
                                                                } else if (dataElement.componentDataElements) {
                                                                    console.log("CHECKING COMPONENT DATA ELEMENTS")
                                                                    for (let g = 0; g < dataElement.componentDataElements.length; g++) {
                                                                        let componentDataElement = dataElement.componentDataElements[g];
                                                                        if (componentDataElement.element === this.chosenChip) {
                                                                            console.log("COMPONENT EQUAL TO CHOSEN CHIP")
                                                                            this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].componentDataElements[g].element.mask = null
                                                                            await this.fetchPreviewMessage();
                                                                            return;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            if (subItem.DataElements) {
                                                console.log("SUB HAS DATA ELEMENTS")
                                                for (let r = 0; r < subItem.DataElements.length; r++) {
                                                    let dataElement = subItem.DataElements[r]
                                                    if (dataElement === this.chosenChip) {
                                                        console.log("TESTER", this.segments[i].subGroups[a][0].subGroups[v][s])
                                                        this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].mask = null
                                                        await this.fetchPreviewMessage();
                                                        return;
                                                    } else if (dataElement.componentDataElements) {
                                                        console.log("CHECKING COMPONENT DATA ELEMENTS")
                                                        for (let g = 0; g < dataElement.componentDataElements.length; g++) {
                                                            let componentDataElement = dataElement.componentDataElements[g];
                                                            if (componentDataElement.element === this.chosenChip) {
                                                                console.log("COMPONENT EQUAL TO CHOSEN CHIP")
                                                                this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].componentDataElements[g].element.mask = null
                                                                await this.fetchPreviewMessage();
                                                                return;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                for (let index in subGroup) {
                                    let integerIndex = parseInt(index)
                                    let subSegment = subGroup[integerIndex]
                                    // console.log({
                                    //     integerIndex
                                    // })
                                    for (let x = 0; x < subSegment.DataElements.length; x++) {
                                        let DataElement = subSegment.DataElements[x];
                                        // console.log({
                                        //     integerIndex,
                                        //     DataElement,
                                        //     chosenChip: this.chosenChip
                                        // })
                                        if (DataElement === this.chosenChip) {
                                            // this.segments[i][integerIndex].DataElements[x].mappedValue = item
                                            this.segments[i].subGroups[a][integerIndex].DataElements[x].mask = null
                                            await this.fetchPreviewMessage();
                                            return
                                        } else if (DataElement.componentDataElements) {
                                            for (let z = 0; z < DataElement.componentDataElements.length; z++) {
                                                let componentDataElement = DataElement.componentDataElements[z];
                                                if (componentDataElement.element === this.chosenChip) {
                                                    this.segments[i].subGroups[a][integerIndex].DataElements[x].componentDataElements[z].element.mask = null
                                                    await this.fetchPreviewMessage();
                                                    return
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            for (let o = 0; o < segment.groupElements.length; o++) {
                                let groupElement = segment.groupElements[o];
                                for (let p = 0; p < groupElement.DataElements.length; p++) {
                                    let DataElement = groupElement.DataElements[p];
                                    if (DataElement === this.chosenChip) {
                                        this.segments[i].groupElements[o].DataElements[p].mask = null
                                        await this.fetchPreviewMessage();
                                        return;
                                    } else if (DataElement.componentDataElements) {
                                        for (let w = 0; w < DataElement.componentDataElements.length; w++) {
                                            let componentDataElement = DataElement.componentDataElements[w];
                                            if (componentDataElement.element === this.chosenChip) {
                                                this.segments[i].groupElements[o].DataElements[p].componentDataElements[w].element.mask = null
                                                await this.fetchPreviewMessage();
                                                return;
                                            }
                                        }
                                    }
                                }
                            }

                        } else {
                            for (let j = 0; j < segment.DataElements.length; j++) {
                                let DataElement = segment.DataElements[j];
                                if (DataElement === this.chosenChip) {
                                    this.segments[i].DataElements[j].mask = null
                                    await this.fetchPreviewMessage();
                                    return
                                } else if (DataElement.componentDataElements) {
                                    for (let k = 0; k < DataElement.componentDataElements.length; k++) {
                                        let componentDataElement = DataElement.componentDataElements[k];
                                        if (componentDataElement.element === this.chosenChip) {
                                            console.log(componentDataElement)
                                            this.segments[i].DataElements[j].componentDataElements[k].element.mask = null
                                            await this.fetchPreviewMessage();
                                            return
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        for (let y = 1; y < this.segments[i].length; y++) {
                            let subSegment = this.segments[i][y];
                            for (let x = 0; x < subSegment.DataElements.length; x++) {
                                let DataElement = subSegment.DataElements[x];
                                if (DataElement === this.chosenChip) {
                                    this.segments[i][y].DataElements[x].mask = null
                                    await this.fetchPreviewMessage();
                                    return
                                } else if (DataElement.componentDataElements) {
                                    for (let z = 0; z < DataElement.componentDataElements.length; z++) {
                                        let componentDataElement = DataElement.componentDataElements[z];
                                        if (componentDataElement.element === this.chosenChip) {
                                            this.segments[i][y].DataElements[x].componentDataElements[z].element.mask = null
                                            await this.fetchPreviewMessage();
                                            return
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        async getValueByPath(object, path) {
            const parts = path.split('.');
            let value = object;
            for (const part of parts) {
                if (Object.prototype.hasOwnProperty.call(value, part)) {
                    value = value[part];
                } else {
                    return undefined; // Path not found
                }
            }
            return value;
        },
        async deleteStorageFile() {
            let fileName = this.fileToDelete.fileName;
            let fileType = this.fileToDelete.deleteType;

            let result = await this.$API.removeStorageFile({
                fileName,
                fileType
            })
            if (result) {
                if (fileType === 'message') {
                    await this.fetchMessagesFromStorage();
                } else if (fileType === 'json') {
                    await this.fetchJSONsFromStorage();
                }
                this.deleteFileDialog = false
            }
        },
        addDataElement() {
            console.log(this.dataElementIndex)
            this.currentSegment.DataElements.splice(this.dataElementIndex + 1, 0, this.elementObj)
            this.elementObj = {}
            this.dialogAddDE = false;
            this.editSegmentDialog = true;
        },
        removeDataElement(index) {
            this.currentSegment.DataElements.splice(index, 1)
        },
        addComponentDE() {
            console.log({
                cdeIndex: this.cdeIndex
            });
            if (!this.currentSegment.DataElements[this.dataElementIndex].componentDataElements) {
                this.currentSegment.DataElements[this.dataElementIndex].componentDataElements = [];
            }

            if (this.cdeIndex === null || this.cdeIndex === this.currentSegment.DataElements[this.dataElementIndex].componentDataElements.length - 1) {
                let length = this.currentSegment.DataElements[this.dataElementIndex].componentDataElements.length;
                let lastOrder = this.currentSegment.DataElements[this.dataElementIndex].componentDataElements[length - 1].order;
                this.currentSegment.DataElements[this.dataElementIndex].componentDataElements.push({
                    order: "0" + JSON.stringify(parseInt(lastOrder) + 10),
                    element: this.elementObj,
                });
            } else if (this.cdeIndex === 0) {
                // Add the generated object to the front of the array
                this.currentSegment.DataElements[this.dataElementIndex].componentDataElements.unshift({
                    order: "010", // Assuming it should always start from 010
                    element: this.elementObj,
                });

                // Update the order property for the rest of the code.
                for (let i = 1; i < this.currentSegment.DataElements[this.dataElementIndex].componentDataElements.length; i++) {
                    let currentOrder = parseInt(this.currentSegment.DataElements[this.dataElementIndex].componentDataElements[i].order, 10);
                    currentOrder += 10;
                    this.currentSegment.DataElements[this.dataElementIndex].componentDataElements[i].order = currentOrder.toString();
                }
            } else {
                let replacementOrder = this.currentSegment.DataElements[this.dataElementIndex].componentDataElements[this.cdeIndex + 1].order;
                this.currentSegment.DataElements[this.dataElementIndex].componentDataElements.splice(this.cdeIndex + 1, 0, {
                    order: replacementOrder,
                    element: this.elementObj,
                });

                // Increment the order property for the rest of the code.
                for (let i = this.cdeIndex + 2; i < this.currentSegment.DataElements[this.dataElementIndex].componentDataElements.length; i++) {
                    let currentOrder = parseInt(this.currentSegment.DataElements[this.dataElementIndex].componentDataElements[i].order, 10);
                    currentOrder += 10;
                    this.currentSegment.DataElements[this.dataElementIndex].componentDataElements[i].order = currentOrder.toString();
                }
            }

            this.elementObj = {}
            this.dialogAddCDE = false;
            this.editSegmentDialog = true;
        },
        removeComponentDE(index, componentIndex) {
            this.currentSegment.DataElements[index].componentDataElements.splice(componentIndex, 1)
            if (this.currentSegment.DataElements[index].componentDataElements.length === 0) {
                delete this.currentSegment.DataElements[index].componentDataElements
            }
        },
        editSegment(segment) {
            this.currentSegment = segment
            this.editSegmentDialog = true
        },
        inUseColorPicker(segment) {
            const hasMappedValue = (element) =>
                element.mappedValue !== undefined && element.mappedValue !== null;

            const isElementInUse = (element) =>
                element.inUse === true;

            const hasComponentMappedValue = (component) =>
                component.element.mappedValue !== undefined && component.element.mappedValue !== null;

            const isComponentElementInUse = (component) =>
                component.element.inUse === true;

            if (segment.DataElements) {
                for (let DataElement of segment.DataElements) {
                    if (DataElement.componentDataElements) {
                        for (let componentDataElement of DataElement.componentDataElements) {
                            if (isComponentElementInUse(componentDataElement) && !hasComponentMappedValue(componentDataElement)) {
                                return 'warning';
                            }
                        }
                    } else {
                        if (isElementInUse(DataElement) && !hasMappedValue(DataElement)) {
                            return 'warning';
                        }
                    }
                }
            }

            return 'success';
        },
        inUseMandatoryColorPicker(segment) {
            const hasMappedValue = (element) =>
                element.mappedValue !== undefined && element.mappedValue !== null;

            const isElementInUse = (element) =>
                element.inUse === true;

            const hasComponentMappedValue = (component) =>
                component.element.mappedValue !== undefined && component.element.mappedValue !== null;

            const isComponentElementInUse = (component) =>
                component.element.inUse === true;

            if (segment.DataElements) {
                for (let DataElement of segment.DataElements) {
                    if (DataElement.componentDataElements) {
                        for (let componentDataElement of DataElement.componentDataElements) {
                            if (isComponentElementInUse(componentDataElement) && !hasComponentMappedValue(componentDataElement)) {
                                return 'primary';
                            }
                        }
                    } else {
                        if (isElementInUse(DataElement) && !hasMappedValue(DataElement)) {
                            return 'primary';
                        }
                    }
                }
            }

            return 'success';
        },
        anyFlags(segment) {
            if (segment.DataElements) {
                for (let DataElement of segment.DataElements) {
                    if (DataElement.componentDataElements) {
                        for (let componentDataElement of DataElement.componentDataElements) {
                            if (componentDataElement.element.flagged === true) {
                                return true;
                            }
                        }
                    } else {
                        if (DataElement.flagged === true) {
                            return true;
                        }
                    }
                }
            }

            return false;
        },
        anyFlagsArr(arr) {
            for (let section of arr) {
                let result = this.anyFlags(section);
                if (result === true) {
                    return true;
                }

            }
            return false
        },
        anyFlagsSubGrpArr(arrs) {
            for (let arr of arrs) {
                let result = this.anyFlagsArr(arr.slice(1));
                if (result === true) {
                    return true
                }
            }
            return false;
        },
        anyFlagsSubOfSubGrp(obj) {
            for (let subGroup of obj.subGroups) {
                let header = subGroup[0];
                let result = this.anyFlagsSubGrpArr(header.subGroups);
                if (result === true) {
                    return true
                }
            }
            return false
        },
        anyFlagsSubGrps(obj) {
            let groupElementsCheck = this.anyFlagsArr(obj.groupElements);
            if (groupElementsCheck === true) {
                return true
            }

            for (let subGroup of obj.subGroups) {
                let subGroupCheck = this.anyFlagsArr(subGroup.slice(1));
                if (subGroupCheck === true) {
                    return true;
                }
                // console.log({ROSS: subGroup})
                for (let lowestGrp of subGroup) {
                    if (lowestGrp.subGroups && lowestGrp.subGroups.length > 0) {
                        for (let bottomGroup of lowestGrp.subGroups) {
                            // console.log({
                            //     bottomGroup: bottomGroup[0]
                            // })

                            if (bottomGroup[0].subGroups) {
                                let result = this.anyFlagsSubGrpArr(bottomGroup[0].subGroups)
                                if (result === true) {
                                    return true;
                                }
                            }

                            let bottomGroupCheck = this.anyFlagsArr(bottomGroup.slice(1))
                            if (bottomGroupCheck === true) {
                                return true;
                            }
                        }
                    }
                }
            }

            return false;
        },
        async fetchSegmentsList() {
            this.segmentsList = await this.$API.fetchSegmentsList();
        },
        removeJSON() {
            this.fileList = [];
            this.fileJSON = null;
        },
        removeMSG() {
            this.msgList = [];
            this.fileMSG = null;
        },
        handleRowClick(item) {
            this.selectedJSON = item.fileName;
            this.fetchJSONData(item)
        },
        async beginEditing(item) {
            let response = await this.$API.fetchJSONDataInfo({
                fileName: item.fileName
            })
            this.editedJSON = item.fileName + ".json";
            console.log({fileName: item.fileName})
            this.editingJSON = response
            this.jsonEditDialog = true
        },
        async uploadEdited() {
            let response = await this.$API.uploadJSON({
                jsonData: this.editingJSON,
                jsonName: this.editedJSON
            })
            this.jsonEditDialog = false
            this.snackbar.show = true
            this.snackbar.color = "success";
            this.snackbar.text = `Edited JSON Successfully!`
        },
        handleMsgRowClick(item) {
            this.selectedMsg = item.fileName;
            this.fetchMsgData(item)
        },
        isSelected(item) {
            return this.selectedRow === item;
        },
        isMessageSelected(item) {
            return this.selectedMessageFile === item
        },
        async fetchJSONData(item) {
            // let selectState = (row.isSelected) ? false : true;
            // row.select(selectState);
            this.currentJSON = item.fileName
            let response = await this.$API.fetchJSONDataInfo({
                fileName: item.fileName
            })
            this.fileData = response
            const {
                groupedKeys,
                groupNames
            } = await this.$API.fetchJSONData({
                fileData: response
            });
            this.fileJSON = groupedKeys
            console.log(this.fileJSON)
            this.tabs = groupNames
            // console.log({
            //     JSON: this.fileJSON
            // })
            // console.log({
            //     fetchJSONDataInfo: response
            // })
            this.jsonStorageDialog = false;
        },
        async fetchMsgData(item) {
            // let selectState = (row.isSelected) ? false : true;
            // row.select(selectState);
            this.jsonStorageDialog = false;
            this.currentMsg = item.fileName
            let msgResponse = await this.$API.fetchMessageData({
                fileName: item.fileName
            })
            this.fileMSG = msgResponse
            this.previewMsg = msgResponse
            let response = await this.$API.decodeMessage({
                segments: this.segments,
                message: this.fileMSG
            })
            this.segments = response.segments
            console.log(this.segments)
        },
        async fetchJSONsFromStorage() {
            let params = {}
            params.limit = this.jsonParams.limit
            params.offset = this.jsonParams.offset
            if (this.searchJSON && this.searchJSON.length > 0) {
                params.search = this.searchJSON
            }
            let response = await this.$API.fetchJSONs(params);
            console.log({
                fetchedJSONs: response
            })
            this.jsonFileOptions = response.paginatedFileList;
            this.totalJSONs = response.total
        },
        async uploadJSONToStorage() {
            let response = await this.$API.uploadJSON({
                jsonData: this.fileData,
                jsonName: this.fileList[0].name
            })
            if (response) {
                this.fetchJSONsFromStorage()
                this.refreshKey++
            }
        },
        async fetchMessagesFromStorage() {
            let params = {}
            params.limit = this.msgParams.limit
            params.offset = this.msgParams.offset
            if (this.searchMsg && this.searchMsg.length > 0) {
                params.search = this.searchMsg
            }
            let response = await this.$API.fetchMessages(params);
            console.log({
                fetchedMsg: response
            })
            this.msgFileOptions = response.paginatedFileList;
            this.totalMsgs = response.total
        },
        async uploadMsgToStorage() {
            this.jsonStorageDialog = false
            this.loading = true
            console.log({
                fileName: this.msgList[0].name,
                message: this.fileMSG
            })
            let uploadMsg = await this.$API.uploadMsg({
                fileName: this.msgList[0].name,
                message: this.fileMSG
            })
            let response = await this.$API.decodeMessage({
                segments: this.segments,
                message: this.fileMSG
            })
            console.log(response)
            this.segments = response.segments
            await this.fetchJSONsFromStorage()
            this.loading = false
        },
        getFileIcon(file) {
            const extension = file.name.split('.').pop().toLowerCase();
            // Add more extensions and corresponding icons as needed
            const iconMap = {
                json: 'upload_file',
                txt: 'description'
                // Add more mappings here
            };
            return iconMap[extension] || 'upload_file';
        },
        async removeStatic() {
            delete this.chosenChip.static
            this.chosenChip.mappedValue = null
            this.mapDialog = false;
        },
        async fetchPreviewMessage() {
            let debugValue;
            if (this.debug === true) {
                debugValue = "on"
            } else {
                debugValue = "off"
            }
            // console.log(this.segments)
            this.previewMsg = await this.$API.previewEDIFACTMessage({
                template: this.segments,
                fileData: this.fileData,
                debug: debugValue
            });
        },
        async setKeyValuePairs() {
            let arr = []
            for (let i = 0; i < this.leftColumnItems.length; i++) {
                let keyValuePair = {
                    name: this.leftColumnItems[i].name,
                    value: this.rightColumnItems[i].value,
                    description: this.descriptionColumnItems[i].description
                }
                arr.push(keyValuePair)
            }
            this.keyValuePairs = arr
            this.leftColumnItems = [{
                    name: ""
                }, ],
                this.rightColumnItems = [{
                    value: ""
                }, ],
                this.descriptionColumnItems = [{
                    description: ""
                }, ],
                this.keyValuePairDialog = false

            this.mapDialog = true
            let segment = this.currentSegment;
            for (let i = 0; i < this.segments.length; i++) {
                if (segment === this.segments[i]) {
                    if (!Array.isArray(segment)) {
                        if (segment.groupElements && segment.subGroups) {
                            console.log({
                                SubGroups: segment.subGroups
                            })
                            for (let a = 0; a < segment.subGroups.length; a++) {
                                let subGroup = segment.subGroups[a];

                                if (subGroup[0].subGroups) {
                                    console.log("SUB GROUPS HAS SUB GROUPS")
                                    for (let v = 0; v < subGroup[0].subGroups.length; v++) {
                                        let sub = subGroup[0].subGroups[v]
                                        console.log({
                                            sub
                                        })
                                        for (let s = 0; s < sub.length; s++) {
                                            let subItem = sub[s];

                                            if (subItem.subGroups) {
                                                for (let lastGroup of subItem.subGroups) {
                                                    let lastGroupIndex = this.segments[i].subGroups[a][0].subGroups[v][s].subGroups.indexOf(lastGroup)
                                                    // console.log({
                                                    //     lastGroup,
                                                    //     lastGroupIndex
                                                    // })
                                                    for (let lastSegment of lastGroup.slice(1)) {
                                                        let lastSegmentIndex = this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex].indexOf(lastSegment);
                                                        // console.log({lastSegment, lastSegmentIndex})
                                                        if (lastSegment.DataElements) {
                                                            console.log("SUB HAS DATA ELEMENTS")
                                                            for (let r = 0; r < lastSegment.DataElements.length; r++) {
                                                                let dataElement = lastSegment.DataElements[r]
                                                                if (dataElement === this.chosenChip) {
                                                                    console.log("TESTER", this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex])
                                                                    if (Array.isArray(this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].static)) {
                                                                        for (let keyValuePair of this.keyValuePairs) {
                                                                            this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].static.push(keyValuePair)
                                                                        }
                                                                        return;
                                                                    } else {
                                                                        this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].static = []
                                                                        for (let keyValuePair of this.keyValuePairs) {
                                                                            this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].static.push(keyValuePair)
                                                                        }
                                                                        return;
                                                                    }
                                                                } else if (dataElement.componentDataElements) {
                                                                    console.log("CHECKING COMPONENT DATA ELEMENTS")
                                                                    for (let g = 0; g < dataElement.componentDataElements.length; g++) {
                                                                        let componentDataElement = dataElement.componentDataElements[g];
                                                                        if (componentDataElement.element === this.chosenChip) {
                                                                            console.log("COMPONENT EQUAL TO CHOSEN CHIP")
                                                                            if (Array.isArray(componentDataElement.element)) {
                                                                                for (let keyValuePair of this.keyValuePairs) {
                                                                                    this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].componentDataElements[g].element.static.push(keyValuePair)
                                                                                }
                                                                            } else {
                                                                                this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].componentDataElements[g].element.static = []
                                                                                for (let keyValuePair of this.keyValuePairs) {
                                                                                    this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].componentDataElements[g].element.static.push(keyValuePair)
                                                                                }
                                                                            }
                                                                            return;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            if (subItem.DataElements) {
                                                console.log("SUB HAS DATA ELEMENTS")
                                                for (let r = 0; r < subItem.DataElements.length; r++) {
                                                    let dataElement = subItem.DataElements[r]
                                                    if (dataElement === this.chosenChip) {
                                                        console.log("TESTER", this.segments[i].subGroups[a][0].subGroups[v][s])
                                                        if (Array.isArray(dataElement.static)) {
                                                            for (let keyValuePair of this.keyValuePairs) {
                                                                this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].static.push(keyValuePair)
                                                            }
                                                            return;
                                                        } else {
                                                            this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].static = [];
                                                            for (let keyValuePair of this.keyValuePairs) {
                                                                this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].static.push(keyValuePair)
                                                            }
                                                            return;
                                                        }
                                                    } else if (dataElement.componentDataElements) {
                                                        console.log("CHECKING COMPONENT DATA ELEMENTS")
                                                        for (let g = 0; g < dataElement.componentDataElements.length; g++) {
                                                            let componentDataElement = dataElement.componentDataElements[g];
                                                            if (componentDataElement.element === this.chosenChip) {
                                                                console.log("COMPONENT EQUAL TO CHOSEN CHIP")
                                                                if (Array.isArray(componentDataElement.element.static)) {
                                                                    for (let keyValuePair of this.keyValuePairs) {
                                                                        this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].componentDataElements[g].element.static.push(keyValuePair)
                                                                    }
                                                                    return;
                                                                } else {
                                                                    this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].componentDataElements[g].element.static = []
                                                                    for (let keyValuePair of this.keyValuePairs) {
                                                                        this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].componentDataElements[g].element.static.push(keyValuePair)
                                                                    }
                                                                    return;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                for (let index in subGroup) {
                                    let integerIndex = parseInt(index)
                                    let subSegment = subGroup[integerIndex]
                                    // console.log({
                                    //     integerIndex
                                    // })
                                    for (let x = 0; x < subSegment.DataElements.length; x++) {
                                        let DataElement = subSegment.DataElements[x];
                                        // console.log({
                                        //     integerIndex,
                                        //     DataElement,
                                        //     chosenChip: this.chosenChip
                                        // })
                                        if (DataElement === this.chosenChip) {
                                            // this.segments[i][integerIndex].DataElements[x].mappedValue = item
                                            if (Array.isArray(DataElement.static)) {
                                                for (let keyValuePair of this.keyValuePairs) {
                                                    this.segments[i].subGroups[a][integerIndex].DataElements[x].static.push(keyValuePair)
                                                }
                                                return
                                            } else {
                                                this.segments[i].subGroups[a][integerIndex].DataElements[x].static = [];
                                                for (let keyValuePair of this.keyValuePairs) {
                                                    this.segments[i].subGroups[a][integerIndex].DataElements[x].static.push(keyValuePair)
                                                }
                                                return
                                            }
                                        } else if (DataElement.componentDataElements) {
                                            for (let z = 0; z < DataElement.componentDataElements.length; z++) {
                                                let componentDataElement = DataElement.componentDataElements[z];
                                                if (componentDataElement.element === this.chosenChip) {
                                                    if (Array.isArray(componentDataElement.element.static)) {
                                                        for (let keyValuePair of this.keyValuePairs) {
                                                            this.segments[i].subGroups[a][integerIndex].DataElements[x].componentDataElements[z].element.static.push(keyValuePair)
                                                        }
                                                        return
                                                    } else {
                                                        this.segments[i].subGroups[a][integerIndex].DataElements[x].componentDataElements[z].element.static = [];
                                                        for (let keyValuePair of this.keyValuePairs) {
                                                            this.segments[i].subGroups[a][integerIndex].DataElements[x].componentDataElements[z].element.static.push(keyValuePair)
                                                        }
                                                        return
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            for (let o = 0; o < segment.groupElements.length; o++) {
                                let groupElement = segment.groupElements[o];
                                for (let p = 0; p < groupElement.DataElements.length; p++) {
                                    let DataElement = groupElement.DataElements[p];
                                    if (DataElement === this.chosenChip) {
                                        if (Array.isArray(DataElement.static)) {
                                            for (let keyValuePair of this.keyValuePairs) {
                                                this.segments[i].groupElements[o].DataElements[p].static.push(keyValuePair)
                                            }
                                            return;
                                        } else {
                                            this.segments[i].groupElements[o].DataElements[p].static = []
                                            for (let keyValuePair of this.keyValuePairs) {
                                                this.segments[i].groupElements[o].DataElements[p].static.push(keyValuePair)
                                            }
                                            return;
                                        }
                                    } else if (DataElement.componentDataElements) {
                                        for (let w = 0; w < DataElement.componentDataElements.length; w++) {
                                            let componentDataElement = DataElement.componentDataElements[w];
                                            if (componentDataElement.element === this.chosenChip) {
                                                if (Array.isArray(componentDataElement.element.static)) {
                                                    for (let keyValuePair of this.keyValuePairs) {
                                                        this.segments[i].groupElements[o].DataElements[p].componentDataElements[w].element.static.push(keyValuePair)
                                                    }
                                                    return;
                                                } else {
                                                    this.segments[i].groupElements[o].DataElements[p].componentDataElements[w].element.static = []
                                                    for (let keyValuePair of this.keyValuePairs) {
                                                        this.segments[i].groupElements[o].DataElements[p].componentDataElements[w].element.static.push(keyValuePair)
                                                    }
                                                    return;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                        } else {
                            for (let j = 0; j < segment.DataElements.length; j++) {
                                let DataElement = segment.DataElements[j];
                                if (DataElement === this.chosenChip) {
                                    if (Array.isArray(DataElement.static)) {
                                        for (let keyValuePair of this.keyValuePairs) {
                                            this.segments[i].DataElements[j].static.push(keyValuePair)
                                        }
                                        return
                                    } else {
                                        this.segments[i].DataElements[j].static = []
                                        for (let keyValuePair of this.keyValuePairs) {
                                            this.segments[i].DataElements[j].static.push(keyValuePair)
                                        }
                                        return
                                    }
                                } else if (DataElement.componentDataElements) {
                                    for (let k = 0; k < DataElement.componentDataElements.length; k++) {
                                        let componentDataElement = DataElement.componentDataElements[k];
                                        if (componentDataElement.element === this.chosenChip) {
                                            console.log(componentDataElement)
                                            if (Array.isArray(componentDataElement.element.static)) {
                                                for (let keyValuePair of this.keyValuePairs) {
                                                    this.segments[i].DataElements[j].componentDataElements[k].element.static.push(keyValuePair)
                                                }
                                                return
                                            } else {
                                                this.segments[i].DataElements[j].componentDataElements[k].element.static = []
                                                for (let keyValuePair of this.keyValuePairs) {
                                                    this.segments[i].DataElements[j].componentDataElements[k].element.static.push(keyValuePair)
                                                }
                                                return
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        for (let y = 1; y < this.segments[i].length; y++) {
                            let subSegment = this.segments[i][y];
                            for (let x = 0; x < subSegment.DataElements.length; x++) {
                                let DataElement = subSegment.DataElements[x];
                                if (DataElement === this.chosenChip) {
                                    if (Array.isArray(DataElement.static)) {
                                        for (let keyValuePair of this.keyValuePairs) {
                                            this.segments[i][y].DataElements[x].static.push(keyValuePair)
                                        }
                                        return
                                    } else {
                                        this.segments[i][y].DataElements[x].static = []
                                        for (let keyValuePair of this.keyValuePairs) {
                                            this.segments[i][y].DataElements[x].static.push(keyValuePair)
                                        }
                                        return
                                    }
                                } else if (DataElement.componentDataElements) {
                                    for (let z = 0; z < DataElement.componentDataElements.length; z++) {
                                        let componentDataElement = DataElement.componentDataElements[z];
                                        if (componentDataElement.element === this.chosenChip) {
                                            if (Array.isArray(componentDataElement.element.static)) {
                                                for (let keyValuePair of this.keyValuePairs) {
                                                    this.segments[i][y].DataElements[x].componentDataElements[z].element.static.push(keyValuePair)
                                                }
                                                return
                                            } else {
                                                this.segments[i][y].DataElements[x].componentDataElements[z].element.static = []
                                                for (let keyValuePair of this.keyValuePairs) {
                                                    this.segments[i][y].DataElements[x].componentDataElements[z].element.static.push(keyValuePair)
                                                }
                                                return
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        async removeKeyValuePair(keyValuePair) {
            let segment = this.currentSegment;
            this.mapDialog = false
            for (let i = 0; i < this.segments.length; i++) {
                if (segment === this.segments[i]) {
                    if (!Array.isArray(segment)) {
                        if (segment.groupElements && segment.subGroups) {
                            console.log({
                                SubGroups: segment.subGroups
                            })
                            for (let a = 0; a < segment.subGroups.length; a++) {
                                let subGroup = segment.subGroups[a];

                                if (subGroup[0].subGroups) {
                                    console.log("SUB GROUPS HAS SUB GROUPS")
                                    for (let v = 0; v < subGroup[0].subGroups.length; v++) {
                                        let sub = subGroup[0].subGroups[v]
                                        console.log({
                                            sub
                                        })
                                        for (let s = 0; s < sub.length; s++) {
                                            let subItem = sub[s];

                                            if (subItem.subGroups) {
                                                for (let lastGroup of subItem.subGroups) {
                                                    let lastGroupIndex = this.segments[i].subGroups[a][0].subGroups[v][s].subGroups.indexOf(lastGroup)
                                                    // console.log({
                                                    //     lastGroup,
                                                    //     lastGroupIndex
                                                    // })
                                                    for (let lastSegment of lastGroup.slice(1)) {
                                                        let lastSegmentIndex = this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex].indexOf(lastSegment);
                                                        // console.log({lastSegment, lastSegmentIndex})
                                                        if (lastSegment.DataElements) {
                                                            console.log("SUB HAS DATA ELEMENTS")
                                                            for (let r = 0; r < lastSegment.DataElements.length; r++) {
                                                                let dataElement = lastSegment.DataElements[r]
                                                                if (dataElement === this.chosenChip) {
                                                                    console.log("TESTER", this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex])
                                                                    for (let staticValueIndex = 0; staticValueIndex < this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].static.length; staticValueIndex++) {
                                                                        if (this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].static[staticValueIndex] === keyValuePair) {
                                                                            this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].static.splice(staticValueIndex, 1);
                                                                            staticValueIndex--;
                                                                            return
                                                                        }
                                                                    }
                                                                } else if (dataElement.componentDataElements) {
                                                                    console.log("CHECKING COMPONENT DATA ELEMENTS")
                                                                    for (let g = 0; g < dataElement.componentDataElements.length; g++) {
                                                                        let componentDataElement = dataElement.componentDataElements[g];
                                                                        if (componentDataElement.element === this.chosenChip) {
                                                                            console.log("COMPONENT EQUAL TO CHOSEN CHIP")
                                                                            for (let staticValueIndex = 0; staticValueIndex < this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].componentDataElements[g].element.static.length; staticValueIndex++) {
                                                                                if (this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].componentDataElements[g].element.static[staticValueIndex] === keyValuePair) {
                                                                                    this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].componentDataElements[g].element.static.splice(staticValueIndex, 1);
                                                                                    staticValueIndex--;
                                                                                    return
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            if (subItem.DataElements) {
                                                console.log("SUB HAS DATA ELEMENTS")
                                                for (let r = 0; r < subItem.DataElements.length; r++) {
                                                    let dataElement = subItem.DataElements[r]
                                                    if (dataElement === this.chosenChip) {
                                                        console.log("TESTER", this.segments[i].subGroups[a][0].subGroups[v][s])
                                                        for (let staticValueIndex = 0; staticValueIndex < this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].static.length; staticValueIndex++) {
                                                            if (this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].static[staticValueIndex] === keyValuePair) {
                                                                this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].static.splice(staticValueIndex, 1);
                                                                staticValueIndex--;
                                                                return
                                                            }
                                                        }
                                                    } else if (dataElement.componentDataElements) {
                                                        console.log("CHECKING COMPONENT DATA ELEMENTS")
                                                        for (let g = 0; g < dataElement.componentDataElements.length; g++) {
                                                            let componentDataElement = dataElement.componentDataElements[g];
                                                            if (componentDataElement.element === this.chosenChip) {
                                                                console.log("COMPONENT EQUAL TO CHOSEN CHIP")
                                                                for (let staticValueIndex = 0; staticValueIndex < this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].componentDataElements[g].element.static.length; staticValueIndex++) {
                                                                    if (this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].componentDataElements[g].element.static[staticValueIndex] === keyValuePair) {
                                                                        this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].componentDataElements[g].element.static.splice(staticValueIndex, 1);
                                                                        staticValueIndex--;
                                                                        return;
                                                                    }
                                                                }

                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                for (let index in subGroup) {
                                    let integerIndex = parseInt(index)
                                    let subSegment = subGroup[integerIndex]
                                    // console.log({
                                    //     integerIndex
                                    // })
                                    for (let x = 0; x < subSegment.DataElements.length; x++) {
                                        let DataElement = subSegment.DataElements[x];
                                        // console.log({
                                        //     integerIndex,
                                        //     DataElement,
                                        //     chosenChip: this.chosenChip
                                        // })
                                        if (DataElement === this.chosenChip) {
                                            // this.segments[i][integerIndex].DataElements[x].mappedValue = item
                                            for (let staticValueIndex = 0; staticValueIndex < this.segments[i].subGroups[a][integerIndex].DataElements[x].static.length; staticValueIndex++) {
                                                if (this.segments[i].subGroups[a][integerIndex].DataElements[x].static[staticValueIndex] === keyValuePair) {
                                                    this.segments[i].subGroups[a][integerIndex].DataElements[x].static.splice(staticValueIndex, 1);
                                                    staticValueIndex--;
                                                    return
                                                }
                                            }
                                        } else if (DataElement.componentDataElements) {
                                            for (let z = 0; z < DataElement.componentDataElements.length; z++) {
                                                let componentDataElement = DataElement.componentDataElements[z];
                                                if (componentDataElement.element === this.chosenChip) {
                                                    for (let staticValueIndex = 0; staticValueIndex < this.segments[i].subGroups[a][integerIndex].DataElements[x].componentDataElements[z].element.static.length; staticValueIndex++) {
                                                        if (this.segments[i].subGroups[a][integerIndex].DataElements[x].componentDataElements[z].element.static[staticValueIndex] === keyValuePair) {
                                                            this.segments[i].subGroups[a][integerIndex].DataElements[x].componentDataElements[z].element.static.splice(staticValueIndex, 1);
                                                            staticValueIndex--;
                                                            return
                                                        }
                                                    }

                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            for (let o = 0; o < segment.groupElements.length; o++) {
                                let groupElement = segment.groupElements[o];
                                for (let p = 0; p < groupElement.DataElements.length; p++) {
                                    let DataElement = groupElement.DataElements[p];
                                    if (DataElement === this.chosenChip) {
                                        for (let staticValueIndex = 0; staticValueIndex < this.segments[i].groupElements[o].DataElements[p].static.length; staticValueIndex++) {
                                            if (this.segments[i].groupElements[o].DataElements[p].static[staticValueIndex] === keyValuePair) {
                                                this.segments[i].groupElements[o].DataElements[p].static.splice(staticValueIndex, 1);
                                                staticValueIndex--;
                                                return
                                            }
                                        }

                                    } else if (DataElement.componentDataElements) {
                                        for (let w = 0; w < DataElement.componentDataElements.length; w++) {
                                            let componentDataElement = DataElement.componentDataElements[w];
                                            if (componentDataElement.element === this.chosenChip) {
                                                for (let staticValueIndex = 0; staticValueIndex < this.segments[i].groupElements[o].DataElements[p].componentDataElements[w].element.static.length; staticValueIndex++) {
                                                    if (this.segments[i].groupElements[o].DataElements[p].componentDataElements[w].element.static[staticValueIndex] === keyValuePair) {
                                                        this.segments[i].groupElements[o].DataElements[p].componentDataElements[w].element.static.splice(staticValueIndex, 1);
                                                        staticValueIndex--;
                                                        return
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                        } else {
                            for (let j = 0; j < segment.DataElements.length; j++) {
                                let DataElement = segment.DataElements[j];
                                if (DataElement === this.chosenChip) {
                                    for (let staticValueIndex = 0; staticValueIndex < this.segments[i].DataElements[j].static.length; staticValueIndex++) {
                                        if (this.segments[i].DataElements[j].static[staticValueIndex] === keyValuePair) {
                                            this.segments[i].DataElements[j].static.splice(staticValueIndex, 1);
                                            staticValueIndex--;
                                            return
                                        }
                                    }

                                } else if (DataElement.componentDataElements) {
                                    for (let k = 0; k < DataElement.componentDataElements.length; k++) {
                                        let componentDataElement = DataElement.componentDataElements[k];
                                        if (componentDataElement.element === this.chosenChip) {
                                            console.log(componentDataElement)
                                            for (let staticValueIndex = 0; staticValueIndex < this.segments[i].DataElements[j].componentDataElements[k].element.static.length; staticValueIndex++) {
                                                if (this.segments[i].DataElements[j].componentDataElements[k].element.static[staticValueIndex] === keyValuePair) {
                                                    this.segments[i].DataElements[j].componentDataElements[k].element.static.splice(staticValueIndex, 1);
                                                    staticValueIndex--;
                                                    return
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        for (let y = 1; y < this.segments[i].length; y++) {
                            let subSegment = this.segments[i][y];
                            for (let x = 0; x < subSegment.DataElements.length; x++) {
                                let DataElement = subSegment.DataElements[x];
                                if (DataElement === this.chosenChip) {
                                    for (let staticValueIndex = 0; staticValueIndex < this.segments[i][y].DataElements[x].static.length; staticValueIndex++) {
                                        if (this.segments[i][y].DataElements[x].static[staticValueIndex] === keyValuePair) {
                                            this.segments[i][y].DataElements[x].static.splice(staticValueIndex, 1);
                                            staticValueIndex--;
                                            return
                                        }
                                    }

                                } else if (DataElement.componentDataElements) {
                                    for (let z = 0; z < DataElement.componentDataElements.length; z++) {
                                        let componentDataElement = DataElement.componentDataElements[z];
                                        if (componentDataElement.element === this.chosenChip) {
                                            for (let staticValueIndex = 0; staticValueIndex < this.segments[i][y].DataElements[x].componentDataElements[z].element.static.length; staticValueIndex++) {
                                                if (this.segments[i][y].DataElements[x].componentDataElements[z].element.static[staticValueIndex] === keyValuePair) {
                                                    this.segments[i][y].DataElements[x].componentDataElements[z].element.static.splice(staticValueIndex, 1);
                                                    staticValueIndex--;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        async addKeyValuePairs() {
            this.leftColumnItems.push({
                name: ""
            });
            this.rightColumnItems.push({
                value: ""
            });
            this.descriptionColumnItems.push({
                description: ""
            })
        },
        removeRow() {
            let index = this.leftColumnItems.length - 1
            this.leftColumnItems.splice(index, 1);
            this.rightColumnItems.splice(index, 1);
            this.descriptionColumnItems.splice(index, 1)
        },
        async setStatic() {
            this.mapDialog = false;
            this.setStaticDialog = false;
            let segment = this.currentSegment;
            for (let i = 0; i < this.segments.length; i++) {
                if (segment === this.segments[i]) {
                    if (!Array.isArray(segment)) {
                        if (segment.groupElements && segment.subGroups) {
                            console.log({
                                SubGroups: segment.subGroups
                            })
                            for (let a = 0; a < segment.subGroups.length; a++) {
                                let subGroup = segment.subGroups[a];

                                if (subGroup[0].subGroups) {
                                    console.log("SUB GROUPS HAS SUB GROUPS")
                                    for (let v = 0; v < subGroup[0].subGroups.length; v++) {
                                        let sub = subGroup[0].subGroups[v]
                                        console.log({
                                            sub
                                        })
                                        for (let s = 0; s < sub.length; s++) {
                                            let subItem = sub[s];

                                            if (subItem.subGroups) {
                                                for (let lastGroup of subItem.subGroups) {
                                                    let lastGroupIndex = this.segments[i].subGroups[a][0].subGroups[v][s].subGroups.indexOf(lastGroup)
                                                    // console.log({
                                                    //     lastGroup,
                                                    //     lastGroupIndex
                                                    // })
                                                    for (let lastSegment of lastGroup.slice(1)) {
                                                        let lastSegmentIndex = this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex].indexOf(lastSegment);
                                                        // console.log({lastSegment, lastSegmentIndex})
                                                        if (lastSegment.DataElements) {
                                                            console.log("SUB HAS DATA ELEMENTS")
                                                            for (let r = 0; r < lastSegment.DataElements.length; r++) {
                                                                let dataElement = lastSegment.DataElements[r]
                                                                if (dataElement === this.chosenChip) {
                                                                    console.log("TESTER", this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex])
                                                                    this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].mappedValue = this.staticValue;
                                                                    this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].static = this.staticValue;
                                                                    return;
                                                                } else if (dataElement.componentDataElements) {
                                                                    console.log("CHECKING COMPONENT DATA ELEMENTS")
                                                                    for (let g = 0; g < dataElement.componentDataElements.length; g++) {
                                                                        let componentDataElement = dataElement.componentDataElements[g];
                                                                        if (componentDataElement.element === this.chosenChip) {
                                                                            console.log("COMPONENT EQUAL TO CHOSEN CHIP")
                                                                            this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].componentDataElements[g].element.mappedValue = this.staticValue
                                                                            this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].componentDataElements[g].element.static = this.staticValue
                                                                            return;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            if (subItem.DataElements) {
                                                console.log("SUB HAS DATA ELEMENTS")
                                                for (let r = 0; r < subItem.DataElements.length; r++) {
                                                    let dataElement = subItem.DataElements[r]
                                                    if (dataElement === this.chosenChip) {
                                                        console.log("TESTER", this.segments[i].subGroups[a][0].subGroups[v][s])
                                                        this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].mappedValue = this.staticValue;
                                                        this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].static = this.staticValue
                                                        this.staticValue = ""
                                                        return;
                                                    } else if (dataElement.componentDataElements) {
                                                        console.log("CHECKING COMPONENT DATA ELEMENTS")
                                                        for (let g = 0; g < dataElement.componentDataElements.length; g++) {
                                                            let componentDataElement = dataElement.componentDataElements[g];
                                                            if (componentDataElement.element === this.chosenChip) {
                                                                console.log("COMPONENT EQUAL TO CHOSEN CHIP")
                                                                this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].componentDataElements[g].element.mappedValue = this.staticValue;
                                                                this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].componentDataElements[g].element.static = this.staticValue
                                                                this.staticValue = ""
                                                                return;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                for (let index in subGroup) {
                                    let integerIndex = parseInt(index)
                                    let subSegment = subGroup[integerIndex]
                                    // console.log({
                                    //     integerIndex
                                    // })
                                    for (let x = 0; x < subSegment.DataElements.length; x++) {
                                        let DataElement = subSegment.DataElements[x];
                                        // console.log({
                                        //     integerIndex,
                                        //     DataElement,
                                        //     chosenChip: this.chosenChip
                                        // })
                                        if (DataElement === this.chosenChip) {
                                            // this.segments[i][integerIndex].DataElements[x].mappedValue = item
                                            this.segments[i].subGroups[a][integerIndex].DataElements[x].mappedValue = this.staticValue;
                                            this.segments[i].subGroups[a][integerIndex].DataElements[x].static = this.staticValue
                                            this.staticValue = ""
                                            return
                                        } else if (DataElement.componentDataElements) {
                                            for (let z = 0; z < DataElement.componentDataElements.length; z++) {
                                                let componentDataElement = DataElement.componentDataElements[z];
                                                if (componentDataElement.element === this.chosenChip) {
                                                    this.segments[i].subGroups[a][integerIndex].DataElements[x].componentDataElements[z].element.mappedValue = this.staticValue;
                                                    this.segments[i].subGroups[a][integerIndex].DataElements[x].componentDataElements[z].element.static = this.staticValue
                                                    this.staticValue = ""
                                                    return
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            for (let o = 0; o < segment.groupElements.length; o++) {
                                let groupElement = segment.groupElements[o];
                                for (let p = 0; p < groupElement.DataElements.length; p++) {
                                    let DataElement = groupElement.DataElements[p];
                                    if (DataElement === this.chosenChip) {
                                        this.segments[i].groupElements[o].DataElements[p].mappedValue = this.staticValue;
                                        this.segments[i].groupElements[o].DataElements[p].static = this.staticValue
                                        this.staticValue = ""
                                        return;
                                    } else if (DataElement.componentDataElements) {
                                        for (let w = 0; w < DataElement.componentDataElements.length; w++) {
                                            let componentDataElement = DataElement.componentDataElements[w];
                                            if (componentDataElement.element === this.chosenChip) {
                                                this.segments[i].groupElements[o].DataElements[p].componentDataElements[w].element.mappedValue = this.staticValue
                                                this.segments[i].groupElements[o].DataElements[p].componentDataElements[w].element.static = this.staticValue
                                                this.staticValue = ""
                                                return;
                                            }
                                        }
                                    }
                                }
                            }

                        } else {
                            for (let j = 0; j < segment.DataElements.length; j++) {
                                let DataElement = segment.DataElements[j];
                                if (DataElement === this.chosenChip) {
                                    this.segments[i].DataElements[j].mappedValue = this.staticValue;
                                    this.segments[i].DataElements[j].static = this.staticValue;
                                    this.staticValue = ""
                                    return
                                } else if (DataElement.componentDataElements) {
                                    for (let k = 0; k < DataElement.componentDataElements.length; k++) {
                                        let componentDataElement = DataElement.componentDataElements[k];
                                        if (componentDataElement.element === this.chosenChip) {
                                            console.log(componentDataElement)
                                            this.segments[i].DataElements[j].componentDataElements[k].element.mappedValue = this.staticValue;
                                            this.segments[i].DataElements[j].componentDataElements[k].element.static = this.staticValue
                                            this.staticValue = ""
                                            return
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        for (let y = 1; y < this.segments[i].length; y++) {
                            let subSegment = this.segments[i][y];
                            for (let x = 0; x < subSegment.DataElements.length; x++) {
                                let DataElement = subSegment.DataElements[x];
                                if (DataElement === this.chosenChip) {
                                    this.segments[i][y].DataElements[x].mappedValue = this.staticValue;
                                    this.segments[i][y].DataElements[x].static = this.staticValue
                                    this.staticValue = ""
                                    return
                                } else if (DataElement.componentDataElements) {
                                    for (let z = 0; z < DataElement.componentDataElements.length; z++) {
                                        let componentDataElement = DataElement.componentDataElements[z];
                                        if (componentDataElement.element === this.chosenChip) {
                                            this.segments[i][y].DataElements[x].componentDataElements[z].element.mappedValue = this.staticValue;
                                            this.segments[i][y].DataElements[x].componentDataElements[z].element.static = this.staticValue;
                                            this.staticValue = ""
                                            return
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        async saveCopyOfTemplate() {
            let response = this.$API.uploadCopyOfBuild({
                fileName: this.newBuildName,
                fileData: this.jsonObj
            })
            this.snackbar.show = true
            this.snackbar.color = "success";
            this.snackbar.text = `Created Copy Successfully!`
            this.segments = this.jsonObj.SegmentTable
            this.modifyBuildDialog = false
        },
        async deleteTemplate(item) {
            let fileName = item.fileName;
            let deletionResponse = await this.$API.removeMessageTemplate({
                fileName,
            });

            await this.fetchFileTemplates();
            await this.fetchIncompletes();

            this.refreshKey++; // Increment the refreshKey after both functions have completed
        },
        async submitFiles() {
            console.log(this.fileList)
            for (let i = 0; i < this.fileList.length; i++) {
                const file = this.fileList[i].file;
                const url = URL.createObjectURL(file);
                console.log({
                    url
                })
                const dataUrl = await this.getDataUrl(url);

                // Decode base64-encoded JSON to a JavaScript object
                const decodedData = JSON.parse(atob(dataUrl.split(',')[1]));
                this.fileData = decodedData
                const {
                    groupedKeys,
                    groupNames
                } = await this.$API.fetchJSONData({
                    fileData: decodedData
                });
                this.fileJSON = groupedKeys
                this.tabs = groupNames
                console.log({
                    JSON: this.fileJSON
                })
            }

            return this.fileJSON;
        },
        async submitJSON() {
            console.log(this.msgList)
            for (let i = 0; i < this.msgList.length; i++) {
                const file = this.msgList[i].file;
                const url = URL.createObjectURL(file);
                console.log({
                    url
                })
                const dataUrl = await this.getDataUrl(url);

                // Decode base64-encoded JSON to a JavaScript object
                const decodedData = atob(dataUrl.split(',')[1])
                this.fileMSG = decodedData
            }
            console.log({
                fileMSG: this.fileMSG
            })
            return this.fileMSG;
        },
        async getDataUrl(url) {
            return new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.open('GET', url, true);
                xhr.responseType = 'blob';

                xhr.onload = function () {
                    if (xhr.status === 200) {
                        const blobData = xhr.response;
                        const reader = new FileReader();

                        reader.onloadend = function () {
                            const dataUrl = reader.result;
                            resolve(dataUrl);
                        };

                        reader.readAsDataURL(blobData);
                    } else {
                        reject(new Error('Failed to retrieve data from URL.'));
                    }
                };

                xhr.send();
            });
        },
        addFiles() {
            console.log("JSON!")
            this.$refs.jsonUploader.$el.children[0].click();
        },
        addMsgs() {
            console.log("MSG!")
            this.$refs.edifactMSGUploader.$el.children[0].click();
        },
        async commenceAction(action) {
            if (action === "New") {
                this.commenceBuildCreateDialog = true
                this.createTemplateDialog = false
            } else if (action === "Open") {
                this.chooseBuildDialog = true
                this.createTemplateDialog = false
            } else if (action === "Make a Copy") {
                this.commeceCopyCreationDialog = true
                this.createTemplateDialog = false
            } else if (action === "Delete") {
                this.removeMessageTemplateDialog = true
                console.log(action)
            } else if (action === "Create Service") {
                this.commenceCreationDialog = true
            } else if (action === "Save As") {
                this.nameIncompleteDialog = true
            } else if (action === "Save") {
                this.saveCurrent()
            }
        },
        async saveCurrent() {
            this.loading = true;
            console.log({
                selectedFileProperties: this.selectedFileProperties
            })
            let saveResponse = await this.$API.saveCurrent({
                fileProperties: this.selectedFileProperties,
                segments: this.segments
            })
            this.loading = false;
            this.tableKey++;
            this.snackbar.show = true;
            this.snackbar.text = saveResponse
            this.snackbar.color = "success"
        },
        async newBuildFromOldBuild(item) {
            console.log({
                item
            })
            this.commenceBuildCreateDialog = false;
            this.commeceCopyCreationDialog = false;
            console.log("GETTING JSON OBJ!")
            this.jsonObj = await this.$API.newBuildFromOld({
                fileName: item.fileName
            })
            console.log(this.jsonObj)
            // this.jsonObj = JSON.stringify(this.jsonObj)
            console.log({
                jsonObj: this.jsonObj
            })
            await this.fetchFileTemplates()
            await this.fetchIncompletes()
            this.refreshKey++
            this.modifyBuildDialog = true
        },
        async fetchIncompletes() {
            let params = {}
            params.limit = this.incompleteParams.limit
            params.offset = this.incompleteParams.offset
            if (this.incompleteSearch && this.incompleteSearch.length > 0) {
                params.search = this.incompleteSearch
            }
            let response = await this.$API.fetchIncompletes(params)
            this.incompleteTemplates = response.paginatedFileList
        },
        async submitIncomplete() {
            this.nameIncompleteDialog = false
            this.loading = true
            let response = await this.$API.submitIncompleteTemplate({
                segments: this.segments,
                templateName: this.templateName
            });
            await this.fetchFileTemplates()
            await this.fetchIncompletes()
            this.refreshKey++
            this.loading = false
            this.snackbar.text = response;
            this.snackbar.show = true;
            this.snackbar.color = "success"
            this.templateName = ""
        },
        async createNewBuildUN() {
            // Set the flag to indicate that a build is being created
            this.creatingBuild = true;

            console.log({
                BuildName: this.newBuildName
            });

            try {
                let version = this.chosenUNVersion;
                let messageType = this.chosenUNMessageType;
                let fileName = this.newBuildName;

                let response = await this.$API.createBuildFileUN({
                    version,
                    messageType,
                    fileName,
                });

                console.log({
                    unBuildResponse: response
                });
            } catch (error) {
                console.error("Error creating build:", error);
            } finally {
                // Reset the flag after the build creation attempt is complete
                await this.fetchFileTemplates()
                await this.fetchIncompletes()
                this.refreshKey++
                this.creatingBuild = false;
                this.commenceBuildCreateDialog = false;
                this.chosenTemplateName = this.newBuildName
                this.fetchFileData()
            }
        },
        async fetchUNMessageTypes(version) {
            let params = {
                version: version
            }
            this.unMessageTypes = await this.$API.fetchUNMessageTypes(params)
            console.log(this.unMessageTypes)
        },
        async fetchUNVersions() {
            this.chooseBuildDialog = false;
            this.unVersions = await this.$API.fetchUNVersions()
            console.log(this.unVersions)

        },
        selectBuild(item) {
            this.chosenTemplateName = item.fileName;
            this.selectedFileProperties = item;
            this.fetchFileData()
        },
        selectIncomplete(item) {
            console.log(item)
            this.chosenIncompleteName = item.fileName;
            this.fetchIncompleteData()
        },
        async markSegmentInUseOrNotInUse(parentSegment, childSegment) {
            function recursiveMark(segment) {
                if (segment === childSegment) {
                    // this.fetchPreviewMessage()
                    return true;
                }

                if (segment.subGroups) {
                    for (let a = 0; a < segment.subGroups.length; a++) {
                        let subGroup = segment.subGroups[a];
                        for (let subGrpSeg of subGroup) {
                            if (recursiveMark(subGrpSeg)) {
                                // this.fetchPreviewMessage()
                                return true;
                            }
                        }
                    }
                }
                // this.fetchPreviewMessage()
                return false;
            }
            if (Array.isArray(parentSegment)) {
                console.log({
                    childSegment,
                    parentSegment
                })
                for (let seg of parentSegment) {
                    if (recursiveMark(seg)) {
                        if (Object.prototype.hasOwnProperty.call(childSegment, "inUse")) {
                            childSegment.inUse = !childSegment.inUse;
                        } else {
                            childSegment.inUse = true;
                        }
                        // this.fetchPreviewMessage()
                        for (let i = 0; i < childSegment.DataElements.length; i++) {
                            childSegment.DataElements[i].inUse = childSegment.inUse
                            if (childSegment.DataElements[i].componentDataElements && childSegment.DataElements[i].componentDataElements.length > 0) {
                                for (let j = 0; j < childSegment.DataElements[i].componentDataElements.length; j++) {
                                    childSegment.DataElements[i].componentDataElements[j].element.inUse = childSegment.inUse
                                }
                            }
                        }

                        // console.log({
                        //     parentSegment
                        // })
                        // for (let k = 0; k < parentSegment.length; k++) {
                        //     console.log({
                        //         seg: parentSegment[k]
                        //     });
                        //     parentSegment[k].inUse = childSegment.inUse;

                        //     for (let l = 0; l < parentSegment[k].DataElements.length; l++) { // Declare 'l' using 'let' and fix the loop condition
                        //         parentSegment[k].DataElements[l].inUse = childSegment.inUse;

                        //         if (parentSegment[k].DataElements[l].componentDataElements && parentSegment[k].DataElements[l].componentDataElements.length > 0) {
                        //             for (let h = 0; h < parentSegment[k].DataElements[l].componentDataElements.length; h++) {
                        //                 parentSegment[k].DataElements[l].componentDataElements[h].element.inUse = childSegment.inUse;
                        //             }
                        //         }
                        //     }
                        // }
                        this.segmentsKey++;
                        return;
                    }
                }
            } else {
                console.log({
                    childSegment,
                    parentSegment
                })
                if (parentSegment === childSegment || recursiveMark(parentSegment)) {
                    childSegment.inUse = !childSegment.inUse
                    // this.fetchPreviewMessage()
                    for (let i = 0; i < childSegment.DataElements.length; i++) {
                        console.log({
                            childSegmentDE: childSegment.DataElements[i]
                        })
                        childSegment.DataElements[i].inUse = childSegment.inUse
                        if (childSegment.DataElements[i].componentDataElements && childSegment.DataElements[i].componentDataElements.length > 0) {
                            for (let j = 0; j < childSegment.DataElements[i].componentDataElements.length; j++) {
                                childSegment.DataElements[i].componentDataElements[j].element.inUse = childSegment.inUse
                            }
                        }
                    }
                    // if (parentSegment.groupElements) {
                    // for (let k = 0; k < parentSegment.groupElements.length; k++) {
                    //         console.log({
                    //             seg: parentSegment.groupElements[k]
                    //         });
                    //         parentSegment.groupElements[k].inUse = childSegment.inUse;

                    //         for (let l = 0; l < parentSegment.groupElements[k].DataElements.length; l++) { // Declare 'l' using 'let' and fix the loop condition
                    //             parentSegment.groupElements[k].DataElements[l].inUse = childSegment.inUse;

                    //             if (parentSegment.groupElements[k].DataElements[l].componentDataElements && parentSegment.groupElements[k].DataElements[l].componentDataElements.length > 0) {
                    //                 for (let h = 0; h < parentSegment.groupElements[k].DataElements[l].componentDataElements.length; h++) {
                    //                     parentSegment.groupElements[k].DataElements[l].componentDataElements[h].element.inUse = childSegment.inUse;
                    //                 }
                    //             }
                    //         }
                    //     }
                    // }

                    // if (parentSegment.subGroups) {
                    //     for (let v = 0; v < parentSegment.subGroups.length; v++) {
                    //         for (let b = 0; b < parentSegment.subGroups[v].length; b++) {
                    //             parentSegment.subGroups[v][b].inUse = childSegment.inUse;
                    //             for (let n = 0; n < parentSegment.subGroups[v][b].DataElements.length; n++) {
                    //                 parentSegment.subGroups[v][b].DataElements[n].inUse = childSegment.inUse
                    //                 if (parentSegment.subGroups[v][b].DataElements[n].componentDataElements && parentSegment.subGroups[v][b].DataElements[n].componentDataElements.length > 0) {
                    //                     for (let m = 0; m < parentSegment.subGroups[v][b].DataElements[n].componentDataElements.length; m++) {
                    //                         parentSegment.subGroups[v][b].DataElements[n].componentDataElements[m].element.inUse = childSegment.inUse
                    //                     }
                    //                 }
                    //             }
                    //         }
                    //     }
                    // }
                    this.segmentsKey++;
                    return;
                }

                for (let o = 0; o < parentSegment.groupElements.length; o++) {
                    let groupElement = parentSegment.groupElements[o];
                    if (groupElement === childSegment) {
                        if (Object.prototype.hasOwnProperty.call(childSegment, "inUse")) {
                            childSegment.inUse = !childSegment.inUse;
                        } else {
                            childSegment.inUse = true;
                        }
                        // this.fetchPreviewMessage()
                        console.log({groupElement})
                        for (let i = 0; i < childSegment.DataElements.length; i++) {
                            childSegment.DataElements[i].inUse = childSegment.inUse
                            if (childSegment.DataElements[i].componentDataElements && childSegment.DataElements[i].componentDataElements.length > 0) {
                                for (let j = 0; j < childSegment.DataElements[i].componentDataElements.length; j++) {
                                    childSegment.DataElements[i].componentDataElements[j].element.inUse = childSegment.inUse
                                }
                            }
                        }
                        // for (let k = 0; k < parentSegment.groupElements.length; k++) {
                        //     console.log({
                        //         seg: parentSegment.groupElements[k]
                        //     });
                        //     parentSegment.groupElements[k].inUse = childSegment.inUse;

                        //     for (let l = 0; l < parentSegment.groupElements[k].DataElements.length; l++) { // Declare 'l' using 'let' and fix the loop condition
                        //         parentSegment.groupElements[k].DataElements[l].inUse = childSegment.inUse;

                        //         if (parentSegment.groupElements[k].DataElements[l].componentDataElements && parentSegment.groupElements[k].DataElements[l].componentDataElements.length > 0) {
                        //             for (let h = 0; h < parentSegment.groupElements[k].DataElements[l].componentDataElements.length; h++) {
                        //                 parentSegment.groupElements[k].DataElements[l].componentDataElements[h].element.inUse = childSegment.inUse;
                        //             }
                        //         }
                        //     }
                        // }
                        this.segmentsKey++;
                        return;
                    }
                }
            }
        },
        async createTemplate() {
            try {
                this.commenceCreationDialog = false
                let response = await this.$API.createEDIFACTTemplate({
                    segments: this.segments,
                    templateName: this.templateName
                });
                this.templateName = "";

                if (response.error) {
                    // this.snackbar.show = true;
                    this.snackbar.text = `Following Element is Unmapped: ${response.element}`
                    this.confirmIncompleteTemplateDialog = true
                    this.incompleteResponse = `${response.element}`

                } else {
                    this.snackbar.show = true;
                    this.snackbar.text = "EDIFACT MESSAGE TEMPLATE SUCCESSFULLY CREATED!"
                }
            } catch (error) {
                console.log({
                    error
                })
                if (error.response && error.response.status === 500) {
                    console.log("Request failed with status 500: Internal Server Error");
                } else {
                    console.log("Request failed with an error:", error.error);
                }
            }
        },
        async rightClickMenu(data) {
            if (!data.componentDataElements) {
                this.selectedData = data
                console.log(this.selectedData)
                this.rightClickMenuDialog = true
            }
        },
        async flagFunc() {
            this.mapDialog = false;
            let segment = this.currentSegment;
            for (let i = 0; i < this.segments.length; i++) {
                if (segment === this.segments[i]) {
                    if (!Array.isArray(segment)) {
                        if (segment.groupElements && segment.subGroups) {
                            console.log({
                                SubGroups: segment.subGroups
                            })
                            for (let a = 0; a < segment.subGroups.length; a++) {
                                let subGroup = segment.subGroups[a];

                                if (subGroup[0].subGroups) {
                                    console.log("SUB GROUPS HAS SUB GROUPS")
                                    for (let v = 0; v < subGroup[0].subGroups.length; v++) {
                                        let sub = subGroup[0].subGroups[v]
                                        console.log({
                                            sub
                                        })
                                        for (let s = 0; s < sub.length; s++) {
                                            let subItem = sub[s];

                                            if (subItem.subGroups) {
                                                for (let lastGroup of subItem.subGroups) {
                                                    let lastGroupIndex = this.segments[i].subGroups[a][0].subGroups[v][s].subGroups.indexOf(lastGroup)
                                                    // console.log({
                                                    //     lastGroup,
                                                    //     lastGroupIndex
                                                    // })
                                                    for (let lastSegment of lastGroup.slice(1)) {
                                                        let lastSegmentIndex = this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex].indexOf(lastSegment);
                                                        // console.log({lastSegment, lastSegmentIndex})
                                                        if (lastSegment.DataElements) {
                                                            console.log("SUB HAS DATA ELEMENTS")
                                                            for (let r = 0; r < lastSegment.DataElements.length; r++) {
                                                                let dataElement = lastSegment.DataElements[r]
                                                                if (dataElement === this.chosenChip) {
                                                                    console.log("TESTER", this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex])
                                                                    this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].flagged = !this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].flagged
                                                                    return;
                                                                } else if (dataElement.componentDataElements) {
                                                                    console.log("CHECKING COMPONENT DATA ELEMENTS")
                                                                    for (let g = 0; g < dataElement.componentDataElements.length; g++) {
                                                                        let componentDataElement = dataElement.componentDataElements[g];
                                                                        if (componentDataElement.element === this.chosenChip) {
                                                                            console.log("COMPONENT EQUAL TO CHOSEN CHIP")
                                                                            this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].componentDataElements[g].element.flagged = !this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].componentDataElements[g].element.flagged
                                                                            return;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            if (subItem.DataElements) {
                                                console.log("SUB HAS DATA ELEMENTS")
                                                for (let r = 0; r < subItem.DataElements.length; r++) {
                                                    let dataElement = subItem.DataElements[r]
                                                    if (dataElement === this.chosenChip) {
                                                        console.log("TESTER", this.segments[i].subGroups[a][0].subGroups[v][s])
                                                        this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].flagged = !this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].flagged;
                                                        return;
                                                    } else if (dataElement.componentDataElements) {
                                                        console.log("CHECKING COMPONENT DATA ELEMENTS")
                                                        for (let g = 0; g < dataElement.componentDataElements.length; g++) {
                                                            let componentDataElement = dataElement.componentDataElements[g];
                                                            if (componentDataElement.element === this.chosenChip) {
                                                                console.log("COMPONENT EQUAL TO CHOSEN CHIP")
                                                                this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].componentDataElements[g].element.flagged = !this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].componentDataElements[g].element.flagged;
                                                                return;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                for (let index in subGroup) {
                                    let integerIndex = parseInt(index)
                                    let subSegment = subGroup[integerIndex]
                                    // console.log({
                                    //     integerIndex
                                    // })
                                    for (let x = 0; x < subSegment.DataElements.length; x++) {
                                        let DataElement = subSegment.DataElements[x];
                                        // console.log({
                                        //     integerIndex,
                                        //     DataElement,
                                        //     chosenChip: this.chosenChip
                                        // })
                                        if (DataElement === this.chosenChip) {
                                            // this.segments[i][integerIndex].DataElements[x].mappedValue = item
                                            this.segments[i].subGroups[a][integerIndex].DataElements[x].flagged = !this.segments[i].subGroups[a][integerIndex].DataElements[x].flagged;
                                            return
                                        } else if (DataElement.componentDataElements) {
                                            for (let z = 0; z < DataElement.componentDataElements.length; z++) {
                                                let componentDataElement = DataElement.componentDataElements[z];
                                                if (componentDataElement.element === this.chosenChip) {
                                                    this.segments[i].subGroups[a][integerIndex].DataElements[x].componentDataElements[z].element.flagged = !this.segments[i].subGroups[a][integerIndex].DataElements[x].componentDataElements[z].element.flagged;
                                                    return
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            for (let o = 0; o < segment.groupElements.length; o++) {
                                let groupElement = segment.groupElements[o];
                                for (let p = 0; p < groupElement.DataElements.length; p++) {
                                    let DataElement = groupElement.DataElements[p];
                                    if (DataElement === this.chosenChip) {
                                        this.segments[i].groupElements[o].DataElements[p].flagged = !this.segments[i].groupElements[o].DataElements[p].flagged;
                                        return;
                                    } else if (DataElement.componentDataElements) {
                                        for (let w = 0; w < DataElement.componentDataElements.length; w++) {
                                            let componentDataElement = DataElement.componentDataElements[w];
                                            if (componentDataElement.element === this.chosenChip) {
                                                this.segments[i].groupElements[o].DataElements[p].componentDataElements[w].element.flagged = !this.segments[i].groupElements[o].DataElements[p].componentDataElements[w].element.flagged;
                                                return;
                                            }
                                        }
                                    }
                                }
                            }

                        } else {
                            for (let j = 0; j < segment.DataElements.length; j++) {
                                let DataElement = segment.DataElements[j];
                                if (DataElement === this.chosenChip) {
                                    this.segments[i].DataElements[j].flagged = !this.segments[i].DataElements[j].flagged;
                                    return
                                } else if (DataElement.componentDataElements) {
                                    for (let k = 0; k < DataElement.componentDataElements.length; k++) {
                                        let componentDataElement = DataElement.componentDataElements[k];
                                        if (componentDataElement.element === this.chosenChip) {
                                            console.log(componentDataElement)
                                            this.segments[i].DataElements[j].componentDataElements[k].element.flagged = !this.segments[i].DataElements[j].componentDataElements[k].element.flagged;
                                            return
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        for (let y = 1; y < this.segments[i].length; y++) {
                            let subSegment = this.segments[i][y];
                            for (let x = 0; x < subSegment.DataElements.length; x++) {
                                let DataElement = subSegment.DataElements[x];
                                if (DataElement === this.chosenChip) {
                                    this.segments[i][y].DataElements[x].flagged = !this.segments[i][y].DataElements[x].flagged;
                                    return
                                } else if (DataElement.componentDataElements) {
                                    for (let z = 0; z < DataElement.componentDataElements.length; z++) {
                                        let componentDataElement = DataElement.componentDataElements[z];
                                        if (componentDataElement.element === this.chosenChip) {
                                            this.segments[i][y].DataElements[x].componentDataElements[z].element.flagged = !this.segments[i][y].DataElements[x].componentDataElements[z].element.flagged;
                                            return
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        async unmap() {
            this.mapDialog = false;
            let segment = this.currentSegment;
            for (let i = 0; i < this.segments.length; i++) {
                if (segment === this.segments[i]) {
                    if (!Array.isArray(segment)) {
                        if (segment.groupElements && segment.subGroups) {
                            console.log({
                                SubGroups: segment.subGroups
                            })
                            for (let a = 0; a < segment.subGroups.length; a++) {
                                let subGroup = segment.subGroups[a];

                                if (subGroup[0].subGroups) {
                                    console.log("SUB GROUPS HAS SUB GROUPS")
                                    for (let v = 0; v < subGroup[0].subGroups.length; v++) {
                                        let sub = subGroup[0].subGroups[v]
                                        console.log({
                                            sub
                                        })
                                        for (let s = 0; s < sub.length; s++) {
                                            let subItem = sub[s];

                                            if (subItem.subGroups) {
                                                for (let lastGroup of subItem.subGroups) {
                                                    let lastGroupIndex = this.segments[i].subGroups[a][0].subGroups[v][s].subGroups.indexOf(lastGroup)
                                                    // console.log({
                                                    //     lastGroup,
                                                    //     lastGroupIndex
                                                    // })
                                                    for (let lastSegment of lastGroup.slice(1)) {
                                                        let lastSegmentIndex = this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex].indexOf(lastSegment);
                                                        // console.log({lastSegment, lastSegmentIndex})
                                                        if (lastSegment.DataElements) {
                                                            console.log("SUB HAS DATA ELEMENTS")
                                                            for (let r = 0; r < lastSegment.DataElements.length; r++) {
                                                                let dataElement = lastSegment.DataElements[r]
                                                                if (dataElement === this.chosenChip) {
                                                                    console.log("TESTER", this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex])
                                                                    this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].mappedValue = null
                                                                    if (!Array.isArray(this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].static)) {
                                                                        this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].static = null
                                                                    }
                                                                    return;
                                                                } else if (dataElement.componentDataElements) {
                                                                    console.log("CHECKING COMPONENT DATA ELEMENTS")
                                                                    for (let g = 0; g < dataElement.componentDataElements.length; g++) {
                                                                        let componentDataElement = dataElement.componentDataElements[g];
                                                                        if (componentDataElement.element === this.chosenChip) {
                                                                            console.log("COMPONENT EQUAL TO CHOSEN CHIP")
                                                                            this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].componentDataElements[g].element.mappedValue = null
                                                                            if (!Array.isArray(this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].componentDataElements[g].element.static)) {
                                                                                this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].componentDataElements[g].element.static = null
                                                                            }
                                                                            return;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            if (subItem.DataElements) {
                                                console.log("SUB HAS DATA ELEMENTS")
                                                for (let r = 0; r < subItem.DataElements.length; r++) {
                                                    let dataElement = subItem.DataElements[r]
                                                    if (dataElement === this.chosenChip) {
                                                        console.log("TESTER", this.segments[i].subGroups[a][0].subGroups[v][s])
                                                        this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].mappedValue = null;
                                                        if (!Array.isArray(this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].static)) {
                                                            this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].static = null
                                                        }
                                                        return;
                                                    } else if (dataElement.componentDataElements) {
                                                        console.log("CHECKING COMPONENT DATA ELEMENTS")
                                                        for (let g = 0; g < dataElement.componentDataElements.length; g++) {
                                                            let componentDataElement = dataElement.componentDataElements[g];
                                                            if (componentDataElement.element === this.chosenChip) {
                                                                console.log("COMPONENT EQUAL TO CHOSEN CHIP")
                                                                this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].componentDataElements[g].element.mappedValue = null;
                                                                if (!Array.isArray(this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].componentDataElements[g].element.static)) {
                                                                    this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].componentDataElements[g].element.static = null
                                                                }
                                                                return;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                for (let index in subGroup) {
                                    let integerIndex = parseInt(index)
                                    let subSegment = subGroup[integerIndex]
                                    // console.log({
                                    //     integerIndex
                                    // })
                                    for (let x = 0; x < subSegment.DataElements.length; x++) {
                                        let DataElement = subSegment.DataElements[x];
                                        // console.log({
                                        //     integerIndex,
                                        //     DataElement,
                                        //     chosenChip: this.chosenChip
                                        // })
                                        if (DataElement === this.chosenChip) {
                                            // this.segments[i][integerIndex].DataElements[x].mappedValue = item
                                            this.segments[i].subGroups[a][integerIndex].DataElements[x].mappedValue = null;
                                            if (!Array.isArray(this.segments[i].subGroups[a][integerIndex].DataElements[x].static)) {
                                                this.segments[i].subGroups[a][integerIndex].DataElements[x].static = null
                                            }
                                            return
                                        } else if (DataElement.componentDataElements) {
                                            for (let z = 0; z < DataElement.componentDataElements.length; z++) {
                                                let componentDataElement = DataElement.componentDataElements[z];
                                                if (componentDataElement.element === this.chosenChip) {
                                                    this.segments[i].subGroups[a][integerIndex].DataElements[x].componentDataElements[z].element.mappedValue = null;
                                                    if (!Array.isArray(this.segments[i].subGroups[a][integerIndex].DataElements[x].componentDataElements[z].element.static)) {
                                                        this.segments[i].subGroups[a][integerIndex].DataElements[x].componentDataElements[z].element.static = null
                                                    }
                                                    return
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            for (let o = 0; o < segment.groupElements.length; o++) {
                                let groupElement = segment.groupElements[o];
                                for (let p = 0; p < groupElement.DataElements.length; p++) {
                                    let DataElement = groupElement.DataElements[p];
                                    if (DataElement === this.chosenChip) {
                                        this.segments[i].groupElements[o].DataElements[p].mappedValue = null;
                                        if (!Array.isArray(this.segments[i].groupElements[o].DataElements[p].static)) {
                                            this.segments[i].groupElements[o].DataElements[p].static = null
                                        }
                                        return;
                                    } else if (DataElement.componentDataElements) {
                                        for (let w = 0; w < DataElement.componentDataElements.length; w++) {
                                            let componentDataElement = DataElement.componentDataElements[w];
                                            if (componentDataElement.element === this.chosenChip) {
                                                this.segments[i].groupElements[o].DataElements[p].componentDataElements[w].element.mappedValue = null;
                                                if (!Array.isArray(this.segments[i].groupElements[o].DataElements[p].componentDataElements[w].element.static)) {
                                                    this.segments[i].groupElements[o].DataElements[p].componentDataElements[w].element.static = null
                                                }
                                                return;
                                            }
                                        }
                                    }
                                }
                            }

                        } else {
                            for (let j = 0; j < segment.DataElements.length; j++) {
                                let DataElement = segment.DataElements[j];
                                if (DataElement === this.chosenChip) {
                                    this.segments[i].DataElements[j].mappedValue = null;
                                    if (!Array.isArray(this.segments[i].DataElements[j].static)) {
                                        this.segments[i].DataElements[j].static = null
                                    }
                                    return
                                } else if (DataElement.componentDataElements) {
                                    for (let k = 0; k < DataElement.componentDataElements.length; k++) {
                                        let componentDataElement = DataElement.componentDataElements[k];
                                        if (componentDataElement.element === this.chosenChip) {
                                            console.log(componentDataElement)
                                            this.segments[i].DataElements[j].componentDataElements[k].element.mappedValue = null;
                                            if (!Array.isArray(this.segments[i].DataElements[j].componentDataElements[k].element.static)) {
                                                this.segments[i].DataElements[j].componentDataElements[k].element.static = null
                                            }
                                            return
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        for (let y = 1; y < this.segments[i].length; y++) {
                            let subSegment = this.segments[i][y];
                            for (let x = 0; x < subSegment.DataElements.length; x++) {
                                let DataElement = subSegment.DataElements[x];
                                if (DataElement === this.chosenChip) {
                                    this.segments[i][y].DataElements[x].mappedValue = null;
                                    if (!Array.isArray(this.segments[i][y].DataElements[x].static)) {
                                        this.segments[i][y].DataElements[x].static = null
                                    }
                                    return
                                } else if (DataElement.componentDataElements) {
                                    for (let z = 0; z < DataElement.componentDataElements.length; z++) {
                                        let componentDataElement = DataElement.componentDataElements[z];
                                        if (componentDataElement.element === this.chosenChip) {
                                            this.segments[i][y].DataElements[x].componentDataElements[z].element.mappedValue = null;
                                            if (!Array.isArray(this.segments[i][y].DataElements[x].componentDataElements[z].element.static)) {
                                                this.segments[i][y].DataElements[x].componentDataElements[z].element.static = null
                                            }
                                            return
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        async markAsEnd() {
            this.mapDialog = false;
            let segment = this.currentSegment;
            for (let i = 0; i < this.segments.length; i++) {
                if (segment === this.segments[i]) {
                    if (!Array.isArray(segment)) {
                        if (segment.groupElements && segment.subGroups) {
                            console.log({
                                SubGroups: segment.subGroups
                            })
                            for (let a = 0; a < segment.subGroups.length; a++) {
                                let subGroup = segment.subGroups[a];

                                if (subGroup[0].subGroups) {
                                    console.log("SUB GROUPS HAS SUB GROUPS")
                                    for (let v = 0; v < subGroup[0].subGroups.length; v++) {
                                        let sub = subGroup[0].subGroups[v]
                                        console.log({
                                            sub
                                        })

                                        for (let s = 0; s < sub.length; s++) {
                                            let subItem = sub[s];

                                            if (subItem.subGroups) {
                                                for (let lastGroup of subItem.subGroups) {
                                                    let lastGroupIndex = this.segments[i].subGroups[a][0].subGroups[v][s].subGroups.indexOf(lastGroup)
                                                    // console.log({
                                                    //     lastGroup,
                                                    //     lastGroupIndex
                                                    // })
                                                    for (let lastSegment of lastGroup.slice(1)) {
                                                        let lastSegmentIndex = this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex].indexOf(lastSegment);
                                                        // console.log({lastSegment, lastSegmentIndex})
                                                        if (lastSegment.DataElements) {
                                                            console.log("SUB HAS DATA ELEMENTS")
                                                            for (let r = 0; r < lastSegment.DataElements.length; r++) {
                                                                let dataElement = lastSegment.DataElements[r]
                                                                if (dataElement === this.chosenChip) {
                                                                    console.log("TESTER", this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex])
                                                                    if (!this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].hide) {
                                                                        this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].hide = true;
                                                                        this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].inUse = false;
                                                                    } else {
                                                                        this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].hide = !this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].hide
                                                                        this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].inUse = !this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].inUse
                                                                    }

                                                                    return;
                                                                } else if (dataElement.componentDataElements) {
                                                                    console.log("CHECKING COMPONENT DATA ELEMENTS")
                                                                    for (let g = 0; g < dataElement.componentDataElements.length; g++) {
                                                                        let componentDataElement = dataElement.componentDataElements[g];
                                                                        if (componentDataElement.element === this.chosenChip) {
                                                                            console.log("COMPONENT EQUAL TO CHOSEN CHIP")
                                                                            if (!this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].componentDataElements[g].element.hide) {
                                                                                this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].componentDataElements[g].element.hide = true;
                                                                                this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].componentDataElements[g].element.inUse = false;
                                                                            } else {
                                                                                this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].componentDataElements[g].element.inUse = !this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].componentDataElements[g].element.inUse
                                                                                this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].componentDataElements[g].element.hide = !this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].componentDataElements[g].element.hide
                                                                            }
                                                                            return;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            if (subItem.DataElements) {
                                                console.log("SUB HAS DATA ELEMENTS")
                                                for (let r = 0; r < subItem.DataElements.length; r++) {
                                                    let dataElement = subItem.DataElements[r]
                                                    if (dataElement === this.chosenChip) {
                                                        console.log("TESTER", this.segments[i].subGroups[a][0].subGroups[v][s])
                                                        if (!this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].hide) {
                                                            this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].hide = true;
                                                            this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].inUse = false;
                                                        } else {
                                                            this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].hide = !this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].hide
                                                            this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].inUse = !this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].inUse
                                                        }
                                                        return;
                                                    } else if (dataElement.componentDataElements) {
                                                        console.log("CHECKING COMPONENT DATA ELEMENTS")
                                                        for (let g = 0; g < dataElement.componentDataElements.length; g++) {
                                                            let componentDataElement = dataElement.componentDataElements[g];
                                                            if (componentDataElement.element === this.chosenChip) {
                                                                console.log("COMPONENT EQUAL TO CHOSEN CHIP")
                                                                if (!this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].componentDataElements[g].element.hide) {
                                                                    this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].componentDataElements[g].element.inUse = false
                                                                    this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].componentDataElements[g].element.hide = true;
                                                                } else {
                                                                    this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].componentDataElements[g].element.inUse = !this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].componentDataElements[g].element.inUse
                                                                    this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].componentDataElements[g].element.hide = !this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].componentDataElements[g].element.hide
                                                                }
                                                                return;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                for (let index in subGroup) {
                                    let integerIndex = parseInt(index)
                                    let subSegment = subGroup[integerIndex]
                                    // console.log({
                                    //     integerIndex
                                    // })
                                    for (let x = 0; x < subSegment.DataElements.length; x++) {
                                        let DataElement = subSegment.DataElements[x];
                                        // console.log({
                                        //     integerIndex,
                                        //     DataElement,
                                        //     chosenChip: this.chosenChip
                                        // })
                                        if (DataElement === this.chosenChip) {
                                            // this.segments[i][integerIndex].DataElements[x].mappedValue = item
                                            if (!this.segments[i].subGroups[a][integerIndex].DataElements[x].hide) {
                                                this.segments[i].subGroups[a][integerIndex].DataElements[x].hide = true;
                                                this.segments[i].subGroups[a][integerIndex].DataElements[x].inUse = false
                                            } else {
                                                this.segments[i].subGroups[a][integerIndex].DataElements[x].inUse = !this.segments[i].subGroups[a][integerIndex].DataElements[x].inUse
                                                this.segments[i].subGroups[a][integerIndex].DataElements[x].hide = !this.segments[i].subGroups[a][integerIndex].DataElements[x].hide
                                            }
                                            return
                                        } else if (DataElement.componentDataElements) {
                                            for (let z = 0; z < DataElement.componentDataElements.length; z++) {
                                                let componentDataElement = DataElement.componentDataElements[z];
                                                if (componentDataElement.element === this.chosenChip) {
                                                    if (!this.segments[i].subGroups[a][integerIndex].DataElements[x].componentDataElements[z].element.hide) {
                                                        this.segments[i].subGroups[a][integerIndex].DataElements[x].componentDataElements[z].element.inUse = false
                                                        this.segments[i].subGroups[a][integerIndex].DataElements[x].componentDataElements[z].element.hide = true;
                                                    } else {
                                                        this.segments[i].subGroups[a][integerIndex].DataElements[x].componentDataElements[z].element.inUse = !this.segments[i].subGroups[a][integerIndex].DataElements[x].componentDataElements[z].element.inUse
                                                        this.segments[i].subGroups[a][integerIndex].DataElements[x].componentDataElements[z].element.hide = !this.segments[i].subGroups[a][integerIndex].DataElements[x].componentDataElements[z].element.hide
                                                    }
                                                    return
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            for (let o = 0; o < segment.groupElements.length; o++) {
                                let groupElement = segment.groupElements[o];
                                for (let p = 0; p < groupElement.DataElements.length; p++) {
                                    let DataElement = groupElement.DataElements[p];
                                    if (DataElement === this.chosenChip) {
                                        if (!this.segments[i].groupElements[o].DataElements[p].hide) {
                                            this.segments[i].groupElements[o].DataElements[p].inUse = false
                                            this.segments[i].groupElements[o].DataElements[p].hide = true;
                                        } else {
                                            this.segments[i].groupElements[o].DataElements[p].inUse = !this.segments[i].groupElements[o].DataElements[p].inUse
                                            this.segments[i].groupElements[o].DataElements[p].hide = !this.segments[i].groupElements[o].DataElements[p].hide
                                        }

                                        return;
                                    } else if (DataElement.componentDataElements) {
                                        for (let w = 0; w < DataElement.componentDataElements.length; w++) {
                                            let componentDataElement = DataElement.componentDataElements[w];
                                            if (componentDataElement.element === this.chosenChip) {
                                                if (!this.segments[i].groupElements[o].DataElements[p].componentDataElements[w].element.hide) {
                                                    this.segments[i].groupElements[o].DataElements[p].componentDataElements[w].element.inUse = false
                                                    this.segments[i].groupElements[o].DataElements[p].componentDataElements[w].element.hide = true
                                                } else {
                                                    this.segments[i].groupElements[o].DataElements[p].componentDataElements[w].element.inUse = !this.segments[i].groupElements[o].DataElements[p].componentDataElements[w].element.inUse
                                                    this.segments[i].groupElements[o].DataElements[p].componentDataElements[w].element.hide = !this.segments[i].groupElements[o].DataElements[p].componentDataElements[w].element.hide
                                                }
                                                return;
                                            }
                                        }
                                    }
                                }
                            }

                        } else {
                            for (let j = 0; j < segment.DataElements.length; j++) {
                                let DataElement = segment.DataElements[j];
                                if (DataElement === this.chosenChip) {
                                    if (!this.segments[i].DataElements[j].hide) {
                                        this.segments[i].DataElements[j].inUse = false
                                        this.segments[i].DataElements[j].hide = true;
                                    } else {
                                        this.segments[i].DataElements[j].inUse = !this.segments[i].DataElements[j].inUse
                                        this.segments[i].DataElements[j].hide = !this.segments[i].DataElements[j].hide
                                    }
                                    return
                                } else if (DataElement.componentDataElements) {
                                    for (let k = 0; k < DataElement.componentDataElements.length; k++) {
                                        let componentDataElement = DataElement.componentDataElements[k];
                                        if (componentDataElement.element === this.chosenChip) {
                                            console.log(componentDataElement)
                                            if (!this.segments[i].DataElements[j].componentDataElements[k].element.hide) {
                                                this.segments[i].DataElements[j].componentDataElements[k].element.inUse = false;
                                                this.segments[i].DataElements[j].componentDataElements[k].element.hide = true;
                                            } else {
                                                this.segments[i].DataElements[j].componentDataElements[k].element.inUse = !this.segments[i].DataElements[j].componentDataElements[k].element.inUse
                                                this.segments[i].DataElements[j].componentDataElements[k].element.hide = !this.segments[i].DataElements[j].componentDataElements[k].element.hide
                                            }
                                            return
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        for (let y = 1; y < this.segments[i].length; y++) {
                            let subSegment = this.segments[i][y];
                            for (let x = 0; x < subSegment.DataElements.length; x++) {
                                let DataElement = subSegment.DataElements[x];
                                if (DataElement === this.chosenChip) {
                                    if (!this.segments[i][y].DataElements[x].hide) {
                                        this.segments[i][y].DataElements[x].inUse = false
                                        this.segments[i][y].DataElements[x].hide = true;
                                    } else {
                                        this.segments[i][y].DataElements[x].inUse = !this.segments[i][y].DataElements[x].inUse
                                        this.segments[i][y].DataElements[x].hide = !this.segments[i][y].DataElements[x].hide
                                    }
                                    return
                                } else if (DataElement.componentDataElements) {
                                    for (let z = 0; z < DataElement.componentDataElements.length; z++) {
                                        let componentDataElement = DataElement.componentDataElements[z];
                                        if (componentDataElement.element === this.chosenChip) {
                                            if (!this.segments[i][y].DataElements[x].componentDataElements[z].element.hide) {
                                                this.segments[i][y].DataElements[x].componentDataElements[z].element.inUse = false
                                                this.segments[i][y].DataElements[x].componentDataElements[z].element.hide = true;
                                            } else {
                                                this.segments[i][y].DataElements[x].componentDataElements[z].element.inUse = !this.segments[i][y].DataElements[x].componentDataElements[z].element.inUse
                                                this.segments[i][y].DataElements[x].componentDataElements[z].element.hide = !this.segments[i][y].DataElements[x].componentDataElements[z].element.hide
                                            }
                                            return
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        async markNotInUse() {
            this.mapDialog = false;
            let segment = this.currentSegment;
            for (let i = 0; i < this.segments.length; i++) {
                if (segment === this.segments[i]) {
                    if (!Array.isArray(segment)) {
                        if (segment.groupElements && segment.subGroups) {
                            console.log({
                                SubGroups: segment.subGroups
                            })
                            for (let a = 0; a < segment.subGroups.length; a++) {
                                let subGroup = segment.subGroups[a];

                                if (subGroup[0].subGroups) {
                                    console.log("SUB GROUPS HAS SUB GROUPS")
                                    for (let v = 0; v < subGroup[0].subGroups.length; v++) {
                                        let sub = subGroup[0].subGroups[v]
                                        console.log({
                                            sub
                                        })

                                        for (let s = 0; s < sub.length; s++) {
                                            let subItem = sub[s];

                                            if (subItem.subGroups) {
                                                for (let lastGroup of subItem.subGroups) {
                                                    let lastGroupIndex = this.segments[i].subGroups[a][0].subGroups[v][s].subGroups.indexOf(lastGroup)
                                                    // console.log({
                                                    //     lastGroup,
                                                    //     lastGroupIndex
                                                    // })
                                                    for (let lastSegment of lastGroup.slice(1)) {
                                                        let lastSegmentIndex = this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex].indexOf(lastSegment);
                                                        // console.log({lastSegment, lastSegmentIndex})
                                                        if (lastSegment.DataElements) {
                                                            console.log("SUB HAS DATA ELEMENTS")
                                                            for (let r = 0; r < lastSegment.DataElements.length; r++) {
                                                                let dataElement = lastSegment.DataElements[r]
                                                                if (dataElement === this.chosenChip) {
                                                                    console.log("TESTER", this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex])
                                                                    this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].inUse = !this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].inUse
                                                                    return;
                                                                } else if (dataElement.componentDataElements) {
                                                                    console.log("CHECKING COMPONENT DATA ELEMENTS")
                                                                    for (let g = 0; g < dataElement.componentDataElements.length; g++) {
                                                                        let componentDataElement = dataElement.componentDataElements[g];
                                                                        if (componentDataElement.element === this.chosenChip) {
                                                                            console.log("COMPONENT EQUAL TO CHOSEN CHIP")
                                                                            this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].componentDataElements[g].element.inUse = !this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].componentDataElements[g].element.inUse
                                                                            return;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            if (subItem.DataElements) {
                                                console.log("SUB HAS DATA ELEMENTS")
                                                for (let r = 0; r < subItem.DataElements.length; r++) {
                                                    let dataElement = subItem.DataElements[r]
                                                    if (dataElement === this.chosenChip) {
                                                        console.log("TESTER", this.segments[i].subGroups[a][0].subGroups[v][s])
                                                        this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].inUse = !this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].inUse
                                                        return;
                                                    } else if (dataElement.componentDataElements) {
                                                        console.log("CHECKING COMPONENT DATA ELEMENTS")
                                                        for (let g = 0; g < dataElement.componentDataElements.length; g++) {
                                                            let componentDataElement = dataElement.componentDataElements[g];
                                                            if (componentDataElement.element === this.chosenChip) {
                                                                console.log("COMPONENT EQUAL TO CHOSEN CHIP")
                                                                this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].componentDataElements[g].element.inUse = !this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].componentDataElements[g].element.inUse
                                                                return;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                for (let index in subGroup) {
                                    let integerIndex = parseInt(index)
                                    let subSegment = subGroup[integerIndex]
                                    // console.log({
                                    //     integerIndex
                                    // })
                                    for (let x = 0; x < subSegment.DataElements.length; x++) {
                                        let DataElement = subSegment.DataElements[x];
                                        // console.log({
                                        //     integerIndex,
                                        //     DataElement,
                                        //     chosenChip: this.chosenChip
                                        // })
                                        if (DataElement === this.chosenChip) {
                                            // this.segments[i][integerIndex].DataElements[x].mappedValue = item
                                            this.segments[i].subGroups[a][integerIndex].DataElements[x].inUse = !this.segments[i].subGroups[a][integerIndex].DataElements[x].inUse
                                            return
                                        } else if (DataElement.componentDataElements) {
                                            for (let z = 0; z < DataElement.componentDataElements.length; z++) {
                                                let componentDataElement = DataElement.componentDataElements[z];
                                                if (componentDataElement.element === this.chosenChip) {
                                                    this.segments[i].subGroups[a][integerIndex].DataElements[x].componentDataElements[z].element.inUse = !this.segments[i].subGroups[a][integerIndex].DataElements[x].componentDataElements[z].element.inUse
                                                    return
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            for (let o = 0; o < segment.groupElements.length; o++) {
                                let groupElement = segment.groupElements[o];
                                for (let p = 0; p < groupElement.DataElements.length; p++) {
                                    let DataElement = groupElement.DataElements[p];
                                    if (DataElement === this.chosenChip) {
                                        this.segments[i].groupElements[o].DataElements[p].inUse = !this.segments[i].groupElements[o].DataElements[p].inUse
                                        return;
                                    } else if (DataElement.componentDataElements) {
                                        for (let w = 0; w < DataElement.componentDataElements.length; w++) {
                                            let componentDataElement = DataElement.componentDataElements[w];
                                            if (componentDataElement.element === this.chosenChip) {
                                                this.segments[i].groupElements[o].DataElements[p].componentDataElements[w].element.inUse = !this.segments[i].groupElements[o].DataElements[p].componentDataElements[w].element.inUse
                                                return;
                                            }
                                        }
                                    }
                                }
                            }

                        } else {
                            for (let j = 0; j < segment.DataElements.length; j++) {
                                let DataElement = segment.DataElements[j];
                                if (DataElement === this.chosenChip) {
                                    this.segments[i].DataElements[j].inUse = !this.segments[i].DataElements[j].inUse
                                    return
                                } else if (DataElement.componentDataElements) {
                                    for (let k = 0; k < DataElement.componentDataElements.length; k++) {
                                        let componentDataElement = DataElement.componentDataElements[k];
                                        if (componentDataElement.element === this.chosenChip) {
                                            console.log(componentDataElement)
                                            this.segments[i].DataElements[j].componentDataElements[k].element.inUse = !this.segments[i].DataElements[j].componentDataElements[k].element.inUse
                                            return
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        for (let y = 1; y < this.segments[i].length; y++) {
                            let subSegment = this.segments[i][y];
                            for (let x = 0; x < subSegment.DataElements.length; x++) {
                                let DataElement = subSegment.DataElements[x];
                                if (DataElement === this.chosenChip) {
                                    this.segments[i][y].DataElements[x].inUse = !this.segments[i][y].DataElements[x].inUse
                                    return
                                } else if (DataElement.componentDataElements) {
                                    for (let z = 0; z < DataElement.componentDataElements.length; z++) {
                                        let componentDataElement = DataElement.componentDataElements[z];
                                        if (componentDataElement.element === this.chosenChip) {
                                            this.segments[i][y].DataElements[x].componentDataElements[z].element.inUse = !this.segments[i][y].DataElements[x].componentDataElements[z].element.inUse
                                            return
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        async useStatic() {
            this.mapDialog = false;
            let segment = this.currentSegment;
            for (let i = 0; i < this.segments.length; i++) {
                if (segment === this.segments[i]) {
                    if (!Array.isArray(segment)) {
                        if (segment.groupElements && segment.subGroups) {
                            console.log({
                                SubGroups: segment.subGroups
                            })
                            for (let a = 0; a < segment.subGroups.length; a++) {
                                let subGroup = segment.subGroups[a];

                                if (subGroup[0].subGroups) {
                                    console.log("SUB GROUPS HAS SUB GROUPS")
                                    for (let v = 0; v < subGroup[0].subGroups.length; v++) {
                                        let sub = subGroup[0].subGroups[v]
                                        console.log({
                                            sub
                                        })
                                        for (let s = 0; s < sub.length; s++) {
                                            let subItem = sub[s];
                                            if (subItem.DataElements) {
                                                console.log("SUB HAS DATA ELEMENTS")
                                                for (let r = 0; r < subItem.DataElements.length; r++) {
                                                    let dataElement = subItem.DataElements[r]
                                                    if (dataElement === this.chosenChip) {
                                                        console.log("TESTER", this.segments[i].subGroups[a][0].subGroups[v][s])
                                                        this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].mappedValue = this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].static.value;
                                                        return;
                                                    } else if (dataElement.componentDataElements) {
                                                        console.log("CHECKING COMPONENT DATA ELEMENTS")
                                                        for (let g = 0; g < dataElement.componentDataElements.length; g++) {
                                                            let componentDataElement = dataElement.componentDataElements[g];
                                                            if (componentDataElement.element === this.chosenChip) {
                                                                console.log("COMPONENT EQUAL TO CHOSEN CHIP")
                                                                this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].componentDataElements[g].element.mappedValue = this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].componentDataElements[g].element.static.value;
                                                                return;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                for (let index in subGroup) {
                                    let integerIndex = parseInt(index)
                                    let subSegment = subGroup[integerIndex]
                                    // console.log({
                                    //     integerIndex
                                    // })
                                    for (let x = 0; x < subSegment.DataElements.length; x++) {
                                        let DataElement = subSegment.DataElements[x];
                                        // console.log({
                                        //     integerIndex,
                                        //     DataElement,
                                        //     chosenChip: this.chosenChip
                                        // })
                                        if (DataElement === this.chosenChip) {
                                            // this.segments[i][integerIndex].DataElements[x].mappedValue = item
                                            this.segments[i].subGroups[a][integerIndex].DataElements[x].mappedValue = this.segments[i].subGroups[a][integerIndex].DataElements[x].static.value
                                            return
                                        } else if (DataElement.componentDataElements) {
                                            for (let z = 0; z < DataElement.componentDataElements.length; z++) {
                                                let componentDataElement = DataElement.componentDataElements[z];
                                                if (componentDataElement.element === this.chosenChip) {
                                                    this.segments[i].subGroups[a][integerIndex].DataElements[x].componentDataElements[z].element.mappedValue = this.segments[i].subGroups[a][integerIndex].DataElements[x].componentDataElements[z].element.static.value
                                                    return
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            for (let o = 0; o < segment.groupElements.length; o++) {
                                let groupElement = segment.groupElements[o];
                                for (let p = 0; p < groupElement.DataElements.length; p++) {
                                    let DataElement = groupElement.DataElements[p];
                                    if (DataElement === this.chosenChip) {
                                        this.segments[i].groupElements[o].DataElements[p].mappedValue = this.segments[i].groupElements[o].DataElements[p].mappedValue.static.value
                                        return;
                                    } else if (DataElement.componentDataElements) {
                                        for (let w = 0; w < DataElement.componentDataElements.length; w++) {
                                            let componentDataElement = DataElement.componentDataElements[w];
                                            if (componentDataElement.element === this.chosenChip) {
                                                this.segments[i].groupElements[o].DataElements[p].componentDataElements[w].element.mappedValue = this.segments[i].groupElements[o].DataElements[p].componentDataElements[w].element.static.value
                                                return;
                                            }
                                        }
                                    }
                                }
                            }

                        } else {
                            for (let j = 0; j < segment.DataElements.length; j++) {
                                let DataElement = segment.DataElements[j];
                                if (DataElement === this.chosenChip) {
                                    this.segments[i].DataElements[j].mappedValue = this.segments[i].DataElements[j].static.value
                                    return
                                } else if (DataElement.componentDataElements) {
                                    for (let k = 0; k < DataElement.componentDataElements.length; k++) {
                                        let componentDataElement = DataElement.componentDataElements[k];
                                        if (componentDataElement.element === this.chosenChip) {
                                            console.log(componentDataElement)
                                            this.segments[i].DataElements[j].componentDataElements[k].element.mappedValue = this.segments[i].DataElements[j].componentDataElements[k].element.static.value
                                            return
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        for (let y = 1; y < this.segments[i].length; y++) {
                            let subSegment = this.segments[i][y];
                            for (let x = 0; x < subSegment.DataElements.length; x++) {
                                let DataElement = subSegment.DataElements[x];
                                if (DataElement === this.chosenChip) {
                                    this.segments[i][y].DataElements[x].mappedValue = this.segments[i][y].DataElements[x].static.value
                                    return
                                } else if (DataElement.componentDataElements) {
                                    for (let z = 0; z < DataElement.componentDataElements.length; z++) {
                                        let componentDataElement = DataElement.componentDataElements[z];
                                        if (componentDataElement.element === this.chosenChip) {
                                            this.segments[i][y].DataElements[x].componentDataElements[z].element.mappedValue = this.segments[i][y].DataElements[x].componentDataElements[z].static.value
                                            return
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        async setValueForMapping(item) {
            this.selectedItemToMap = item.path
        },
        async addKVP(item) {
            this.selectedKVPMatches.push({
                staticName: item.name,
                staticValue: item.value,
                mappedValue: this.selectedItemToMap
            })
            console.log(this.selectedKVPMatches)
        },
        async removeKVP(item) {
            for (let i = 0; i < this.selectedKVPMatches.length; i++) {
                if (this.selectedKVPMatches[i].staticValue === item.value && this.selectedKVPMatches[i].staticName === item.name) {
                    this.selectedKVPMatches.splice(i, 1)
                    break
                }
            }
        },
        async saveComments(comments) {
            console.log(comments)
            this.commentsLoading = true
            let segment = this.currentSegment;
            for (let i = 0; i < this.segments.length; i++) {
                if (segment === this.segments[i]) {
                    if (!Array.isArray(segment)) {
                        if (segment.groupElements && segment.subGroups) {
                            console.log({
                                SubGroups: segment.subGroups
                            })
                            for (let a = 0; a < segment.subGroups.length; a++) {
                                let subGroup = segment.subGroups[a];

                                if (subGroup[0].subGroups) {
                                    console.log("SUB GROUPS HAS SUB GROUPS")
                                    for (let v = 0; v < subGroup[0].subGroups.length; v++) {
                                        let sub = subGroup[0].subGroups[v]
                                        console.log({
                                            sub
                                        })
                                        for (let s = 0; s < sub.length; s++) {
                                            let subItem = sub[s];

                                            if (subItem.subGroups) {
                                                for (let lastGroup of subItem.subGroups) {
                                                    let lastGroupIndex = this.segments[i].subGroups[a][0].subGroups[v][s].subGroups.indexOf(lastGroup)
                                                    // console.log({
                                                    //     lastGroup,
                                                    //     lastGroupIndex
                                                    // })
                                                    for (let lastSegment of lastGroup.slice(1)) {
                                                        let lastSegmentIndex = this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex].indexOf(lastSegment);
                                                        // console.log({lastSegment, lastSegmentIndex})
                                                        if (lastSegment.DataElements) {
                                                            console.log("SUB HAS DATA ELEMENTS++++++++")
                                                            for (let r = 0; r < lastSegment.DataElements.length; r++) {
                                                                let dataElement = lastSegment.DataElements[r]
                                                                if (dataElement === this.chosenChip) {
                                                                    console.log("TESTER++++++", this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex])
                                                                    this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].comments = comments
                                                                    this.comment = null;
                                                                    this.commentsLoading = false
                                                                    return;
                                                                } else if (dataElement.componentDataElements) {
                                                                    console.log("CHECKING COMPONENT DATA ELEMENTS+++++++")
                                                                    for (let g = 0; g < dataElement.componentDataElements.length; g++) {
                                                                        let componentDataElement = dataElement.componentDataElements[g];
                                                                        if (componentDataElement.element === this.chosenChip) {
                                                                            console.log("COMPONENT EQUAL TO CHOSEN CHIP++++++")
                                                                            this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].componentDataElements[g].element.comments = comments
                                                                            this.comment = null;
                                                                            this.commentsLoading = false
                                                                            return;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            if (subItem.DataElements) {
                                                console.log("SUB HAS DATA ELEMENTS")
                                                for (let r = 0; r < subItem.DataElements.length; r++) {
                                                    let dataElement = subItem.DataElements[r]
                                                    if (dataElement === this.chosenChip) {
                                                        console.log("TESTER", this.segments[i].subGroups[a][0].subGroups[v][s])
                                                        this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].comments = comments

                                                        this.comment = null;
                                                        this.commentsLoading = false
                                                        return;
                                                    } else if (dataElement.componentDataElements) {
                                                        console.log("CHECKING COMPONENT DATA ELEMENTS")
                                                        for (let g = 0; g < dataElement.componentDataElements.length; g++) {
                                                            let componentDataElement = dataElement.componentDataElements[g];
                                                            if (componentDataElement.element === this.chosenChip) {
                                                                console.log("COMPONENT EQUAL TO CHOSEN CHIP")
                                                                this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].componentDataElements[g].element.comments = comments

                                                                this.comment = null;
                                                                this.commentsLoading = false
                                                                return;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                for (let index in subGroup) {
                                    let integerIndex = parseInt(index)
                                    let subSegment = subGroup[integerIndex]
                                    // console.log({
                                    //     integerIndex
                                    // })
                                    for (let x = 0; x < subSegment.DataElements.length; x++) {
                                        let DataElement = subSegment.DataElements[x];
                                        // console.log({
                                        //     integerIndex,
                                        //     DataElement,
                                        //     chosenChip: this.chosenChip
                                        // })
                                        if (DataElement === this.chosenChip) {
                                            // this.segments[i][integerIndex].DataElements[x].mappedValue = item
                                            this.segments[i].subGroups[a][integerIndex].DataElements[x].comments = comments

                                            this.comment = null;
                                            this.commentsLoading = false
                                            return
                                        } else if (DataElement.componentDataElements) {
                                            for (let z = 0; z < DataElement.componentDataElements.length; z++) {
                                                let componentDataElement = DataElement.componentDataElements[z];
                                                if (componentDataElement.element === this.chosenChip) {
                                                    this.segments[i].subGroups[a][integerIndex].DataElements[x].componentDataElements[z].element.comments = comments

                                                    this.comment = null;
                                                    this.commentsLoading = false
                                                    return
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            for (let o = 0; o < segment.groupElements.length; o++) {
                                let groupElement = segment.groupElements[o];
                                for (let p = 0; p < groupElement.DataElements.length; p++) {
                                    let DataElement = groupElement.DataElements[p];
                                    if (DataElement === this.chosenChip) {
                                        this.segments[i].groupElements[o].DataElements[p].comments = comments

                                        this.comment = null;
                                        this.commentsLoading = false
                                        return;
                                    } else if (DataElement.componentDataElements) {
                                        for (let w = 0; w < DataElement.componentDataElements.length; w++) {
                                            let componentDataElement = DataElement.componentDataElements[w];
                                            if (componentDataElement.element === this.chosenChip) {
                                                this.segments[i].groupElements[o].DataElements[p].componentDataElements[w].element.comments = comments

                                                this.comment = null;
                                                this.commentsLoading = false
                                                return;
                                            }
                                        }
                                    }
                                }
                            }

                        } else {
                            for (let j = 0; j < segment.DataElements.length; j++) {
                                let DataElement = segment.DataElements[j];
                                if (DataElement === this.chosenChip) {
                                    this.segments[i].DataElements[j].comments = comments
                                    this.comment = null;
                                    this.commentsLoading = false
                                    return
                                } else if (DataElement.componentDataElements) {
                                    for (let k = 0; k < DataElement.componentDataElements.length; k++) {
                                        let componentDataElement = DataElement.componentDataElements[k];
                                        if (componentDataElement.element === this.chosenChip) {
                                            console.log({
                                                chosenChip: this.chosenChip,
                                                componentDataElement: componentDataElement.element
                                            })
                                            this.segments[i].DataElements[j].componentDataElements[k].element.comments = comments
                                            this.comment = null;
                                            this.commentsLoading = false
                                            return
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        for (let y = 1; y < this.segments[i].length; y++) {
                            let subSegment = this.segments[i][y];
                            for (let x = 0; x < subSegment.DataElements.length; x++) {
                                let DataElement = subSegment.DataElements[x];
                                if (DataElement === this.chosenChip) {
                                    this.segments[i][y].DataElements[x].comments = comments
                                    return
                                } else if (DataElement.componentDataElements) {
                                    for (let z = 0; z < DataElement.componentDataElements.length; z++) {
                                        let componentDataElement = DataElement.componentDataElements[z];
                                        if (componentDataElement.element === this.chosenChip) {
                                            this.segments[i][y].DataElements[x].componentDataElements[z].element.comments = comments
                                            this.comment = null;
                                            this.commentsLoading = false
                                            return
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        async mapToJSON(item) {
            this.mapDialog = false;
            this.selectedItem = null
            let segment = this.currentSegment;
            for (let i = 0; i < this.segments.length; i++) {
                if (segment === this.segments[i]) {
                    if (!Array.isArray(segment)) {
                        if (segment.groupElements && segment.subGroups) {
                            console.log({
                                SubGroups: segment.subGroups
                            })
                            for (let a = 0; a < segment.subGroups.length; a++) {
                                let subGroup = segment.subGroups[a];

                                if (subGroup[0].subGroups) {
                                    console.log("SUB GROUPS HAS SUB GROUPS")
                                    for (let v = 0; v < subGroup[0].subGroups.length; v++) {
                                        let sub = subGroup[0].subGroups[v]
                                        console.log({
                                            sub
                                        })
                                        for (let s = 0; s < sub.length; s++) {
                                            let subItem = sub[s];

                                            if (subItem.subGroups) {
                                                for (let lastGroup of subItem.subGroups) {
                                                    let lastGroupIndex = this.segments[i].subGroups[a][0].subGroups[v][s].subGroups.indexOf(lastGroup)
                                                    // console.log({
                                                    //     lastGroup,
                                                    //     lastGroupIndex
                                                    // })
                                                    for (let lastSegment of lastGroup.slice(1)) {
                                                        let lastSegmentIndex = this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex].indexOf(lastSegment);
                                                        // console.log({lastSegment, lastSegmentIndex})
                                                        if (lastSegment.DataElements) {
                                                            console.log("SUB HAS DATA ELEMENTS")
                                                            for (let r = 0; r < lastSegment.DataElements.length; r++) {
                                                                let dataElement = lastSegment.DataElements[r]
                                                                if (dataElement === this.chosenChip) {
                                                                    console.log("TESTER", this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex])
                                                                    this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].mappedValue = item
                                                                    return;
                                                                } else if (dataElement.componentDataElements) {
                                                                    console.log("CHECKING COMPONENT DATA ELEMENTS")
                                                                    for (let g = 0; g < dataElement.componentDataElements.length; g++) {
                                                                        let componentDataElement = dataElement.componentDataElements[g];
                                                                        if (componentDataElement.element === this.chosenChip) {
                                                                            console.log("COMPONENT EQUAL TO CHOSEN CHIP")
                                                                            this.segments[i].subGroups[a][0].subGroups[v][s].subGroups[lastGroupIndex][lastSegmentIndex].DataElements[r].componentDataElements[g].element.mappedValue = item
                                                                            return;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            if (subItem.DataElements) {
                                                console.log("SUB HAS DATA ELEMENTS")
                                                for (let r = 0; r < subItem.DataElements.length; r++) {
                                                    let dataElement = subItem.DataElements[r]
                                                    if (dataElement === this.chosenChip) {
                                                        console.log("TESTER", this.segments[i].subGroups[a][0].subGroups[v][s])
                                                        this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].mappedValue = item;
                                                        return;
                                                    } else if (dataElement.componentDataElements) {
                                                        console.log("CHECKING COMPONENT DATA ELEMENTS")
                                                        for (let g = 0; g < dataElement.componentDataElements.length; g++) {
                                                            let componentDataElement = dataElement.componentDataElements[g];
                                                            if (componentDataElement.element === this.chosenChip) {
                                                                console.log("COMPONENT EQUAL TO CHOSEN CHIP")
                                                                this.segments[i].subGroups[a][0].subGroups[v][s].DataElements[r].componentDataElements[g].element.mappedValue = item;
                                                                return;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                for (let index in subGroup) {
                                    let integerIndex = parseInt(index)
                                    let subSegment = subGroup[integerIndex]
                                    // console.log({
                                    //     integerIndex
                                    // })
                                    for (let x = 0; x < subSegment.DataElements.length; x++) {
                                        let DataElement = subSegment.DataElements[x];
                                        // console.log({
                                        //     integerIndex,
                                        //     DataElement,
                                        //     chosenChip: this.chosenChip
                                        // })
                                        if (DataElement === this.chosenChip) {
                                            // this.segments[i][integerIndex].DataElements[x].mappedValue = item
                                            this.segments[i].subGroups[a][integerIndex].DataElements[x].mappedValue = item
                                            return
                                        } else if (DataElement.componentDataElements) {
                                            for (let z = 0; z < DataElement.componentDataElements.length; z++) {
                                                let componentDataElement = DataElement.componentDataElements[z];
                                                if (componentDataElement.element === this.chosenChip) {
                                                    this.segments[i].subGroups[a][integerIndex].DataElements[x].componentDataElements[z].element.mappedValue = item
                                                    return
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            for (let o = 0; o < segment.groupElements.length; o++) {
                                let groupElement = segment.groupElements[o];
                                for (let p = 0; p < groupElement.DataElements.length; p++) {
                                    let DataElement = groupElement.DataElements[p];
                                    if (DataElement === this.chosenChip) {
                                        this.segments[i].groupElements[o].DataElements[p].mappedValue = item
                                        return;
                                    } else if (DataElement.componentDataElements) {
                                        for (let w = 0; w < DataElement.componentDataElements.length; w++) {
                                            let componentDataElement = DataElement.componentDataElements[w];
                                            if (componentDataElement.element === this.chosenChip) {
                                                this.segments[i].groupElements[o].DataElements[p].componentDataElements[w].element.mappedValue = item
                                                return;
                                            }
                                        }
                                    }
                                }
                            }

                        } else {
                            for (let j = 0; j < segment.DataElements.length; j++) {
                                let DataElement = segment.DataElements[j];
                                if (DataElement === this.chosenChip) {
                                    this.segments[i].DataElements[j].mappedValue = item
                                    return
                                } else if (DataElement.componentDataElements) {
                                    for (let k = 0; k < DataElement.componentDataElements.length; k++) {
                                        let componentDataElement = DataElement.componentDataElements[k];
                                        if (componentDataElement.element === this.chosenChip) {
                                            this.segments[i].DataElements[j].componentDataElements[k].element.mappedValue = item
                                            return
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        for (let y = 1; y < this.segments[i].length; y++) {
                            let subSegment = this.segments[i][y];
                            for (let x = 0; x < subSegment.DataElements.length; x++) {
                                let DataElement = subSegment.DataElements[x];
                                if (DataElement === this.chosenChip) {
                                    this.segments[i][y].DataElements[x].mappedValue = item
                                    return
                                } else if (DataElement.componentDataElements) {
                                    for (let z = 0; z < DataElement.componentDataElements.length; z++) {
                                        let componentDataElement = DataElement.componentDataElements[z];
                                        if (componentDataElement.element === this.chosenChip) {
                                            this.segments[i][y].DataElements[x].componentDataElements[z].element.mappedValue = item
                                            return
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        getDataDisplay(value) {
            if (Array.isArray(value)) {
                return "Array"
            } else if (value !== null && typeof value === 'object') {
                return "Object"
            } else {
                return value
            }
        },
        async beginMapping(element, segment) {
            if (!element.componentDataElements) {
                this.mapDialog = true
                this.chosenChip = element
                this.currentSegment = segment
                this.comment = this.chosenChip.comments
                this.endTag = this.chosenChip.endTag
                console.log({
                    chosenChip: this.chosenChip
                })
            }
        },
        async loadFileData() {
            this.loadJSONDialog = false
            const {
                groupedKeys,
                groupNames
            } = await this.$API.fetchJSONData({
                fileName: this.chosenJSONOption
            });
            this.fileJSON = groupedKeys
            this.tabs = groupNames
            console.log(this.fileJSON)
        },
        async fetchFileTemplates() {
            let params = {}
            params.limit = this.params.limit
            params.offset = this.params.offset
            if (this.buildSearch && this.buildSearch.length > 0) {
                params.search = this.buildSearch
            }
            let response = await this.$API.fetchFileTemplates(params);
            this.templateOptions = response.paginatedFileList;
            this.copyOptions = response.filteredFirstFileList
            this.total = response.total;
            console.log(this.templateOptions)
        },
        async fetchJSONOptions() {
            this.jsonOptions = await this.$API.fetchJSONOptions();
        },
        async fetchFileData() {
            this.chooseBuildDialog = false
            this.loading = true;
            let response = await this.$API.fetchFileData({
                fileName: this.chosenTemplateName
            });
            if (response) {
                this.loading = false;
            } else {
                this.$message({
                    type: 'error',
                    message: 'There was an issue loading the selected template. Try again or fix your damn code Ross!!!!!!!!!!!!!!'
                })
                this.loading = false;
            }
            this.segments = response;
            console.log({
                segments: this.segments
            })
        },
        async fetchIncompleteData() {
            this.incompleteTemplatesDialog = false
            this.loading = true;
            let response = await this.$API.fetchIncompleteData({
                fileName: this.chosenIncompleteName
            });
            if (response) {
                this.loading = false;
            } else {
                this.$message({
                    type: 'error',
                    message: 'There was an issue loading the selected template. Try again or fix your damn code Ross!!!!!!!!!!!!!!'
                })
                this.loading = false;
            }
            this.segments = response;
            console.log({
                segments: this.segments
            })
        },
        async addSubOfSubGroupElementRepeat(subGroup, storage, dataGroup, data) {
            let newRepeat = parseInt(data.repeat) - 1;
            if (this.segments.indexOf(storage) !== -1) {
                console.log("SUB REF INDEX FOUND!!!");
                let refIndex = this.segments.indexOf(storage);
                if (this.segments[refIndex].subGroups.indexOf(subGroup) !== -1) {
                    console.log("SUBGROUP INDEX FOUND!!!")
                    let subGroupIndex = this.segments[refIndex].subGroups.indexOf(subGroup);
                    if (this.segments[refIndex].subGroups[subGroupIndex][0].subGroups.indexOf(dataGroup) !== -1) {
                        console.log("INDEX OF SUB OF SUBGROUP FOUND!!!")
                        let subIndex = this.segments[refIndex].subGroups[subGroupIndex][0].subGroups.indexOf(dataGroup);
                        if (this.segments[refIndex].subGroups[subGroupIndex][0].subGroups[subIndex].indexOf(data) !== -1) {
                            console.log("ITEM INDEX OF SUB FOUND!!!");
                            let itemIndex = this.segments[refIndex].subGroups[subGroupIndex][0].subGroups[subIndex].indexOf(data)
                            this.segments[refIndex].subGroups[subGroupIndex][0].subGroups[subIndex][itemIndex].repeat = newRepeat.toString()
                            this.segmentKey++;
                            const newObj = JSON.parse(JSON.stringify(data));
                            newObj.position += `.${this.segmentKey}`
                            this.segments[refIndex].subGroups[subGroupIndex][0].subGroups[subIndex].splice(itemIndex + 1, 0, newObj)
                            this.btnLoader = false
                        }
                    }
                }
            }
        },
        async removeSubOfSubGroupElementRepeat(subGroup, storage, dataGroup, data) {
            if (this.segments.indexOf(storage) !== -1) {
                console.log("REF INDEX REMOVAL FOUND!!!");
                let refIndex = this.segments.indexOf(storage);
                if (this.segments[refIndex].subGroups.indexOf(subGroup) !== -1) {
                    console.log("SUBGROUP INDEX REMOVAL FOUND!!!")
                    let subGroupIndex = this.segments[refIndex].subGroups.indexOf(subGroup)
                    if (this.segments[refIndex].subGroups[subGroupIndex][0].subGroups.indexOf(dataGroup) !== -1) {
                        console.log("DATAGROUP INDEX REMOVAL FOUND!!!");
                        let dataGroupIndex = this.segments[refIndex].subGroups[subGroupIndex][0].subGroups.indexOf(dataGroup);
                        if (this.segments[refIndex].subGroups[subGroupIndex][0].subGroups[dataGroupIndex].indexOf(data)) {
                            console.log({
                                data
                            })
                            console.log("REMOVAL WORKING ROSSSS")
                            let dataIndex = this.segments[refIndex].subGroups[subGroupIndex][0].subGroups[dataGroupIndex].indexOf(data)

                            this.segments[refIndex].subGroups[subGroupIndex][0].subGroups[dataGroupIndex].splice(dataIndex, 1)

                            let originalData = data;
                            originalData.position = data.position.slice(0, data.position.indexOf("."))
                            const arrayToSearch = this.segments[refIndex].subGroups[subGroupIndex][0].subGroups[dataGroupIndex]

                            const indices = [];
                            for (let i = 0; i < arrayToSearch.length; i++) {
                                if (arrayToSearch[i].position === originalData.position) {
                                    indices.push(i);
                                }
                            }

                            for (let index of indices) {
                                let newRepeat = parseInt(this.segments[refIndex].subGroups[subGroupIndex][0].subGroups[dataGroupIndex][index].repeat) + 1
                                this.segments[refIndex].subGroups[subGroupIndex][0].subGroups[dataGroupIndex][index].repeat = newRepeat.toString();
                            }

                            this.segmentKey++;
                            this.btnLoader = false
                        }
                    }
                }
            }
            // if (this.segments.indexOf(storage) !== -1) {
            //     console.log("REF INDEX REMOVAL FOUND")
            //     let refIndex = this.segments.indexOf(storage);
            //     if (this.segments[refIndex].subGroups.indexOf(subGroup) !== -1) {
            //         console.log("SUBGROUP INDEX REMOVAL FOUND")
            //         let subGroupIndex = this.segments[refIndex].subGroups.indexOf(subGroup);
            //         if (this.segments[refIndex].subGroups[subGroupIndex].indexOf(data) !== -1) {
            //             console.log({
            //                 data
            //             })
            // console.log("REMOVAL WORKING ROSSSS")
            // // let newRepeat = parseInt(data.repeat) + 1
            // let dataIndex = this.segments[refIndex].subGroups[subGroupIndex].indexOf(data);

            // this.segments[refIndex].subGroups[subGroupIndex].splice(dataIndex, 1)

            // let originalData = data;
            // originalData.position = data.position.slice(0, data.position.indexOf("."))
            // // let originalDataIndex = this.segments[refIndex].subGroups[subGroupIndex].indexOf(originalData);
            // // console.log({ tester: this.segments[refIndex].subGroups[subGroupIndex], originalData })
            // // this.segments[refIndex].subGroups[subGroupIndex][originalDataIndex - 1].repeat = newRepeat.toString();
            // const arrayToSearch = this.segments[refIndex].subGroups[subGroupIndex];

            // const indices = [];
            // for (let i = 0; i < arrayToSearch.length; i++) {
            //     if (arrayToSearch[i].position === originalData.position) {
            //         indices.push(i);
            //     }
            // }

            // for (let index of indices) {
            //     let newRepeat = parseInt(this.segments[refIndex].subGroups[subGroupIndex][index].repeat) + 1
            //     this.segments[refIndex].subGroups[subGroupIndex][index].repeat = newRepeat.toString();
            // }

            // this.segmentKey++;
            // this.btnLoader = false
            //         }
            //     }
            // } else {
            //     console.log("ERRRR")
            //     this.btnLoader = false
            // }
        },
        async addSubGroupElementRepeat(data, subGroup, storage) {
            let newRepeat = parseInt(data.repeat) - 1
            if (this.segments.indexOf(storage) !== -1) {
                console.log("REF INDEX FOUND")
                let refIndex = this.segments.indexOf(storage);
                if (this.segments[refIndex].subGroups.indexOf(subGroup) !== -1) {
                    console.log("SUBGROUP INDEX FOUND")
                    let subGroupIndex = this.segments[refIndex].subGroups.indexOf(subGroup);
                    if (this.segments[refIndex].subGroups[subGroupIndex].indexOf(data) !== -1) {
                        console.log("WORKING ROSSSS")
                        let dataIndex = this.segments[refIndex].subGroups[subGroupIndex].indexOf(data)
                        this.segments[refIndex].subGroups[subGroupIndex][dataIndex].repeat = newRepeat.toString()
                        this.segmentKey++;
                        const newObj = JSON.parse(JSON.stringify(data));
                        newObj.position += `.${this.segmentKey}`
                        this.segments[refIndex].subGroups[subGroupIndex].splice(dataIndex + 1, 0, newObj)
                        this.btnLoader = false
                    }
                }
            } else {
                console.log("ERRRR")
                this.btnLoader = false
            }
        },
        async removeSubGroupElementRepeat(data, subGroup, storage) {
            if (this.segments.indexOf(storage) !== -1) {
                console.log("REF INDEX REMOVAL FOUND")
                let refIndex = this.segments.indexOf(storage);
                if (this.segments[refIndex].subGroups.indexOf(subGroup) !== -1) {
                    console.log("SUBGROUP INDEX REMOVAL FOUND")
                    let subGroupIndex = this.segments[refIndex].subGroups.indexOf(subGroup);
                    if (this.segments[refIndex].subGroups[subGroupIndex].indexOf(data) !== -1) {
                        console.log({
                            data
                        })
                        console.log("REMOVAL WORKING ROSSSS")
                        // let newRepeat = parseInt(data.repeat) + 1
                        let dataIndex = this.segments[refIndex].subGroups[subGroupIndex].indexOf(data);

                        this.segments[refIndex].subGroups[subGroupIndex].splice(dataIndex, 1)

                        let originalData = data;
                        originalData.position = data.position.slice(0, data.position.indexOf("."))
                        // let originalDataIndex = this.segments[refIndex].subGroups[subGroupIndex].indexOf(originalData);
                        // console.log({ tester: this.segments[refIndex].subGroups[subGroupIndex], originalData })
                        // this.segments[refIndex].subGroups[subGroupIndex][originalDataIndex - 1].repeat = newRepeat.toString();
                        const arrayToSearch = this.segments[refIndex].subGroups[subGroupIndex];

                        const indices = [];
                        for (let i = 0; i < arrayToSearch.length; i++) {
                            if (arrayToSearch[i].position === originalData.position) {
                                indices.push(i);
                            }
                        }

                        for (let index of indices) {
                            let newRepeat = parseInt(this.segments[refIndex].subGroups[subGroupIndex][index].repeat) + 1
                            this.segments[refIndex].subGroups[subGroupIndex][index].repeat = newRepeat.toString();
                        }

                        this.segmentKey++;
                        this.btnLoader = false
                    }
                }
            } else {
                console.log("ERRRR")
                this.btnLoader = false
            }
        },
        async addSubOfSubGroupRepeat(receivedSubGroup, storage, data) {
            let newRepeat = parseInt(data[0].repeat) - 1;
            if (this.segments.indexOf(storage)) {
                console.log("SUB GROUP REPEAT PART FOUND!!!")
                let refIndex = this.segments.indexOf(storage);
                let subGroups = this.segments[refIndex].subGroups;
                for (let index in subGroups) {
                    let subGroup = subGroups[index];
                    if (subGroup === receivedSubGroup) {
                        console.log("RECEIVED AND FOUND SUBGROUP MATCHED!!!")
                        if (subGroup[0].subGroups) {
                            console.log("HAS SUBGROUPS")
                            for (let subOfSubGroup of subGroup[0].subGroups) {
                                if (subOfSubGroup[0] === data[0]) {
                                    let subOfSubGroups = this.segments[refIndex].subGroups[index][0].subGroups
                                    let subIndex = subOfSubGroups.indexOf(data)
                                    console.log("SUB OF SUBGROUP MATCHES WITH DATA!!!")
                                    console.log({
                                        newRepeat: newRepeat.toString(),
                                        previousRepeat: this.segments[refIndex].subGroups[index][0].subGroups[0].repeat,
                                        subOfSubGroups
                                    })
                                    this.segments[refIndex].subGroups[index][0].subGroups[subIndex][0].repeat = newRepeat.toString();
                                    const newArr = JSON.parse(JSON.stringify(data));
                                    newArr[0].position += `.${this.segmentKey}`
                                    console.log({
                                        newArr
                                    })
                                    this.segments[refIndex].subGroups[index][0].subGroups.splice(subIndex + 1, 0, newArr)
                                    this.segmentKey++;
                                    this.btnLoader = false
                                }
                            }
                        }
                    }
                }
            }
        },
        async addLastSubGroupRepeat(receivedSubGroup, receivedSubOfSubGroup, storage, data) {
            // console.log({receivedSubGroup, receivedSubOfSubGroup, storage, data})
            let newRepeat = parseInt(data[0].repeat) - 1;
            if (this.segments.indexOf(storage)) {
                console.log("SUB GROUP REPEAT PART FOUND!!!")
                let refIndex = this.segments.indexOf(storage);
                let subGroups = this.segments[refIndex].subGroups;
                for (let index in subGroups) {
                    let subGroup = subGroups[index];
                    if (subGroup === receivedSubGroup) {
                        console.log("RECEIVED AND FOUND SUBGROUP MATCHED!!!")
                        if (subGroup[0].subGroups) {
                            console.log("HAS SUBGROUPS")
                            for (let subOfSubGroup of subGroup[0].subGroups) {
                                if (subOfSubGroup === receivedSubOfSubGroup) {
                                    console.log("RECEIVED AND FOUND SUB OF SUBGROUP MATCHED!!!")
                                    if (subOfSubGroup[0].subGroups) {
                                        console.log("SUB OF SUBGROUP HAS SUB GROUPS!!!")
                                        for (let lastOfSubGroup of subOfSubGroup[0].subGroups) {
                                            if (lastOfSubGroup[0] === data[0]) {
                                                let subOfSubGroups = this.segments[refIndex].subGroups[index][0].subGroups
                                                let subOfSubGroupsIndex = subOfSubGroups.indexOf(receivedSubOfSubGroup)
                                                let lastSubGroups = this.segments[refIndex].subGroups[index][0].subGroups[subOfSubGroupsIndex][0].subGroups
                                                let lastSubGroupsIndex = lastSubGroups.indexOf(data);
                                                console.log("LAST SUBGROUP MATCHES DATA!!!")
                                                console.log(this.segments[refIndex].subGroups[index][0].subGroups[subOfSubGroupsIndex][0].subGroups[lastSubGroupsIndex][0])
                                                this.segments[refIndex].subGroups[index][0].subGroups[subOfSubGroupsIndex][0].subGroups[lastSubGroupsIndex][0].repeat = newRepeat.toString();
                                                const newArr = JSON.parse(JSON.stringify(data));
                                                newArr[0].position += `.${this.segmentKey}`
                                                console.log({
                                                    newArr
                                                })
                                                this.segments[refIndex].subGroups[index][0].subGroups[subOfSubGroupsIndex][0].subGroups.splice(lastSubGroupsIndex + 1, 0, newArr);
                                                this.segmentKey++;
                                                this.btnLoader = false
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        async removeLastSubGroupRepeat(receivedSubGroup, receivedSubOfSubGroup, storage, data) {
            this.btnLoader = true;
            if (this.segments.indexOf(storage) !== -1) {
                console.log("REMOVING SUB GROUP REPEAT PART FOUND");
                let refIndex = this.segments.indexOf(storage);

                for (let subGroup of this.segments[refIndex].subGroups) {
                    if (subGroup === receivedSubGroup) {
                        console.log("SUB GROUP FOR REMOVAL MATCHES");
                        let subgroupIndex = this.segments[refIndex].subGroups.indexOf(subGroup);

                        for (let subOfSubGroup of subGroup[0].subGroups) {
                            if (subOfSubGroup === receivedSubOfSubGroup) {
                                console.log("SUB OF SUB GROUP FOR REMOVAL MATCHES");
                                let subOfSubgroupIndex = subGroup[0].subGroups.indexOf(subOfSubGroup);
                                let removalIndex = subGroup[0].subGroups[subOfSubgroupIndex][0].subGroups.indexOf(data);

                                if (removalIndex !== -1) {
                                    console.log({
                                        subOfSubgroupIndex,
                                        removalIndex
                                    });

                                    console.log("REMOVING SUB OF SUBGROUP");
                                    let removedItem = subGroup[0].subGroups[subOfSubgroupIndex][0].subGroups.splice(removalIndex, 1)[0];
                                    console.log({
                                        removedItem
                                    });

                                    let newRepeat = parseInt(subGroup[0].subGroups[subOfSubgroupIndex][0].subGroups[0][0].repeat) + 1
                                    subGroup[0].subGroups[subOfSubgroupIndex][0].subGroups[0][0].repeat = newRepeat.toString()

                                    // if (subGroup[0].subGroups[subOfSubgroupIndex][0].subGroups.length === 0) {
                                    //     // If there are no more subgroups, remove the subOfSubGroup as well
                                    //     console.log("NO MORE SUBGROUPS, REMOVING SUB OF SUBGROUP");
                                    //     subGroup[0].subGroups.splice(subOfSubgroupIndex, 1);
                                    // }

                                    this.segmentKey++;
                                    this.btnLoader = false;
                                }
                            }
                        }
                    }
                }
            }
        },
        async removeSubOfSubGroupRepeat(receivedSubGroup, storage, data) {
            this.btnLoader = true
            if (this.segments.indexOf(storage) !== -1) {
                console.log("REMOVING SUB GROUP REPEAT PART FOUND");
                let refIndex = this.segments.indexOf(storage);
                let originalArr = data;
                let periodPosition = data[0].position.indexOf(".")
                originalArr[0].position = data[0].position.slice(0, periodPosition)
                let newRepeat = parseInt(originalArr[0].repeat) + 1;

                for (let subGroup of this.segments[refIndex].subGroups) {
                    if (subGroup === receivedSubGroup) {
                        console.log("SUB GROUP FOR REMOVAL MATCHES")
                        let subgroupIndex = this.segments[refIndex].subGroups.indexOf(subGroup);
                        let originalIndex = this.segments[refIndex].subGroups[subgroupIndex][0].subGroups.indexOf(originalArr);
                        let removalIndex = this.segments[refIndex].subGroups[subgroupIndex][0].subGroups.indexOf(data);
                        if (originalIndex && removalIndex) {
                            console.log({
                                originalIndex,
                                removalIndex
                            })
                            console.log("ORIGINAL FOUND FOR UPDATING")
                            originalArr[0].repeat = newRepeat.toString()
                            this.segments[refIndex].subGroups[subgroupIndex][0].subGroups[originalIndex - 1] = originalArr
                            this.segments[refIndex].subGroups[subgroupIndex][0].subGroups.splice(removalIndex, 1)
                            console.log({
                                updatedObject: this.segments[refIndex].subGroups[subgroupIndex][0].subGroups[originalIndex],
                                originalArr
                            })
                            this.segmentKey++;
                            this.btnLoader = false
                        }
                    }
                }
            }
            // if (this.segments.indexOf(storage) !== -1) {
            //         console.log("REMOVING SUB GROUP REPEAT PART FOUND")
            //         let refIndex = this.segments.indexOf(storage);
            //         let originalArr = data
            //         originalArr[0].position = data[0].position.slice(0, data[0].position.indexOf("."))
            //         // console.log(originalArr)
            //         let newRepeat = parseInt(originalArr[0].repeat) + 1
            //         let originalIndex = this.segments[refIndex].subGroups.indexOf(originalArr)

            //         console.log({
            //             originalArr,
            //             originalIndex
            //         })
            //         originalArr[0].repeat = newRepeat.toString()
            //         this.segments[refIndex].subGroups[originalIndex - 1] = originalArr

            //         let objectIndex = this.segments[refIndex].subGroups.indexOf(data);
            //         this.segments[refIndex].subGroups.splice(objectIndex, 1);
            //         this.segmentKey++;

            //         console.log(this.segments[refIndex])
            //         this.btnLoader = false
            //     } else {
            //         console.log("ERRRR")
            //         this.btnLoader = false
            //     }
        },
        async addSubGroupRepeat(data, storage, indicator) {
            if (indicator === "groupElements") {
                let newRepeat = parseInt(data.repeat) - 1
                if (this.segments.indexOf(storage) !== -1) {
                    console.log("SUB REPEAT PART FOUND")
                    let refIndex = this.segments.indexOf(storage);
                    let objIndex = this.segments[refIndex].groupElements.indexOf(data)
                    this.segments[refIndex].groupElements[objIndex].repeat = newRepeat.toString();
                    this.segmentKey++;
                    const newObj = JSON.parse(JSON.stringify(data));
                    newObj.position += `.${this.segmentKey}`
                    this.segments[refIndex].groupElements.splice(objIndex + 1, 0, newObj)
                    this.btnLoader = false
                } else {
                    console.log("ERRRR")
                    this.btnLoader = false
                }
            } else if (indicator === "subGroups") {
                let newRepeat = parseInt(data[0].repeat) - 1
                if (this.segments.indexOf(storage)) {
                    console.log("SUB GROUP REPEAT PART FOUND")
                    let refIndex = this.segments.indexOf(storage);
                    let subGroups = this.segments[refIndex].subGroups
                    for (let index in subGroups) {
                        let subGroup = subGroups[index];
                        if (subGroup[0] === data[0]) {
                            this.segments[refIndex].subGroups[index][0].repeat = newRepeat.toString()
                            const newArr = JSON.parse(JSON.stringify(data));
                            newArr[0].position += `.${this.segmentKey}`
                            this.segments[refIndex].subGroups.splice(index + 1, 0, newArr)
                            this.segmentKey++;
                            this.btnLoader = false
                        }
                    }
                }
            }
        },
        async removeSubGroupRepeat(data, storage, indicator) {
            if (indicator === "groupElements") {
                if (this.segments.indexOf(storage) !== -1) {
                    console.log("REMOVING SUB REPEAT PART FOUND")
                    let refIndex = this.segments.indexOf(storage);
                    let originalObject = data
                    originalObject.position = data.position.slice(0, data.position.indexOf("."))
                    console.log(originalObject)
                    let newRepeat = parseInt(originalObject.repeat) + 1
                    let originalIndex = this.segments[refIndex].groupElements.indexOf(originalObject)

                    console.log({
                        originalObject,
                        originalIndex
                    })
                    originalObject.repeat = newRepeat.toString()
                    this.segments[refIndex].groupElements[originalIndex - 1] = originalObject

                    let objectIndex = this.segments[refIndex].groupElements.indexOf(data);
                    this.segments[refIndex].groupElements.splice(objectIndex, 1);
                    this.segmentKey++;

                    console.log(this.segments[refIndex])
                    this.btnLoader = false
                } else {
                    console.log("ERRRR")
                    this.btnLoader = false
                }
            } else if (indicator === "subGroups") {
                if (this.segments.indexOf(storage) !== -1) {
                    console.log("REMOVING SUB GROUP REPEAT PART FOUND")
                    let refIndex = this.segments.indexOf(storage);
                    let originalArr = data
                    originalArr[0].position = data[0].position.slice(0, data[0].position.indexOf("."))
                    // console.log(originalArr)
                    let newRepeat = parseInt(originalArr[0].repeat) + 1
                    let originalIndex = this.segments[refIndex].subGroups.indexOf(originalArr)

                    console.log({
                        originalArr,
                        originalIndex
                    })
                    originalArr[0].repeat = newRepeat.toString()
                    this.segments[refIndex].subGroups[originalIndex - 1] = originalArr

                    let objectIndex = this.segments[refIndex].subGroups.indexOf(data);
                    this.segments[refIndex].subGroups.splice(objectIndex, 1);
                    this.segmentKey++;

                    console.log(this.segments[refIndex])
                    this.btnLoader = false
                } else {
                    console.log("ERRRR")
                    this.btnLoader = false
                }
            }
        },
        async addSubRepeat(data, storage) {
            if (Array.isArray(storage)) {
                let newRepeat = parseInt(data.repeat) - 1
                if (this.segments.indexOf(storage) !== -1) {
                    console.log("SUB REPEAT PART FOUND")
                    let refIndex = this.segments.indexOf(storage);
                    let objIndex = this.segments[refIndex].indexOf(data)
                    this.segments[refIndex][objIndex].repeat = newRepeat.toString();
                    this.segmentKey++;
                    const newObj = JSON.parse(JSON.stringify(data));
                    newObj.position += `.${this.segmentKey}`
                    this.segments[refIndex].splice(objIndex + 1, 0, newObj)
                    this.btnLoader = false
                } else {
                    console.log("ERRRR")
                    this.btnLoader = false
                }
            }
        },
        async removeSubRepeat(data, storage) {
            if (Array.isArray(storage)) {
                if (this.segments.indexOf(storage) !== -1) {
                    console.log("REMOVING SUB REPEAT PART FOUND")
                    let refIndex = this.segments.indexOf(storage);
                    let originalPosition = data.position.slice(0, data.position.indexOf("."))

                    for (let obj of this.segments[refIndex]) {
                        if (obj.position === originalPosition) {
                            console.log("joy")
                            console.log(obj)
                            let newRepeat = parseInt(obj.repeat) + 1
                            let index = this.segments[refIndex].indexOf(obj)
                            this.segments[refIndex][index].repeat = newRepeat.toString()
                        }
                    }

                    let objectIndex = this.segments[refIndex].indexOf(data);
                    this.segments[refIndex].splice(objectIndex, 1);
                    // // console.log(originalObject)
                    // let newRepeat = parseInt(originalObject.repeat) + 1
                    // let originalIndex = this.segments[refIndex].indexOf(originalObject)

                    // originalObject.repeat = newRepeat.toString()
                    // this.segments[refIndex][originalIndex] = originalObject

                    this.segmentKey++;
                    this.btnLoader = false
                } else {
                    console.log("ERRRR")
                    this.btnLoader = false
                }
            }
        },
        addRepeat(data) {
            console.log(data);
            this.btnLoader = true;
            if (!Array.isArray(data)) {
                let foundObjs = this.segments.filter((segment) => segment.position === data.position);
                if (foundObjs.length > 0) {
                    try {
                        let newRepeat = parseInt(foundObjs[0].repeat) - 1;
                        foundObjs.forEach((foundObj) => {
                            let index = this.segments.indexOf(foundObj);
                            this.segments[index].repeat = newRepeat.toString();
                            console.log({
                                index,
                                repeat: this.segments[index]
                            });
                            this.segmentKey++;
                            // Create a deep copy of 'foundObj'
                            const newObj = JSON.parse(JSON.stringify(foundObj));
                            newObj.position += `.${this.segmentKey}`;
                            // Insert the copied 'newObj' right after the original one
                            this.segments.splice(index + 1, 0, newObj);
                        });
                        this.btnLoader = false;
                    } catch (e) {
                        console.error("FOUND ERROR: " + e);
                    }
                } else {
                    console.error("CANNOT FIND OBJ");
                }
            } else {
                let arrays = this.segments.filter((segment) => Array.isArray(segment) === true)
                let foundObjs = arrays.filter((group) => group[0].position === data[0].position)
                if (foundObjs.length > 0) {
                    try {
                        let groupArray = foundObjs[0]
                        let newRepeat = parseInt(groupArray[0].repeat) - 1;
                        console.log(this.segments)
                        foundObjs.forEach((foundObj) => {
                            let index = this.segments.indexOf(foundObj);
                            this.segments[index][0].repeat = newRepeat.toString();
                            console.log({
                                index,
                                repeat: this.segments[index][0].repeat
                            });
                            this.segmentKey++;
                            let newArr = JSON.parse(JSON.stringify(foundObj));
                            newArr[0].position += `.${this.segmentKey}`;
                            this.segments.splice(index + 1, 0, newArr);
                            console.log({
                                foundObj,
                                original: this.segments[index]
                            })
                        });
                        this.btnLoader = false
                    } catch (e) {
                        console.error("FOUND ERROR: " + e);
                    }
                } else {
                    console.error("CANNOT FIND ARRAY OF OBJS");
                }
            }
        },
        async removeRepeat(data) {
            this.btnLoader = true;
            if (!Array.isArray(data)) {
                let foundObjs = this.segments.filter((segment) => segment.position === data.position);
                if (foundObjs.length > 0) {
                    try {
                        let objsToRemove = [];
                        let objsToUpdate = [];
                        foundObjs.forEach((foundObj) => {
                            let originalObj = foundObj;
                            originalObj.position = foundObj.position.slice(0, foundObj.position.indexOf("."))
                            objsToUpdate.push(originalObj)
                            objsToRemove.push(foundObj);
                        });

                        objsToRemove.forEach((objToRemove) => {
                            let index = this.segments.indexOf(objToRemove);
                            this.segments.splice(index, 1);
                        });

                        objsToUpdate.forEach((objToUpdate) => {
                            console.log(objToUpdate);
                            for (let index in this.segments) {
                                let segment = this.segments[index];
                                if (segment.position === objToUpdate.position) {
                                    objToUpdate.repeat = String(parseInt(segment.repeat) + 1);
                                    this.segments[index] = objToUpdate;
                                }
                            }
                        });

                        this.segmentKey++;
                        this.btnLoader = false;
                    } catch (e) {
                        console.error("ERROR: " + e);
                    }
                } else {
                    console.error("CANNOT FIND OBJ");
                }
            } else {
                let arrays = this.segments.filter((segment) => Array.isArray(segment) === true);
                let foundObjs = arrays.filter((group) => group[0].position === data[0].position);
                if (foundObjs.length > 0) {
                    try {
                        let objsToRemove = [];
                        let objsToUpdate = [];
                        foundObjs.forEach((foundObj) => {
                            let originalObj = foundObj;
                            originalObj[0].position = foundObj[0].position.slice(0, foundObj[0].position.indexOf("."))
                            objsToUpdate.push(originalObj)
                            objsToRemove.push(foundObj);
                        });

                        objsToRemove.forEach((objToRemove) => {
                            let index = this.segments.indexOf(objToRemove);
                            this.segments.splice(index, 1);
                        });

                        objsToUpdate.forEach((objToUpdate) => {
                            for (let index in this.segments) {
                                let segment = this.segments[index];
                                if (Array.isArray(segment)) {
                                    if (segment[0].position === objToUpdate[0].position) {
                                        objToUpdate[0].repeat = String(parseInt(segment[0].repeat) + 1);
                                        this.segments[index] = objToUpdate;
                                    }
                                }
                            }
                        });

                        this.segmentKey++;
                        this.btnLoader = false;
                    } catch (e) {
                        console.error("ERROR: " + e);
                    }
                }
            }
        }
    }
};
</script>

<style scoped>
.theme--light.v-data-table tbody tr:nth-of-type(even) {
    background-color: rgba(0, 0, 0, .03);
}

.theme--dark.v-data-table tbody tr:nth-of-type(even) {
    background-color: rgba(0, 0, 0, .5);
}

.theme--light.v-data-table tbody tr.v-data-table__selected {
    background: #f5c17d70 !important;
}

.theme--dark.v-data-table tbody tr.v-data-table__selected {
    background: #a17b4970 !important;
}

.theme--dark.v-data-table tbody tr.v-data-table__selected:hover {
    background: #a17b49c2 !important;
}

.theme--light.v-data-table tbody tr.v-data-table__selected:hover {
    background: #ffd296d2 !important;
}

.spacer {
    margin-top: 3em;
}

.formatted-text {
    white-space: pre-wrap;
}

.custom-panel-content {
    border: 1px solid #ccc;
    padding: 10px;
}

.short {
    width: 100px;
}

.short span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.virtularScroller.v-virtual-scroll .v-virtual-scroll__container .v-virtual-scroll__item {
    top: 20px !important;
}

.dragComponent {
    height: auto;
    width: 100%;
}
</style>
